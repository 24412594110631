import { Divider, notification, Table } from "antd";
import { Component } from "react";
import { connect } from "react-redux";
import Button from "../components/Button";
import Header from "../components/Header";
import TextInput from "../components/TextInput";
import SecurityNavigator from "../navigator/SecurityNavigator";
import WindowResizer from "../navigator/WindowResizer";
import { getAllResources } from "../store/actions/resActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { resStateInterface } from "../store/reducers/resReducer";
import {
  DeviceType,
  utilsStateInterface,
} from "../store/reducers/utilsReducer";
import { FlexibleRowContainer, VerticalContainer } from "../styles/Layout";
import { Title3 } from "../styles/Typography";
import { handleNavigation } from "../utils/navigator";

interface Props {
  history: any;
  location: any;
  authStore: authStateInterface;
  resStore: resStateInterface;
  utilsStore: utilsStateInterface;
  getAllResources: () => void;
}

interface State {
  searchText: string;
}

class Resources extends Component<Props> {
  state: State = {
    searchText: "",
  };

  columns: any = [
    {
      title: "Title",
      dataIndex: "title",
      sorter: (a: any, b: any) => a.title.localeCompare(b.title),
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a: any, b: any) => a.description.localeCompare(b.description),
    },
    {
      title: "Link",
      dataIndex: "link",
      sorter: (a: any, b: any) => a.link.localeCompare(b.link),
    },
  ];

  componentDidMount() {
    this.handleGetPreviewData();
  }

  handleNavigate = (type: string, id: string) => {
    if (type === "resources") {
      handleNavigation(this.props.history, "/editResources/" + id);
    }
  };

  handleNotification = () => {
    if (this.props.history.location.notification) {
      const type = this.props.history.location.notification;
      switch (type) {
        case 1:
          notification.success({
            message: "Resources Sucessfully Created",
            description: "",
          });
          break;
        case 2:
          notification.success({
            message: "Resources Sucessfully Updated",
            description: "",
          });
          break;
        case 3:
          notification.success({
            message: "Resources Sucessfully Deleted",
            description: "",
          });
          break;
        case 4:
          notification.warning({
            message: "Resource does not exists",
            description: "",
          });
          break;
        default:
          break;
      }
      this.props.history.location.notification = "";
    }
  };

  handleGetPreviewData = async () => {
    this.props.getAllResources();
    this.handleNotification();
  };

  handleFilter = (e: any) => {
    this.setState({
      searchText: e.target.value,
    });
  };

  handleOnClickRow = (e: any) => {
    return {
      onClick: () => {
        handleNavigation(this.props.history, "/editResources/" + e.key);
      },
    };
  };

  renderFilteredData = () => {
    let listView: any = [];

    if (this.props.resStore.resources.length > 0) {
      this.props.resStore.resources.map((eachResources) =>
        listView.push({
          key: eachResources.id,
          title: eachResources.title,
          description: eachResources.description,
          link: eachResources.link,
        })
      );
    }

    if (this.state.searchText) {
      const filteredData = listView.filter((o: []) =>
        Object.keys(o).some((k: any) =>
          String(o[k])
            .toLowerCase()
            .includes(this.state.searchText.toLowerCase())
        )
      );
      listView = filteredData;
    }

    return listView;
  };

  render() {
    if (this.props.authStore.userLoading) {
      return null;
    }

    return (
      <>
        <Header history={this.props.history} location={this.props.location} />
        <WindowResizer />
        <SecurityNavigator location={this.props.location} role="SUPER" />
        <VerticalContainer
          style={{
            width: "100%",
          }}
        >
          <VerticalContainer
            style={{
              alignItems: "flex-start",
              width: "90%",
              height: "100%",
              maxWidth: 1200,
              padding: "40px 0px",
            }}
          >
            <FlexibleRowContainer
              style={{
                width: "100%",
                alignItems: "flex-start",
              }}
            >
              <Title3 style={{ marginBottom: 0 }}>Resources</Title3>
              <div style={{ flex: 1, marginTop: 5 }} />
              <TextInput
                placeholder="Search"
                value={this.state.searchText}
                width={
                  this.props.utilsStore.deviceType === DeviceType.Mobile
                    ? 280
                    : 400
                }
                onChange={this.handleFilter}
              />
              <div style={{ flex: 1, marginTop: 10 }} />
              <Button
                text="Add"
                width={150}
                onClick={this.handleNavigate.bind(this, "resources", "create")}
                small={true}
              />
            </FlexibleRowContainer>
            <Divider style={{ marginTop: 10, marginBottom: 30 }} />
            <Table
              style={{
                width: "100%",
                maxWidth: "100%",
                cursor: "pointer",
                overflowX: "auto",
              }}
              columns={this.columns}
              dataSource={this.renderFilteredData()}
              onRow={this.handleOnClickRow}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "30"],
              }}
            />
          </VerticalContainer>
        </VerticalContainer>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    resStore: state.resStore,
    utilsStore: state.utilsStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAllResources: () => dispatch(getAllResources()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Resources);
