import { BookingWithUserModel } from "../actions/bookingActions";

export interface bookingStateInterface {
  bookings: BookingWithUserModel[];
  loading: boolean;
  pagination: string;
}

const initState: bookingStateInterface = {
  bookings: [],
  loading: false,
  pagination: "",
};

const bookingReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_BOOKINGS":
      return {
        ...state,
        bookings: action.payload.bookings,
        pagination: action.payload.pagination,
        loading: false,
      };
    case "SET_BOOKING_LOADING":
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default bookingReducer;
