import { CloseOutlined } from "@ant-design/icons";
import {
  Avatar,
  DatePicker,
  List,
  Modal,
  notification,
  Select,
  Spin,
} from "antd";
import React, { Component, ReactElement } from "react";
import { connect } from "react-redux";
import Button from "../components/Button";
import Header from "../components/Header";
import { VolunteerRoles, VolunteerShift } from "../models/Booking";
import { User } from "../models/User";
import SecurityNavigator from "../navigator/SecurityNavigator";
import WindowResizer from "../navigator/WindowResizer";
import { BookRes, handleBookDates } from "../store/actions/bookingActions";
import { getAllCenters } from "../store/actions/centerActions";
import { getSuggestedUsers } from "../store/actions/userActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { centerStateInterface } from "../store/reducers/centerReducer";
import { userStateInterface } from "../store/reducers/userReducer";
import {
  DeviceType,
  utilsStateInterface,
} from "../store/reducers/utilsReducer";
import { COLOR } from "../styles/Colors";
import {
  FlexibleRowContainer,
  RowContainer,
  VerticalContainer,
} from "../styles/Layout";
import { Body } from "../styles/Typography";
import { handleNavigation } from "../utils/navigator";

interface Props {
  authStore: authStateInterface;
  userStore: userStateInterface;
  utilsStore: utilsStateInterface;
  centerStore: centerStateInterface;
  history: any;
  location: any;
  getSuggestedUsers: (search: string) => void;
  getAllCenters: (state: string) => void;
}

export interface DateSelection {
  date: Date;
  shift: keyof typeof VolunteerShift;
}

interface State {
  loading: boolean;
  email: string;
  centerId: string;
  dateRange: moment.Moment | null;
  selectedUser: User | null;
  selectedRole: keyof typeof VolunteerRoles | null;
  selectedDate: DateSelection[];
}

const { Option } = Select;
const { confirm } = Modal;

class AddBooking extends Component<Props> {
  searchTimeout: ReturnType<typeof setTimeout> | null = null;

  state: State = {
    loading: false,
    email: "",
    centerId: "",
    dateRange: null,
    selectedUser: null,
    selectedRole: null,
    selectedDate: [],
  };

  componentDidMount() {
    if (this.props.authStore.user) {
      this.handleGetPreviewData();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      JSON.stringify(prevProps.authStore.user) !==
        JSON.stringify(this.props.authStore.user) &&
      this.props.authStore.user
    ) {
      this.handleGetPreviewData();
    }
  }

  handleGetPreviewData = async () => {
    if (
      this.props.authStore.user &&
      this.props.authStore.user.role === "SUPER"
    ) {
      this.props.getAllCenters("");
    }
  };

  handleSelectRole = (value: string) => {
    this.setState({
      selectedRole: value,
    });
  };

  handleSelectCenter = (value: string) => {
    this.setState({
      centerId: value,
    });
  };

  handleSearchUsers = (value: any) => {
    this.setState(
      {
        email: value,
      },
      () => {
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(() => {
          if (this.state.email.length === 0) {
            this.setState({ selectedUser: null });
          } else if (this.state.email.length <= 2) {
            notification.warning({
              message: "Please input at least 3 characters",
            });
          } else {
            this.props.getSuggestedUsers(this.state.email);
          }
        }, 500);
      }
    );
  };

  handleSelectUser = (value: string) => {
    let selectedUser: User | null = null;
    this.props.userStore.suggestedUsers.map((eachUser) => {
      if (eachUser.id === value) {
        selectedUser = eachUser;
      }
      return null;
    });

    if (selectedUser) {
      this.setState({
        selectedUser,
      });
    }
  };

  handleSelectDateRange = (value: any) => {
    this.setState(
      {
        dateRange: value,
      },
      () => {
        if (this.state.dateRange) {
          const dateChoices: DateSelection[] = [];
          for (let i = 0; i < 7; i++) {
            const d = value.toDate();
            d.setDate(d.getDate() + i);

            const dateSelection: DateSelection = {
              date: d,
              shift: "MOR",
            };
            dateChoices.push(dateSelection);
          }
          this.setState({
            selectedDate: dateChoices,
          });
        }
      }
    );
  };

  handleChangeIndividualDate = (
    index: number,
    value: keyof typeof VolunteerShift
  ) => {
    let tempChoices: DateSelection[] = JSON.parse(
      JSON.stringify(this.state.selectedDate)
    );
    tempChoices[index]["shift"] = value;

    tempChoices.map((eachChoice, index) => {
      tempChoices[index]["date"] = new Date(eachChoice.date);

      if (value === "WHOLE") {
        tempChoices[index]["shift"] = "WHOLE" as keyof typeof VolunteerShift;
      } else if (tempChoices[index]["shift"] === "WHOLE") {
        tempChoices[index]["shift"] = value;
      }
      return null;
    });

    this.setState({
      selectedDate: tempChoices,
    });
  };

  handleConfirmBooking = () => {
    confirm({
      title:
        "Do you want to book these dates for this user? Please check that this user does not have booking within these dates.",
      okText: "Confirm",
      onOk: this.handleBookDate,
      onCancel: () => {},
    });
  };

  handleNotification = (type: string, message: string, description: string) => {
    if (type === "success") {
      notification["success"]({ message: message, description: "" });
    } else if (type === "warning") {
      notification["warning"]({
        message: message,
        description: description,
      });
    }
  };

  handleBookDate = () => {
    this.setState({ loading: true }, async () => {
      const bookRes: BookRes = await handleBookDates(
        this.state.selectedUser?.id ?? "",
        this.state.centerId,
        this.state.selectedRole ?? "G",
        this.state.selectedDate
      );
      if (bookRes.err) {
        this.handleNotification("warning", bookRes.err, "");
        this.setState({ loading: false });
      } else if (bookRes.success) {
        handleNavigation(this.props.history, "/bookings");
      }
    });
  };

  handleCancelIndividualDate = (index: number) => {
    this.state.selectedDate.splice(index, 1);
    this.setState({
      selectedDate: this.state.selectedDate,
    });
  };

  renderSteps = (index: number, description: string, marginTop?: number) => {
    return (
      <RowContainer style={{ marginTop: marginTop ?? 30, marginBottom: 10 }}>
        <Avatar
          style={{ backgroundColor: COLOR.RED, width: 40, minWidth: 40 }}
          size="large"
        >
          {index}
        </Avatar>
        <VerticalContainer style={{ marginLeft: 10, alignItems: "flex-start" }}>
          <Body
            style={{ fontWeight: "bold", color: COLOR.BLACK, marginBottom: 5 }}
          >
            Step {index}
          </Body>
          <Body style={{ marginBottom: 0 }}>{description}</Body>
        </VerticalContainer>
      </RowContainer>
    );
  };

  renderUserEmailOptions = () => {
    const optionList: any[] = [];

    this.props.userStore.suggestedUsers.map((eachUser) => {
      optionList.push(
        <Option key={eachUser.id} value={eachUser.id}>
          {eachUser.email}
        </Option>
      );
      return null;
    });

    return optionList;
  };

  renderIndividualDateSelection = () => {
    const listComponent: ReactElement[] = [];
    this.state.selectedDate.map((eachChoice: DateSelection, index) => {
      const selections = ["WHOLE", "MOR", "EVE"];
      listComponent.push(
        <li
          style={{
            marginBottom: 15,
            color: COLOR.BLACK,
          }}
          key={index}
        >
          <RowContainer style={{ width: "100%" }}>
            <FlexibleRowContainer
              style={{
                alignItems:
                  this.props.utilsStore.deviceType === DeviceType.Mobile
                    ? "flex-start"
                    : "center",
              }}
            >
              <Body
                style={{
                  marginBottom: 0,
                  width: 150,
                }}
              >
                {eachChoice.date.toDateString()}
              </Body>
              <Select
                defaultValue={"MOR" as keyof typeof VolunteerShift}
                value={eachChoice.shift}
                onChange={this.handleChangeIndividualDate.bind(this, index)}
                style={{
                  width: 130,
                }}
              >
                {selections.map((shift: any, index: any) => (
                  <Option
                    key={shift as keyof typeof VolunteerShift}
                    value={shift as keyof typeof VolunteerShift}
                  >
                    {Object.values(
                      VolunteerShift[shift as keyof typeof VolunteerShift]
                    )}
                  </Option>
                ))}
              </Select>
            </FlexibleRowContainer>
            <div style={{ flexGrow: 1 }} />
            <CloseOutlined
              style={{
                marginLeft: 20,
                cursor: "pointer",
                color: COLOR.RED,
              }}
              onClick={this.handleCancelIndividualDate.bind(this, index)}
            />
          </RowContainer>
        </li>
      );
      return null;
    });

    return listComponent;
  };

  renderDateSelection = () => {
    if (
      this.state.selectedUser &&
      this.state.centerId &&
      this.state.selectedRole &&
      this.state.dateRange &&
      this.state.selectedDate.length > 0
    ) {
      const firstDate = this.state.selectedDate[0].date.toDateString();
      const selectionLength = this.state.selectedDate.length;
      const lastDate =
        this.state.selectedDate[selectionLength - 1].date.toDateString();

      return (
        <>
          {this.renderSteps(5, "Select Exact Shift")}
          <Body
            style={{
              color: COLOR.RED,
              textAlign: "justify",
              marginBottom: 10,
            }}
          >
            Booking from {firstDate}
            {" - "}
            {lastDate}
          </Body>
          <List>
            <ul>{this.renderIndividualDateSelection()}</ul>
          </List>
        </>
      );
    } else {
      return null;
    }
  };

  render() {
    if (this.props.authStore.userLoading) {
      return null;
    }

    return (
      <Spin
        spinning={this.state.loading}
        size="large"
        style={{
          width: "100vw",
          height: "100vh",
          maxHeight: "none",
        }}
      >
        <Header history={this.props.history} location={this.props.location} />
        <WindowResizer />
        <SecurityNavigator location={this.props.location} role="SUPER" />
        <VerticalContainer style={{ width: "100%" }}>
          <VerticalContainer
            style={{
              alignItems: "flex-start",
              width: "90%",
              maxWidth: 1200,
              padding: "40px 0px",
            }}
          >
            <VerticalContainer
              style={{
                border: "solid 1px #ccc",
                width: "100%",
                alignItems: "flex-start",
                borderRadius: 10,
                paddingLeft: 30,
                paddingBottom: 30,
              }}
            >
              {this.renderSteps(1, "Select An User's Email")}
              <Select
                value={
                  this.state.selectedUser ? this.state.selectedUser.email : ""
                }
                style={{
                  width: 230,
                }}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  if (option) {
                    return (
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  } else {
                    return false;
                  }
                }}
                showSearch
                placeholder="Search by Email"
                onSearch={this.handleSearchUsers}
                onChange={this.handleSelectUser}
              >
                {this.renderUserEmailOptions()}
              </Select>
              {this.state.selectedUser && (
                <>
                  {this.renderSteps(2, "Select A Center")}
                  <Select
                    style={{ width: 230 }}
                    value={this.state.centerId}
                    placeholder="Select a center"
                    onChange={this.handleSelectCenter}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      if (option) {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      } else {
                        return false;
                      }
                    }}
                    showSearch
                  >
                    {this.props.centerStore.centers.map(
                      (eachCenter, index: number) => {
                        return (
                          <Option
                            key={index}
                            label={eachCenter.centerName}
                            value={eachCenter.id}
                          >
                            {eachCenter.centerName}
                          </Option>
                        );
                      }
                    )}
                  </Select>
                </>
              )}
              {this.state.selectedUser && this.state.centerId && (
                <>
                  {this.renderSteps(3, "Select A Role")}
                  <Select
                    style={{ width: 230 }}
                    value={this.state.selectedRole ?? ""}
                    onChange={this.handleSelectRole}
                  >
                    {["G", "V", "O", "C"].map(
                      (eachRoleKey: string, index: number) => (
                        <Option key={index} value={eachRoleKey}>
                          {
                            VolunteerRoles[
                              eachRoleKey as keyof typeof VolunteerRoles
                            ]
                          }
                        </Option>
                      )
                    )}
                  </Select>
                </>
              )}
              {this.state.selectedUser &&
                this.state.centerId &&
                this.state.selectedRole && (
                  <>
                    {this.renderSteps(4, "Select A Date")}
                    <DatePicker
                      picker="week"
                      style={{
                        width: 230,
                      }}
                      onChange={this.handleSelectDateRange}
                      value={this.state.dateRange?.startOf("isoWeek")}
                    />
                  </>
                )}
              {this.renderDateSelection()}
              {this.state.selectedUser &&
                this.state.centerId &&
                this.state.selectedRole &&
                this.state.dateRange &&
                this.state.selectedDate.length > 0 && (
                  <RowContainer
                    style={{
                      width: "100%",
                      marginTop: 30,
                      paddingRight: 30,
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      text="Create Booking"
                      width="auto"
                      small={true}
                      onClick={this.handleConfirmBooking}
                    />
                  </RowContainer>
                )}
            </VerticalContainer>
          </VerticalContainer>
        </VerticalContainer>
      </Spin>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    userStore: state.userStore,
    utilsStore: state.utilsStore,
    centerStore: state.centerStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAllCenters: (state: string) => dispatch(getAllCenters(state)),
    getSuggestedUsers: (search: string) => dispatch(getSuggestedUsers(search)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBooking);
