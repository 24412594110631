import { Component } from "react";
import styled from "styled-components";
import Logo from "../assets/logo.png";
import WindowResizer from "../navigator/WindowResizer";
import { VerticalContainer } from "../styles/Layout";
import { Body, Title2 } from "../styles/Typography";

interface Props {
  location: any;
}

export default class Mobile extends Component<Props> {
  render() {
    return (
      <Container>
        <WindowResizer />
        <img src={Logo} alt="Logo" style={{ height: 50 }} />
        <Title2 style={{ textAlign: "center", marginTop: 20 }}>
          Mobile Device Detected
        </Title2>
        <Body style={{ textAlign: "center", width: 280 }}>
          MyVac Admin Dashboard is only available on desktop
        </Body>
      </Container>
    );
  }
}

const Container = styled(VerticalContainer)`
  width: 100vw;
  height: 100vh;
`;
