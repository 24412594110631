import { Divider, notification, Table } from "antd";
import { Component } from "react";
import { connect } from "react-redux";
import Button from "../components/Button";
import Header from "../components/Header";
import TextInput from "../components/TextInput";
import Admin from "../models/Admin";
import { CenterType } from "../models/Center";
import SecurityNavigator from "../navigator/SecurityNavigator";
import WindowResizer from "../navigator/WindowResizer";
import { getAllCenters } from "../store/actions/centerActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { centerStateInterface } from "../store/reducers/centerReducer";
import {
  DeviceType,
  utilsStateInterface,
} from "../store/reducers/utilsReducer";
import { FlexibleRowContainer, VerticalContainer } from "../styles/Layout";
import { Title3 } from "../styles/Typography";
import { handleNavigation } from "../utils/navigator";

interface Props {
  history: any;
  location: any;
  authStore: authStateInterface;
  centerStore: centerStateInterface;
  utilsStore: utilsStateInterface;
  getAllCenters: (state: string) => void;
}

interface State {
  searchText: string;
}

class Centers extends Component<Props> {
  state: State = {
    searchText: "",
  };

  columns: any = [
    {
      title: "Name",
      dataIndex: "centerName",
      sorter: (a: any, b: any) => a.centerName.localeCompare(b.centerName),
    },
    {
      title: "Type",
      dataIndex: "centerType",
      sorter: (a: any, b: any) => a.centerType.localeCompare(b.centerType),
    },
    {
      title: "State",
      dataIndex: "state",
      sorter: (a: any, b: any) => a.state.localeCompare(b.state),
    },
    {
      title: "District",
      dataIndex: "district",
      sorter: (a: any, b: any) => a.district.localeCompare(b.district),
    },
    {
      title: "Email",
      dataIndex: "centerEmail",
      sorter: (a: any, b: any) => a.centerEmail.localeCompare(b.centerEmail),
    },
    {
      title: "Contact No.",
      dataIndex: "centerContactNo",
      sorter: (a: any, b: any) =>
        a.centerContactNo.localeCompare(b.centerContactNo),
    },
    {
      title: "Action",
      dataIndex: "view",
      render: (view: any) => (
        <Button
          text="View"
          small={true}
          width={100}
          onClick={() => {
            if (view) {
              window.open(
                this.props.authStore.user?.role === "SUPER"
                  ? "/editCenter/" + view
                  : "/centerAnalytics/" + view
              );
            }
          }}
        />
      ),
    },
  ];

  componentDidMount() {
    if (this.props.authStore.user) {
      this.handleGetPreviewData();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      JSON.stringify(prevProps.authStore.user) !==
        JSON.stringify(this.props.authStore.user) &&
      this.props.authStore.user
    ) {
      this.handleGetPreviewData();
    }
  }

  handleNavigate = (type: string, id: string) => {
    if (type === "center") {
      handleNavigation(this.props.history, "/editCenter/" + id);
    }
  };

  handleGetPreviewData = async () => {
    if (this.props.authStore.user) {
      const currentUser: Admin = this.props.authStore.user;
      let filterState = "";
      if (currentUser.role !== "SUPER" && currentUser.role !== "NGO") {
        filterState = currentUser.state ?? "";
      }
      this.props.getAllCenters(filterState);
      this.handleNotification();
    }
  };

  handleLoading = (value: boolean) => {
    this.setState({
      loading: value,
    });
  };

  handleNotification = () => {
    if (this.props.history.location.notification) {
      const type = this.props.history.location.notification;
      switch (type) {
        case 1:
          notification["success"]({
            message: "Center Sucessfully Created",
            description: "",
          });
          break;
        case 2:
          notification["success"]({
            message: "Center Sucessfully Updated",
            description: "",
          });
          break;
        case 3:
          notification["success"]({
            message: "Center Sucessfully Deleted",
            description: "",
          });
          break;
        case 4:
          notification["warning"]({
            message: "Center does not exists",
            description: "",
          });
          break;
        default:
          break;
      }
      this.props.history.location.notification = "";
    }
  };

  handleFilter = (e: any) => {
    this.setState({
      searchText: e.target.value,
    });
  };

  renderFilteredData = () => {
    let listView: any = [];

    if (this.props.centerStore.centers.length > 0) {
      this.props.centerStore.centers.map((eachCenters) => {
        let condition = true;
        if (
          this.props.authStore.user &&
          this.props.authStore.user.role === "CENTER"
        ) {
          condition =
            this.props.authStore.user.center?.includes(eachCenters.id) ?? false;
        }
        if (condition) {
          listView.push({
            key: eachCenters.id,
            centerName: eachCenters.centerName,
            centerType: Object(CenterType)[eachCenters.centerType],
            state: eachCenters.centerAddress.state,
            district: eachCenters.centerAddress.district,
            centerEmail: eachCenters.centerEmail,
            centerContactNo: eachCenters.centerContactNo,
            view: eachCenters.id,
          });
        }

        return null;
      });
    }

    if (this.state.searchText) {
      const filteredData = listView.filter((o: []) =>
        Object.keys(o).some((k: any) =>
          String(o[k])
            .toLowerCase()
            .includes(this.state.searchText.toLowerCase())
        )
      );
      listView = filteredData;
    }

    return listView;
  };

  render() {
    if (this.props.authStore.userLoading) {
      return null;
    }

    const superAdmin =
      this.props.authStore.user?.role === "SUPER" ? true : false;
    const ngoAdmin = this.props.authStore.user?.role === "NGO" ? true : false;

    return (
      <>
        <Header history={this.props.history} location={this.props.location} />
        <WindowResizer />
        <SecurityNavigator location={this.props.location} />
        <VerticalContainer
          style={{
            width: "100%",
          }}
        >
          <VerticalContainer
            style={{
              alignItems: "flex-start",
              width: "90%",
              height: "100%",
              maxWidth: 1200,
              padding: "40px 0px",
            }}
          >
            <FlexibleRowContainer
              style={{
                width: "100%",
                alignItems: "flex-start",
              }}
            >
              <Title3 style={{ marginBottom: 0 }}>
                Centers{" "}
                {!superAdmin &&
                  !ngoAdmin &&
                  `in ${this.props.authStore.user?.state ?? ""}`}
              </Title3>
              <div style={{ flex: 1, marginTop: 5 }} />
              <TextInput
                placeholder="Search"
                value={this.state.searchText}
                width={
                  this.props.utilsStore.deviceType === DeviceType.Mobile
                    ? 280
                    : 400
                }
                onChange={this.handleFilter}
              />
              <div style={{ flex: 1, marginTop: 10 }} />
              {superAdmin ? (
                <Button
                  text="Add"
                  width={150}
                  onClick={this.handleNavigate.bind(this, "center", "create")}
                  small={true}
                />
              ) : (
                <div style={{ width: 150 }} />
              )}
            </FlexibleRowContainer>
            <Divider style={{ marginTop: 10, marginBottom: 30 }} />
            <Table
              style={{
                width: "100%",
                maxWidth: "100%",
                cursor: "pointer",
                overflowX: "auto",
              }}
              columns={this.columns}
              dataSource={this.renderFilteredData()}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "30"],
              }}
            />
          </VerticalContainer>
        </VerticalContainer>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    centerStore: state.centerStore,
    utilsStore: state.utilsStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAllCenters: (state: string) => dispatch(getAllCenters(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Centers);
