export enum COLOR {
  "RED" = "#FD5C5E",
  "OPAQUERED" = "#FD5C5ECC",
  "BLACK" = "#2B2B2B",
  "GREY" = "#666",
  "LIGHTGREY" = "#F2F2F2",
  "WHITE" = "#FFFF",
  "LIGHTRED" = "#FF577F",
  "BLUE" = "#08396c",
}
