import { Input } from "antd";
import React, { Component } from "react";
import { COLOR } from "../styles/Colors";
const { TextArea } = Input;

interface Props {
  width: number | string;
  placeholder: string;
  maxWidth?: number | string;
  password?: boolean;
  disabled?: boolean;
  id?: string;
  value?: string | number;
  onChange?: (e: any) => void;
  height?: number;
  borderRadius?: number;
  background?: string;
  autoSize: { minRows: number; maxRows: number };
}

export default class TextInput extends Component<Props> {
  render() {
    return (
      <TextArea
        id={this.props.id ?? ""}
        value={this.props.value ?? ""}
        style={{
          width: this.props.width,
          maxWidth: this.props.maxWidth ?? "none",
          background: this.props.background ?? COLOR.WHITE,
          height: this.props.height,
          borderRadius: this.props.borderRadius,
        }}
        placeholder={this.props.placeholder}
        onChange={this.props.onChange}
        disabled={this.props.disabled}
        autoSize={this.props.autoSize}
      />
    );
  }
}
