import HealthVerificationLink from "../../models/HealthVerificationLink";

export interface healthVerificationLinkStateInterface {
  healthVerificationLink: HealthVerificationLink[];
  loading: boolean;
  pagination: string;
}

const initState: healthVerificationLinkStateInterface = {
  healthVerificationLink: [],
  loading: false,
  pagination: "",
};

const healthVerificationLinkReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_HEALTH_VERIFICATION_LINK":
      return {
        ...state,
        healthVerificationLink: action.payload.healthVerificationLink,
        pagination: action.payload.pagination,
        loading: false,
      };
    case "SET_HEALTH_VERIFICATION_LINK_LOADING":
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default healthVerificationLinkReducer;
