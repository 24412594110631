import moment from "moment";
import { BookingModel } from "../../models/Booking";
import CenterModel, { CenterType, Personnel } from "../../models/Center";
import { User } from "../../models/User";
import firebase from "../../utils/firebase";

let centersSocket: any = null;
export const getAllCenters = (state: string) => {
  return async (dispatch: any, getState: any) => {
    try {
      if (!centersSocket) {
        let centersList: CenterModel[] = [];
        let centerQuery;
        if (state) {
          centerQuery = firebase
            .firestore()
            .collection("centers")
            .where("centerAddress.state", "==", state);
        } else {
          centerQuery = firebase.firestore().collection("centers");
        }
        centersSocket = centerQuery.onSnapshot((querySnapshot) => {
          centersList = [];
          querySnapshot.forEach((doc) => {
            centersList.push(doc.data() as CenterModel);
          });

          dispatch({
            type: "UPDATE_CENTERS",
            payload: {
              centers: centersList,
            },
          });
        });
      }
    } catch (err) {}
  };
};

export interface CenterRes {
  err: string;
  data: CenterModel | null;
}

export const getCenter = async (id: string) => {
  let centerRes: CenterRes = {
    err: "",
    data: null,
  };

  try {
    const centerQuery = await firebase
      .firestore()
      .collection("centers")
      .doc(id)
      .get();
    if (centerQuery.exists) {
      let centerData: CenterModel = centerQuery.data() as CenterModel;
      const tempPersonnelNeeded: Personnel[] = [];
      if (centerData.personnelNeeded.length > 0) {
        centerData.personnelNeeded.map((eachPersonnelNeeded: any) => {
          const tempPersonnel: Personnel = {
            startDate: new Date(eachPersonnelNeeded.startDate.seconds * 1000),
            endDate: new Date(eachPersonnelNeeded.endDate.seconds * 1000),
            volGeneralNo: eachPersonnelNeeded.volGeneralNo,
            volHealthVacNo: eachPersonnelNeeded.volHealthVacNo,
            volHealthObsNo: eachPersonnelNeeded.volHealthObsNo,
            volHealthCounsNo: eachPersonnelNeeded.volHealthCounsNo,
            shift: eachPersonnelNeeded.shift,
          };
          tempPersonnelNeeded.push(tempPersonnel);
          return null;
        });
      }

      centerData["personnelNeeded"] = tempPersonnelNeeded;
      centerRes.data = centerData;
    } else {
      centerRes.err = "Cannot be found";
    }
  } catch (err) {
    centerRes.err = err.message;
  }

  return centerRes;
};
export interface UsersBookingRes {
  err: string;
  data:
    | {
        user: User;
        startDate: Date;
        endDate: Date;
      }[]
    | null;
}
export interface UsersBookingModel {
  user: User;
  endDate: Date;
  startDate: Date;
}
export const getBookings = async (centerId: string) => {
  let usersBookingRes: UsersBookingRes = {
    err: "",
    data: null,
  };

  const thisMon = moment().startOf("isoWeek").toDate();
  try {
    const usersBookings: UsersBookingModel[] = [];
    const bookingQuery = await firebase
      .firestore()
      .collection("bookings")
      .where("centerId", "==", centerId)
      .where("startDate", ">=", thisMon)
      .orderBy("startDate")
      .get();

    if (bookingQuery) {
      const bookingList: BookingModel[] = [];
      bookingQuery.forEach((eachDoc) => {
        bookingList.push(eachDoc.data() as BookingModel);
      });
      await Promise.all(
        bookingList.map(async (eachDoc: BookingModel) => {
          const userQuery = await firebase
            .firestore()
            .collection("users")
            .doc(eachDoc.userId)
            .get();
          if (userQuery.exists) {
            const bookingsData = {
              user: userQuery.data() as User,
              startDate: new Date(eachDoc.startDate.seconds * 1000),
              endDate: new Date(eachDoc.endDate.seconds * 1000),
            };
            usersBookings.push(bookingsData);
          }
        })
      );
    }
    usersBookingRes.data = usersBookings;
  } catch (err) {
    usersBookingRes.err = err.message;
  }
  return usersBookingRes;
};

export const createCenter = async (
  items: {
    centerName: string;
    centerContactNo: string;
    centerAddress: {
      street: string;
      poscode: string;
      state: string;
      district: string;
    };
    centerEmail: string;
    doses: number;
    volGeneral: number;
    volHealth: number;
    centerType: CenterType | string;
    description: string;
    personnelNeeded: Personnel[];
  },
  uploadImageFile: {
    data: File;
    url: string;
  }
) => {
  try {
    const centersRef = firebase.firestore().collection("centers").doc();
    const uploadTask = await firebase
      .storage()
      .ref("centers")
      .child(centersRef.id + "/image.jpg")
      .put(uploadImageFile.data);
    const uploadTaskUrl: string = await uploadTask.ref.getDownloadURL();
    const urlParams = new URLSearchParams(uploadTaskUrl);
    const imageToken = urlParams.get("token");
    const centersModel = {
      id: centersRef.id,
      imageToken: imageToken,
      centerName: items.centerName,
      centerContactNo: items.centerContactNo,
      centerAddress: {
        street: items.centerAddress.street,
        poscode: items.centerAddress.poscode,
        state: items.centerAddress.state,
        district: items.centerAddress.district,
      },
      doses: items.doses,
      volGeneral: items.volGeneral,
      volHealth: items.volHealth,
      centerEmail: items.centerEmail,
      centerType: items.centerType,
      description: items.description,
      personnelNeeded: items.personnelNeeded,
    };
    await firebase
      .firestore()
      .collection("centers")
      .doc(centersRef.id)
      .set(centersModel);
    return "";
  } catch (err) {
    return err.message;
  }
};

export const updateCenter = async (
  items: {
    id: string;
    centerName: string;
    centerContactNo: string;
    centerAddress: {
      street: string;
      poscode: string;
      state: string;
      district: string;
    };
    doses: number;
    volGeneral: number;
    volHealth: number;
    centerEmail: string;
    centerType: CenterType | string;
    description: string;
    personnelNeeded: Personnel[];
  },
  uploadImageFile: {
    data: File;
    url: string;
  }
) => {
  try {
    const centersRef = items.id;

    let centersModel: any;
    centersModel = {
      centerName: items.centerName,
      centerContactNo: items.centerContactNo,
      centerAddress: {
        street: items.centerAddress.street,
        poscode: items.centerAddress.poscode,
        state: items.centerAddress.state,
        district: items.centerAddress.district,
      },
      centerEmail: items.centerEmail,
      centerType: items.centerType,
      doses: items.doses,
      volGeneral: items.volGeneral,
      volHealth: items.volHealth,
      description: items.description,
      personnelNeeded: items.personnelNeeded,
    };
    if (uploadImageFile) {
      const uploadTask = await firebase
        .storage()
        .ref("centers")
        .child(centersRef + "/image.jpg")
        .put(uploadImageFile.data);
      const uploadTaskUrl: string = await uploadTask.ref.getDownloadURL();
      const urlParams = new URLSearchParams(uploadTaskUrl);
      const imageToken = urlParams.get("token");
      centersModel["imageToken"] = imageToken;
    }

    await firebase
      .firestore()
      .collection("centers")
      .doc(centersRef)
      .update(centersModel);
    return "";
  } catch (err) {
    return err.message;
  }
};

export const deleteCenter = async (items: { id: string }) => {
  try {
    const centersRef = items.id;
    if (centersRef) {
      await firebase
        .storage()
        .ref("centers")
        .child(centersRef + "/image.jpg")
        .delete();
      await firebase.firestore().collection("centers").doc(centersRef).delete();
      return "";
    } else return "Center is not deleted. Unknown Error.";
  } catch (err) {
    return err.message;
  }
};

export const clearCentersSocket = () => {
  if (centersSocket) {
    centersSocket();
  }
};
