import {
  ArrowLeftOutlined,
  CalendarOutlined,
  InfoCircleFilled,
  RiseOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Col, Row, Table } from "antd";
import axios from "axios";
import moment from "moment";
import "moment/locale/en-gb";
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Header from "../components/Header";
import CenterModel from "../models/Center";
import SecurityNavigator from "../navigator/SecurityNavigator";
import WindowResizer from "../navigator/WindowResizer";
import {
  getCenterAnalytics,
  getSpreadsheetAnalytics,
} from "../store/actions/analyticsActions";
import { CenterRes, getCenter } from "../store/actions/centerActions";
import { analyticStateInterface } from "../store/reducers/analyticReducer";
import { authStateInterface } from "../store/reducers/authReducer";
import {
  DeviceType,
  utilsStateInterface,
} from "../store/reducers/utilsReducer";
import { COLOR } from "../styles/Colors";
import { RowContainer, VerticalContainer } from "../styles/Layout";
import { Body, Title2, Title3 } from "../styles/Typography";
import { handleNavigation } from "../utils/navigator";

interface Props {
  location: any;
  history: any;
  match: any;
  utilsStore: utilsStateInterface;
  authStore: authStateInterface;
  analyticStore: analyticStateInterface;
  getCenter: (centerId: string) => void;
  getCenterAnalytics: () => void;
  getSpreadsheetAnalytics: () => void;
}

const iconStyle = {
  fontSize: 50,
  color: COLOR.RED,
};

interface State {
  startOfWeek: moment.Moment;
  endOfWeek: moment.Moment;
  center: CenterModel | null;
  centerAnalyticsTable: any[];
  bookingContactsTable: any[];
}

class CenterAnalytics extends Component<Props> {
  state: State = {
    startOfWeek: moment().startOf("isoWeek"),
    endOfWeek: moment().endOf("isoWeek"),
    center: null,
    centerAnalyticsTable: [],
    bookingContactsTable: [],
  };

  componentDidMount = async () => {
    if (this.props.authStore.user) {
      if (this.handleGetParams()) {
        await this.handleGetPreviewData();
      } else {
        this.handleNavigate("centers");
      }
    }
  };

  componentDidUpdate = async (prevProps: Props) => {
    if (
      this.props.authStore.user !== prevProps.authStore.user &&
      this.props.authStore.user
    ) {
      if (this.handleGetParams()) {
        await this.handleGetPreviewData();
      } else {
        this.handleNavigate("centers");
      }
    }
  };

  handleGetParams() {
    const id = this.props.match.params.id;
    return id;
  }

  handleGetPreviewData = async () => {
    try {
      this.props.getSpreadsheetAnalytics();
      const centerQuery: CenterRes = await getCenter(this.handleGetParams());
      if (!centerQuery.err) {
        this.setState({
          center: centerQuery.data,
        });
        this.props.getCenterAnalytics();
        await this.handleGetAnalyticsSpreadsheet();
      } else {
        this.handleNavigate("centers");
      }
    } catch (err) {
      this.handleNavigate("centers");
    }
  };

  handleGetAnalyticsSpreadsheet = async () => {
    let centerAnalyticsLink = "";
    let bookingContactLink = "";
    if (this.props.analyticStore.spreadsheetAnalytics) {
      const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
      const bucketUrl = isProd
        ? process.env.REACT_APP_PROD_STORAGE_BUCKET
        : process.env.REACT_APP_DEV_STORAGE_BUCKET;
      const prefixFileUrl =
        "https://firebasestorage.googleapis.com/v0/b/" + bucketUrl + "/o/";
      if (this.props.analyticStore.spreadsheetAnalytics.centerAnalytics) {
        const centerAnalyticsData =
          this.props.analyticStore.spreadsheetAnalytics.centerAnalytics;
        centerAnalyticsLink =
          prefixFileUrl +
          centerAnalyticsData.name +
          "?alt=media&token=" +
          centerAnalyticsData.token;
      }
      if (this.props.analyticStore.spreadsheetAnalytics.bookingContacts) {
        const bookingContactsData =
          this.props.analyticStore.spreadsheetAnalytics.bookingContacts;
        bookingContactLink =
          prefixFileUrl +
          bookingContactsData.name +
          "?alt=media&token=" +
          bookingContactsData.token;
      }
    }

    const centerAnalyticsfile = await axios.get(centerAnalyticsLink);
    const bookingContactfile = await axios.get(bookingContactLink);

    this.setState({
      centerAnalyticsTable: this.handleCSVProcessing(centerAnalyticsfile.data),
      bookingContactsTable: this.handleCSVProcessing(bookingContactfile.data),
    });
  };

  handleCSVProcessing = (data: any) => {
    const dataStringLines: any = data.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );
    const arr = dataStringLines.map((e: any) => e.replace(/,/g, ""));
    let startPoint = -1;
    arr.map((eachArr: any, index: number) => {
      const centerName =
        "Center Name : " + this.state.center?.centerName ?? "Invalid Center";
      const eachFilterArr = eachArr.replace(/[^A-Z0-9]/gi, "");
      const centerFilterName = centerName.replace(/[^A-Z0-9]/gi, "");
      if (eachFilterArr.includes(centerFilterName)) {
        startPoint = index;
      }
      return null;
    });

    if (startPoint > 0) {
      let endRow = true;
      const list = [];
      for (let i = startPoint + 3; endRow; i++) {
        const row = dataStringLines[i].split(
          /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
        );
        const obj: any = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push({ ...obj, key: i });
        } else {
          endRow = false;
        }
      }
      const columns = headers.slice(1).map((eachHeader: any) => ({
        title: eachHeader,
        dataIndex: eachHeader,
      }));
      return [columns, list];
    }
  };

  handleNavigate = (type: string) => {
    if (type === "centers") {
      handleNavigation(this.props.history, "/centers");
    } else if (type === "editCenter") {
      handleNavigation(
        this.props.history,
        "/editCenter/" + this.state.center?.id
      );
    }
  };

  handleWeekSelection = (value: any) => {
    this.setState({
      startOfWeek: moment(value).startOf("isoWeek"),
      endOfWeek: moment(value).endOf("isoWeek"),
    });
  };

  renderStatisticData = () => {
    const currentDate = this.props.analyticStore.centerAnalytics
      ? new Date(
          this.props.analyticStore.centerAnalytics.analyticalDate.seconds * 1000
        )
      : new Date();

    let totalVolunteers: string | number = "-";
    let thisWeekVolunteers: string | number = "-";
    let upcomingVolunteers: string | number = "-";
    const dateString = moment(currentDate).format("DD-MMMM-YYYY ha");

    if (this.props.analyticStore.centerAnalytics && this.state.center) {
      const currentAnalytics = this.props.analyticStore.centerAnalytics;
      currentAnalytics.centerArray.map((eachCenter) => {
        if (eachCenter.centerID === this.state.center?.id) {
          totalVolunteers = eachCenter.totalVolunteer;
          thisWeekVolunteers = eachCenter.thisWeekVolunteer;
          upcomingVolunteers = eachCenter.upcomingVolunteer;
        }
        return null;
      });
    }

    const statisticData = [
      {
        icon: <TeamOutlined style={iconStyle} />,
        title: totalVolunteers,
        body: "Total Volunteers",
        p: "Updated on: " + dateString,
      },
      {
        icon: <CalendarOutlined style={iconStyle} />,
        title: thisWeekVolunteers,
        body: "This Week Volunteers",
        p: "Updated on: " + dateString,
      },
      {
        icon: <RiseOutlined style={iconStyle} />,
        title: upcomingVolunteers,
        body: "Upcoming Volunteers",
        p: "Updated on: " + dateString,
      },
    ];

    return statisticData;
  };

  returnTableInfo = (position: number) => {
    let tableInfo: any = [
      {
        title: "Slots Left (General Volunteers)",
        volGeneral: this.state.center?.volGeneral,
        columns: this.state.centerAnalyticsTable
          ? this.state.centerAnalyticsTable[0]
          : undefined,
        dataSource: this.state.centerAnalyticsTable
          ? this.state.centerAnalyticsTable[1]
          : undefined,
      },
      {
        title: "Booking Contacts",
        columns: this.state.bookingContactsTable
          ? this.state.bookingContactsTable[0]
          : undefined,
        dataSource: this.state.bookingContactsTable
          ? this.state.bookingContactsTable[1]
          : undefined,
      },
    ];
    return tableInfo[position];
  };

  renderStatisticCard = (detail: any) => {
    return (
      <Col
        span={this.props.utilsStore.deviceType === DeviceType.Mobile ? 24 : 8}
      >
        <BaseCardContainer
          style={{
            height:
              this.props.utilsStore.deviceType === DeviceType.Mobile
                ? "auto"
                : "100%",
            marginBottom:
              this.props.utilsStore.deviceType === DeviceType.Mobile ? 10 : 0,
          }}
        >
          <VerticalContainer
            style={{
              margin: 25,
            }}
          >
            <RowContainer>
              {detail.icon}
              <VerticalContainer
                style={{
                  alignItems: "flex-start",
                  paddingLeft: 20,
                }}
              >
                <Title3 style={{ marginBottom: 0 }}>{detail.title}</Title3>
                <Title3 style={{ marginBottom: 0 }}>{detail.body}</Title3>
                <Body style={{ marginBottom: 0 }}>{detail.p}</Body>
              </VerticalContainer>
            </RowContainer>
          </VerticalContainer>
        </BaseCardContainer>
      </Col>
    );
  };

  renderTable = (detail: any) => {
    return (
      <VerticalContainer
        style={{
          alignItems: "flex-center",
          width: "90%",
          padding: "0px 10px",
        }}
      >
        <Title3
          style={{
            margin: "25px 0px 15px 0px",
            alignSelf: "flex-start",
          }}
        >
          {detail.title}
        </Title3>
        {detail.volGeneral && (
          <RowContainer
            style={{
              width: "100%",
              justifyContent: "flex-start",
              marginBottom: 10,
            }}
          >
            <InfoCircleFilled style={{ color: COLOR.RED }} />
            <Body
              style={{
                margin: "0px 0px 0px 5px",
                alignSelf: "flex-start",
                color: COLOR.BLACK,
                fontSize: "16px",
              }}
            >
              Recommended Volunteers : {this.state.center?.volGeneral}
            </Body>
          </RowContainer>
        )}
        <Table
          bordered
          style={{
            width: "100%",
            maxWidth: "100%",
            cursor: "pointer",
            overflowX: "auto",
          }}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
          }}
          columns={detail.columns}
          dataSource={detail.dataSource}
        />
      </VerticalContainer>
    );
  };

  render() {
    if (this.props.authStore.userLoading) {
      return null;
    }

    return (
      <VerticalContainer style={{ width: "100%" }}>
        <WindowResizer />
        <SecurityNavigator location={this.props.location} />
        <Header history={this.props.history} location={this.props.location} />
        <VerticalContainer
          style={{
            width: "100%",
            maxWidth: 1200,
            padding: "40px 0px",
          }}
        >
          <VerticalContainer
            style={{
              alignItems: "flex-start",
              width: "90%",
              marginLeft: 20,
            }}
          >
            <RowContainer style={{ marginBottom: 20 }}>
              <ArrowLeftOutlined
                style={{
                  color: COLOR.RED,
                  fontSize: 25,
                  marginRight: 10,
                }}
                onClick={this.handleNavigate.bind(
                  this,
                  this.props.authStore.user?.role === "SUPER"
                    ? "editCenter"
                    : "centers"
                )}
              />
              <Title2 style={{ marginBottom: 0 }}>
                Analytics of {this.state.center?.centerName ?? ""}
              </Title2>
            </RowContainer>
          </VerticalContainer>
          <Row
            style={{
              width: "90%",
            }}
            gutter={16}
          >
            {this.renderStatisticCard(this.renderStatisticData()[0])}
            {this.renderStatisticCard(this.renderStatisticData()[1])}
            {this.renderStatisticCard(this.renderStatisticData()[2])}
          </Row>
          {this.renderTable(this.returnTableInfo(0))}
          {this.renderTable(this.returnTableInfo(1))}
          {/* <VerticalContainer
            style={{
              alignItems: "flex-center",
              width: "90%",
              marginTop: 50,
            }}
          >
            <RowContainer
              style={{
                width: "100%",
                marginBottom: 30,
                justifyContent: "flex-start",
              }}
            >
              <Title3 style={{ marginBottom: 0 }}>
                Total Volunteers ({startOfWeek.format("DD/MM/YYYY")} -{" "}
                {endOfWeek.format("DD/MM/YYYY")})
              </Title3>
              <div style={{ flexGrow: 1 }} />
              <Body style={{ margin: "0px 10px 0px 0px" }}>Change week :</Body>
              <WeekPicker
                defaultValue={moment()}
                locale={locale}
                allowClear={false}
                onChange={this.handleWeekSelection}
              />
            </RowContainer>
            {this.renderState()}
          </VerticalContainer> */}
        </VerticalContainer>
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
    authStore: state.authStore,
    analyticStore: state.analyticStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getCenter: (centerId: string) => dispatch(getCenter(centerId)),
    getCenterAnalytics: async () => await dispatch(getCenterAnalytics()),
    getSpreadsheetAnalytics: () => dispatch(getSpreadsheetAnalytics()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CenterAnalytics);

const BaseCardContainer = styled(VerticalContainer)`
  background: ${COLOR.LIGHTGREY};
  width: 100%;
  border-radius: 5px;
`;
