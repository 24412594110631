import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Divider, Input, Modal, notification, Spin } from "antd";
import { Component } from "react";
import { connect } from "react-redux";
import Button from "../components/Button";
import Header from "../components/Header";
import TextInput from "../components/TextInput";
import ResourceModel from "../models/Resources";
import SecurityNavigator from "../navigator/SecurityNavigator";
import WindowResizer from "../navigator/WindowResizer";
import {
  createResources,
  deleteResource,
  getResource,
  ResourceRes,
  updateResource,
} from "../store/actions/resActions";
import { sleep } from "../store/actions/utilsActions";
import { authStateInterface } from "../store/reducers/authReducer";
import {
  DeviceType,
  utilsStateInterface,
} from "../store/reducers/utilsReducer";
import {
  FlexibleRowContainer,
  RowContainer,
  VerticalContainer,
} from "../styles/Layout";
import {
  CustomActionTitle,
  Footer as Warning,
  Title3,
} from "../styles/Typography";
import { handleNavigation, handleNotiNavigation } from "../utils/navigator";

const { TextArea } = Input;

interface Props {
  history: any;
  match: any;
  location: any;
  authStore: authStateInterface;
  utilsStore: utilsStateInterface;
}

interface resourcesStateError {
  titleError: string;
  descriptionError: string;
  linkError: string;
}

interface State {
  loading: false;
  resource: ResourceModel;
  resourcesError: resourcesStateError;
}

const { confirm } = Modal;

class ResourcesEditor extends Component<Props> {
  state: State = {
    loading: false,
    resource: {
      title: "",
      description: "",
      link: "",
      id: "",
      createdAt: new Date(),
    },
    resourcesError: {
      titleError: "",
      descriptionError: "",
      linkError: "",
    },
  };

  componentDidMount = () => {
    if (this.getParams()) {
      this.handleGetPreviewData();
    } else {
      this.handleNavigate("resources");
    }
  };

  getParams() {
    const id = this.props.match.params.id;
    return id;
  }

  handleLoading = (value: boolean) => {
    this.setState({
      loading: value,
    });
  };

  handleNavigate = (type: string) => {
    if (type === "resources") {
      handleNavigation(this.props.history, "/resources");
    }
  };

  handleNotification = (type: string, message: string, description: string) => {
    if (type === "success") {
      notification.success({ message: message, description: "" });
    } else if (type === "warning") {
      notification.warning({
        message: message,
        description: description,
      });
    }
  };

  handleGetPreviewData = async () => {
    if (this.getParams() !== "create") {
      this.handleLoading(true);
      const resourceQuery: ResourceRes = await getResource(this.getParams());
      if (resourceQuery.err) {
        this.handleNavigate("resources");
      } else {
        this.setState({
          resource: resourceQuery.data,
        });
        this.handleLoading(false);
      }
    }
  };

  handleChange = (e: any) => {
    let resource = JSON.parse(JSON.stringify(this.state.resource));

    resource[e.target.id] = e.target.value;
    this.setState({
      resource,
    });
  };

  handleCheckCondition = (typeList: string[]) => {
    let resourcesCheck = JSON.parse(JSON.stringify(this.state.resourcesError));
    typeList.map((eachType) => {
      switch (eachType) {
        case "title":
          if (this.state.resource.title.replace(/\s/g, "").length <= 0) {
            resourcesCheck["titleError"] = "Title cannot be empty";
          } else {
            resourcesCheck["titleError"] = "";
          }
          break;
        case "description":
          if (this.state.resource.description.replace(/\s/g, "").length <= 0) {
            resourcesCheck["descriptionError"] = "Description cannot be empty";
          } else {
            resourcesCheck["descriptionError"] = "";
          }
          break;
        case "link":
          const linkFilter = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
          if (this.state.resource.link.replace(/\s/g, "").length <= 0) {
            resourcesCheck["linkError"] = "Link cannot be empty";
          } else if (
            !linkFilter.test(this.state.resource.link.replace(/\s/g, ""))
          ) {
            resourcesCheck["linkError"] = "Must be a valid link";
          } else {
            resourcesCheck["linkError"] = "";
          }
          break;
        default:
          break;
      }
      return null;
    });
    this.setState({
      resourcesError: resourcesCheck,
    });
  };

  handleDeleteResource = async () => {
    this.handleLoading(true);
    if (this.state.resource.id) {
      const resourcesdelete = await deleteResource({
        id: this.getParams(),
      });
      if (resourcesdelete) {
        this.handleNotification(
          "warning",
          "Resource Could Not Be Deleted",
          resourcesdelete
        );
        this.handleLoading(false);
      } else {
        handleNotiNavigation(this.props.history, 3, "/resources");
      }
    }
  };

  handleEditResource = async () => {
    this.handleLoading(true);
    this.handleCheckCondition(["title", "description", "link"]);
    await sleep(250);
    let conditionPassed: boolean = false;
    conditionPassed =
      !this.state.resourcesError.titleError &&
      !this.state.resourcesError.descriptionError &&
      !this.state.resourcesError.linkError
        ? true
        : false;
    if (conditionPassed) {
      const resourcesUpdate = await updateResource({
        id: this.state.resource.id,
        title: this.state.resource.title,
        description: this.state.resource.description,
        link: this.state.resource.link,
      });
      if (resourcesUpdate) {
        this.handleNotification(
          "warning",
          "Resource Could Not Be Updated",
          resourcesUpdate
        );
        this.handleLoading(false);
      } else {
        handleNotiNavigation(this.props.history, 2, "/resources");
      }
    } else {
      this.handleNotification("warning", "Resource Could Not Be Updated", "");
      this.handleLoading(false);
    }
  };

  handleCreateResource = async () => {
    this.handleLoading(true);
    this.handleCheckCondition(["title", "description", "link"]);
    await sleep(250);
    let conditionPassed: boolean = false;
    conditionPassed =
      !this.state.resourcesError.titleError &&
      !this.state.resourcesError.descriptionError &&
      !this.state.resourcesError.linkError
        ? true
        : false;
    if (conditionPassed) {
      const resourcesCreation = await createResources({
        title: this.state.resource.title,
        description: this.state.resource.description,
        link: this.state.resource.link,
      });
      if (resourcesCreation) {
        this.handleNotification(
          "warning",
          "Resource Could Not Be Deleted",
          resourcesCreation
        );
        this.handleLoading(false);
      } else {
        handleNotiNavigation(this.props.history, 1, "/resources");
      }
    } else {
      this.handleNotification("warning", "Resource Could Not Be Added", "");
      this.handleLoading(false);
    }
  };

  handleDeleteConfirmation() {
    confirm({
      title: "Are you sure to delete this resource?",
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        this.handleDeleteResource();
      },
      onCancel() {},
    });
  }

  renderButtons = () => {
    let buttonWidth = 150;
    let marginTop = 0;
    if (this.props.utilsStore.deviceType === DeviceType.Mobile) {
      buttonWidth = 85;
      marginTop = 10;
    }

    if (this.getParams() !== "create" && this.getParams()) {
      return (
        <RowContainer style={{ marginTop: marginTop }}>
          <Button
            text="Delete"
            width={buttonWidth}
            margin={"0px 10px"}
            onClick={this.handleDeleteConfirmation.bind(this)}
            small={true}
          />
          <Button
            text="Update"
            width={buttonWidth}
            margin={"0px 10px"}
            onClick={this.handleEditResource}
            small={true}
          />
        </RowContainer>
      );
    } else {
      return (
        <>
          <div style={{ marginTop: 5 }} />
          <Button
            text="Add"
            width={buttonWidth}
            margin={"0px 10px"}
            onClick={this.handleCreateResource}
            small={true}
          />
        </>
      );
    }
  };

  render() {
    if (this.props.authStore.userLoading) {
      return null;
    }

    return (
      <Spin
        spinning={this.state.loading}
        size="large"
        style={{
          width: "100vw",
          height: "100vh",
          maxHeight: "none",
        }}
      >
        <Header history={this.props.history} location={this.props.location} />
        <WindowResizer />
        <SecurityNavigator location={this.props.location} role="SUPER" />
        <VerticalContainer
          style={{
            width: "100%",
          }}
        >
          <VerticalContainer
            style={{
              alignItems: "flex-start",
              width: "90%",
              height: "100%",
              maxWidth: 1200,
              padding: "40px 0px",
            }}
          >
            <FlexibleRowContainer
              style={{
                width: "100%",
                alignItems: "flex-start",
              }}
            >
              <RowContainer>
                <CustomActionTitle
                  onClick={this.handleNavigate.bind(this, "resources")}
                  title="Resources"
                >
                  Resources
                </CustomActionTitle>
                <Title3 style={{ margin: "0px 10px" }}>/</Title3>
                <Title3 style={{ marginBottom: 0 }}>
                  {this.props.match.params.id === "create"
                    ? "New"
                    : this.state.resource.title}
                </Title3>
              </RowContainer>
              <div style={{ flex: 1 }} />
              {this.renderButtons()}
            </FlexibleRowContainer>
            <Divider style={{ marginTop: 10, marginBottom: 30 }} />
            <RowContainer style={{ width: "100%", alignItems: "flex-start" }}>
              <VerticalContainer
                style={{ width: "48%", alignItems: "flex-start" }}
              >
                <Title3 style={{ marginBottom: 5 }}>Title</Title3>
                <TextInput
                  id="title"
                  value={this.state.resource.title}
                  width={"100%"}
                  placeholder={"Title"}
                  onChange={this.handleChange}
                  maxLength={60}
                />
                {this.state.resourcesError.titleError && (
                  <Warning style={{ marginBottom: 0 }}>
                    {this.state.resourcesError.titleError}
                  </Warning>
                )}
              </VerticalContainer>
              <div style={{ flexGrow: 1 }} />
              <VerticalContainer
                style={{ width: "48%", alignItems: "flex-start" }}
              >
                <Title3 style={{ marginBottom: 5 }}>Description</Title3>
                <TextArea
                  id="description"
                  value={this.state.resource.description}
                  style={{ width: "100%" }}
                  placeholder={"Description"}
                  onChange={this.handleChange}
                />
                {this.state.resourcesError.descriptionError && (
                  <Warning style={{ marginBottom: 0 }}>
                    {this.state.resourcesError.descriptionError}
                  </Warning>
                )}
              </VerticalContainer>
              <div style={{ flexGrow: 1 }} />
            </RowContainer>
            <RowContainer style={{ width: "100%" }}>
              <VerticalContainer
                style={{
                  width: "48%",
                  alignItems: "flex-start",
                }}
              >
                <Title3 style={{ marginBottom: 5 }}>Link</Title3>
                <TextInput
                  id="link"
                  value={this.state.resource.link}
                  width={"100%"}
                  placeholder={"URL"}
                  onChange={this.handleChange}
                />
                {this.state.resourcesError.linkError && (
                  <Warning style={{ marginBottom: 0 }}>
                    {this.state.resourcesError.linkError}
                  </Warning>
                )}
              </VerticalContainer>
              <div style={{ flexGrow: 1 }} />
            </RowContainer>
          </VerticalContainer>
        </VerticalContainer>
      </Spin>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(ResourcesEditor);
