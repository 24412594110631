export enum AdminRole {
  SUPER = "Super Admin",
  STATE = "State Admin",
  CENTER = "Center Admin",
  NGO = "NGO Admin",
  HEALTH = "Health Admin",
}

export default interface Admin {
  id: string;
  name: string;
  email: string;
  role: keyof typeof AdminRole;
  state?: string;
  center?: string[];
  ngoName?: string;
}
