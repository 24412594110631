import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AddBooking from "../pages/AddBooking";
import BookingEditor from "../pages/BookingEditor";
import Bookings from "../pages/Bookings";
import CenterAnalytics from "../pages/CenterAnalytics";
import CenterEditor from "../pages/CenterEditor";
import Centers from "../pages/Centers";
import Dashboard from "../pages/Dashboard";
import HealthVerificationLink from "../pages/HealthVerificationLink";
import HealthVerificationLinkEditor from "../pages/HealthVerificationLinkEditor";
import LoginScreen from "../pages/LoginScreen";
import Mobile from "../pages/Mobile";
import Ngo from "../pages/Ngo";
import NgoEditor from "../pages/NgoEditor";
import NotFound from "../pages/NotFound";
import Resources from "../pages/Resources";
import ResourcesEditor from "../pages/ResourcesEditor";
import RoleEditor from "../pages/RoleEditor";
import Roles from "../pages/Roles";
import Profile from "../pages/VolunteerEditor";
import Volunteers from "../pages/Volunteers";
import {
  getAuthListener,
  removeAuthListener,
} from "../store/actions/authActions";
import { clearCentersSocket } from "../store/actions/centerActions";
import { clearResourcesSocket } from "../store/actions/resActions";
import {
  updateDeviceSize,
  updateDeviceType,
} from "../store/actions/utilsActions";
import {
  DeviceSize,
  DeviceType,
  utilsStateInterface,
} from "../store/reducers/utilsReducer";

interface Props {
  utilsStore: utilsStateInterface;
  updateDeviceType: (deviceType: DeviceType) => Promise<void>;
  updateDeviceSize: (deviceSize: number) => Promise<void>;
  getAuthListener: () => void;
  removeAuthListener: () => void;
}

class Navigator extends Component<Props> {
  componentDidMount() {
    this.handleCalculateWidth();
    window.addEventListener("resize", this.handleCalculateWidth);
    this.props.getAuthListener();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleCalculateWidth);
    this.props.removeAuthListener();
    clearResourcesSocket();
    clearCentersSocket();
  }

  handleCalculateWidth = () => {
    let deviceType: DeviceType = DeviceType.Laptop;
    if (window.innerWidth < DeviceSize.minLaptopBreakpoints)
      deviceType = DeviceType.Tablet;
    else deviceType = DeviceType.Laptop;

    if (deviceType !== this.props.utilsStore.deviceType)
      this.props.updateDeviceType(deviceType);
    if (window.innerWidth !== this.props.utilsStore.deviceSize)
      this.props.updateDeviceSize(window.innerWidth);
  };

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={LoginScreen} />
          <Route exact path="/mobile" component={Mobile} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/volunteers" component={Volunteers} />
          <Route exact path="/editVolunteer/:id" component={Profile} />
          <Route exact path="/resources" component={Resources} />
          <Route exact path="/editResources/:id" component={ResourcesEditor} />
          <Route exact path="/roles" component={Roles} />
          <Route exact path="/editRole/:id" component={RoleEditor} />
          <Route exact path="/centers" component={Centers} />
          <Route exact path="/editCenter/:id" component={CenterEditor} />
          <Route exact path="/bookings" component={Bookings} />
          <Route exact path="/addBooking" component={AddBooking} />
          <Route exact path="/editBooking/:id" component={BookingEditor} />
          <Route
            exact
            path="/centerAnalytics/:id"
            component={CenterAnalytics}
          />
          <Route
            exact
            path="/healthVerificationLink"
            component={HealthVerificationLink}
          />
          <Route
            exact
            path="/editHealthVerificationLink/:id"
            component={HealthVerificationLinkEditor}
          />
          <Route exact path="/ngo" component={Ngo} />
          <Route exact path="/editNgo/:id" component={NgoEditor} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateDeviceType: (deviceType: DeviceType) =>
      dispatch(updateDeviceType(deviceType)),
    updateDeviceSize: (deviceSize: number) =>
      dispatch(updateDeviceSize(deviceSize)),
    getAuthListener: () => dispatch(getAuthListener()),
    removeAuthListener: () => dispatch(removeAuthListener()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigator);
