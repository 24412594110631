import Centers from "../../models/Center";

export interface centerStateInterface {
  centers: Centers[];
}

const initState: centerStateInterface = {
    centers: [],
};

const centerReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_CENTERS":
      return {
        ...state,
        centers: action.payload.centers,
      };
    default:
      return state;
  }
};

export default centerReducer;
