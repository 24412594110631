import {
  ExclamationCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Col,
  DatePicker,
  Divider,
  Modal,
  notification,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import moment from "moment";
import React, { Component, ReactElement } from "react";
import { connect } from "react-redux";
import Button from "../components/Button";
import Header from "../components/Header";
import TextArea from "../components/TextArea";
import TextInput from "../components/TextInput";
import CenterModel, {
  BookingSlot,
  CenterType,
  Personnel,
} from "../models/Center";
import SecurityNavigator from "../navigator/SecurityNavigator";
import WindowResizer from "../navigator/WindowResizer";
import {
  CenterRes,
  createCenter,
  deleteCenter,
  getBookings,
  getCenter,
  updateCenter,
  UsersBookingModel,
  UsersBookingRes,
} from "../store/actions/centerActions";
import { sleep } from "../store/actions/utilsActions";
import { authStateInterface } from "../store/reducers/authReducer";
import {
  DeviceType,
  utilsStateInterface,
} from "../store/reducers/utilsReducer";
import { COLOR } from "../styles/Colors";
import {
  FlexibleRowContainer,
  RowContainer,
  VerticalContainer,
} from "../styles/Layout";
import {
  Body,
  CustomActionTitle,
  Footer as Warning,
  Title3,
} from "../styles/Typography";
import { getDistrictList, getStateList } from "../utils/districtList";
import { handleNavigation, handleNotiNavigation } from "../utils/navigator";

enum ModalState {
  CREATE = "Create",
  UPDATE = "Update",
  INVISIBLE = "Invisible",
}

interface Props {
  history: any;
  location: any;
  match: any;
  authStore: authStateInterface;
  utilsStore: utilsStateInterface;
}

interface CenterStateError {
  centerNameError: string;
  centerContactNoError: string;
  centerEmailError: string;
  centerAddressError: {
    streetError: string;
    poscodeError: string;
  };
  dosesError: string;
  volGeneralError: string;
  volHealthError: string;
  imageTokenError: string;
  descriptionError: string;
  startDateError: string;
  endDateError: string;
  volGeneralNoError: string;
  volHealthVacNoError: string;
  volHealthObsNoError: string;
  volHealthCounsNoError: string;
}

interface UploadImageFile {
  data: File;
  url: string;
}

interface State {
  loading: boolean;
  center: CenterModel;
  bookings: UsersBookingModel[];
  modal: ModalState;
  deletionModal: boolean;
  deletionIndex: number;
  startDate: Date;
  endDate: Date;
  volGeneralNo: number;
  volHealthVacNo: number;
  volHealthObsNo: number;
  volHealthCounsNo: number;
  selectedPersonnelIndex: number;
  shift: keyof typeof BookingSlot;
  centerError: CenterStateError;
  uploadImageFile: UploadImageFile | null;
}

const { confirm } = Modal;
const { Option } = Select;
const dateFormat = "DD/MM/YYYY";

class CenterEditor extends Component<Props> {
  state: State = {
    loading: false,
    modal: ModalState.INVISIBLE,
    deletionModal: false,
    deletionIndex: -1,
    center: {
      id: "",
      centerName: "",
      centerAddress: {
        street: "",
        poscode: "",
        state: getStateList()[0],
        district: getDistrictList("")[0],
      },
      centerEmail: "",
      centerContactNo: "",
      imageToken: "",
      centerType: Object.keys(CenterType)[0] as keyof typeof CenterType,
      doses: 0,
      volGeneral: 0,
      volHealth: 0,
      description: "",
      personnelNeeded: [],
    },
    centerError: {
      centerNameError: "",
      centerContactNoError: "",
      centerEmailError: "",
      centerAddressError: {
        streetError: "",
        poscodeError: "",
      },
      imageTokenError: "",
      descriptionError: "",
      dosesError: "",
      volGeneralError: "",
      volHealthError: "",
      startDateError: "",
      endDateError: "",
      volGeneralNoError: "",
      volHealthVacNoError: "",
      volHealthObsNoError: "",
      volHealthCounsNoError: "",
    },
    bookings: [],
    startDate: new Date(),
    endDate: new Date(),
    volGeneralNo: 0,
    volHealthVacNo: 0,
    volHealthObsNo: 0,
    volHealthCounsNo: 0,
    selectedPersonnelIndex: -1,
    shift: Object.keys(BookingSlot)[2] as keyof typeof BookingSlot,
    uploadImageFile: null,
  };

  columns: any = [
    {
      title: "Start Date",
      dataIndex: "startDate",
      sorter: (a: any, b: any) =>
        moment(a.startDate).unix() - moment(b.startDate).unix(),
      render: (date: Date) => date.toDateString(),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      sorter: (a: any, b: any) =>
        moment(a.endDate).unix() - moment(b.endDate).unix(),
      render: (date: Date) => date.toDateString(),
    },
    {
      title: "General Volunteer",
      dataIndex: "volGeneralNo",
      sorter: (a: any, b: any) => a.volGeneralNo - b.volGeneralNo,
    },
    {
      title: "Vaccination Volunteer",
      dataIndex: "volHealthVacNo",
      sorter: (a: any, b: any) => a.volHealthVacNo - b.volHealthVacNo,
    },
    {
      title: "Observation Bay Volunteer",
      dataIndex: "volHealthObsNo",
      sorter: (a: any, b: any) => a.volHealthObsNo - b.volHealthObsNo,
    },
    {
      title: "Counselling Volunteer",
      dataIndex: "volHealthCounsNo",
      sorter: (a: any, b: any) => a.volHealthCounsNo - b.volHealthCounsNo,
    },
    {
      title: () => <div style={{ textAlign: "center" }}>Action</div>,
      key: "action",
      render: (record: any) => (
        <RowContainer>
          <Button
            text="Update"
            width={"100%"}
            onClick={() => {
              this.handleModal(ModalState.UPDATE, record.key);
            }}
            small={true}
          />
          <div style={{ width: 20 }} />
          <Button
            text="Delete"
            width={"100%"}
            onClick={() =>
              this.handleAvailabilityDeletionModal(true, record.key)
            }
            small={true}
          />
        </RowContainer>
      ),
    },
  ];

  handleGetParams() {
    const id = this.props.match.params.id;
    return id;
  }

  componentDidMount = () => {
    if (this.handleGetParams()) {
      this.handleGetPreviewData();
    } else {
      this.handleNavigate("centers", "");
    }
  };

  handleGetPreviewData = async () => {
    if (this.handleGetParams() !== "create") {
      this.handleLoading(true);
      const centerQuery: CenterRes = await getCenter(this.handleGetParams());
      const bookingQuery: UsersBookingRes = await getBookings(
        this.handleGetParams()
      );
      if (centerQuery.err) {
        handleNotiNavigation(this.props.history, 4, "/centers");
      } else {
        this.setState({
          center: centerQuery.data,
          bookings: bookingQuery.data,
        });
        this.handleLoading(false);
      }
    }
  };

  handleNavigate = (type: string, id: string) => {
    if (type === "centers") {
      handleNavigation(this.props.history, "/centers");
    } else if (type === "centerAnalytics") {
      handleNavigation(this.props.history, "/centerAnalytics/" + id);
    }
  };

  handleModal = (state: ModalState, selectedIndex: number) => {
    this.setState({
      modal: state,
    });
    if (state === ModalState.INVISIBLE) {
      const personnelError = JSON.parse(JSON.stringify(this.state.centerError));
      personnelError["startDateError"] = "";
      personnelError["endDateError"] = "";
      personnelError["volGeneralNoError"] = "";
      personnelError["volHealthVacNoError"] = "";
      personnelError["volHealthObsNoError"] = "";
      personnelError["volHealthCounsNoError"] = "";
      this.setState({
        startDate: new Date(),
        endDate: new Date(),
        volGeneralNo: 0,
        volHealthVacNo: 0,
        volHealthObsNo: 0,
        volHealthCounsNo: 0,
        selectedPersonnelIndex: -1,
        centerError: personnelError,
      });
    } else if (state === ModalState.UPDATE) {
      const selectedPersonnel =
        this.state.center.personnelNeeded[selectedIndex];
      this.setState({
        volGeneralNo: selectedPersonnel.volGeneralNo,
        volHealthVacNo: selectedPersonnel.volHealthVacNo,
        volHealthObsNo: selectedPersonnel.volHealthObsNo,
        volHealthCounsNo: selectedPersonnel.volHealthCounsNo,
        selectedPersonnelIndex: selectedIndex,
      });
    }
  };

  handleInputImage = () => {
    const element: HTMLElement = document.getElementById(
      "upload-button"
    ) as HTMLElement;
    element.click();
  };

  handleImageUpload = async (e: any) => {
    let imageCheck = JSON.parse(JSON.stringify(this.state.centerError));
    const imageFile = e.target.files[0];
    if (!imageFile) {
      imageCheck["imageTokenError"] = "Please select an image";
      this.setState({ centerError: imageCheck });
      return false;
    }
    if (!imageFile.type.match("image.*")) {
      imageCheck["imageTokenError"] = "Please select a valid image";
      this.setState({ centerError: imageCheck });
      return false;
    }
    try {
      this.setState({
        uploadImageFile: {
          data: imageFile,
          url: URL.createObjectURL(imageFile),
        },
      });
    } catch (err) {
      this.handleNotification(
        "warning",
        "Image Could Not Be Updated",
        err.message
      );
    }
  };

  handleLoading = (value: boolean) => {
    this.setState({
      loading: value,
    });
  };

  handleNotification = (type: string, message: string, description: string) => {
    if (type === "success") {
      notification["success"]({ message: message, description: "" });
    } else if (type === "warning") {
      notification["warning"]({
        message: message,
        description: description,
      });
    }
  };

  handleChange = (e: any) => {
    const center = JSON.parse(JSON.stringify(this.state.center));
    center[e.target.id] = e.target.value;
    this.handleParseCenter(center);
  };

  handleSelectChange = (e: keyof typeof CenterType) => {
    const center = JSON.parse(JSON.stringify(this.state.center));
    center["centerType"] = e;
    this.handleParseCenter(center);
  };

  handleAddressChange = (e: any) => {
    const center = JSON.parse(JSON.stringify(this.state.center));
    center["centerAddress"][e.target.id] = e.target.value;
    this.handleParseCenter(center);
  };

  handleAddressChangeSelect = (type: string, value: any) => {
    const center = JSON.parse(JSON.stringify(this.state.center));
    center["centerAddress"][type] = value;
    if (type === "state") {
      center["centerAddress"]["district"] = getDistrictList(value)[0];
    }
    this.handleParseCenter(center);
  };

  handleParseCenter = (center: CenterModel) => {
    const tempPersonnelNeeded: Personnel[] = [];
    if (center.personnelNeeded.length > 0) {
      center.personnelNeeded.map((eachPersonnelNeeded: any) => {
        const tempPersonnel: Personnel = {
          startDate: new Date(eachPersonnelNeeded.startDate),
          endDate: new Date(eachPersonnelNeeded.endDate),
          volGeneralNo: eachPersonnelNeeded.volGeneralNo,
          volHealthVacNo: eachPersonnelNeeded.volHealthVacNo,
          volHealthObsNo: eachPersonnelNeeded.volHealthObsNo,
          volHealthCounsNo: eachPersonnelNeeded.volHealthCounsNo,
          shift: eachPersonnelNeeded.shift,
        };
        tempPersonnelNeeded.push(tempPersonnel);
        return null;
      });
    }
    center["personnelNeeded"] = tempPersonnelNeeded;
    this.setState({ center });
  };

  handleDateChange = (id: string, e: any, value: string) => {
    this.setState({
      [id]: new Date(e),
    });
  };

  handleVolChange = (e: any) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleCheckCondition = (typeList: string[]) => {
    let centerCheck = JSON.parse(JSON.stringify(this.state.centerError));
    typeList.map((eachType) => {
      switch (eachType) {
        case "name":
          if (this.state.center.centerName.replace(/\s/g, "").length <= 0) {
            centerCheck["centerNameError"] = "Name cannot be empty";
          } else {
            centerCheck["centerNameError"] = "";
          }
          break;

        case "contactNo":
          const phoneFilter = /^(\+60)-*[0-9]{8,10}$/;
          if (!phoneFilter.test(this.state.center.centerContactNo)) {
            centerCheck["centerContactNoError"] =
              "Please enter a valid mobile number";
          } else {
            centerCheck["centerContactNoError"] = "";
          }
          break;
        case "street":
          if (
            this.state.center.centerAddress.street.replace(/\s/g, "").length <=
            0
          ) {
            centerCheck["centerAddressError"]["streetError"] =
              "Address cannot be empty";
          } else {
            centerCheck["centerAddressError"]["streetError"] = "";
          }
          break;
        case "poscode":
          const posFilter = /^(\d{5})?$/;
          if (
            !posFilter.test(this.state.center.centerAddress.poscode) ||
            this.state.center.centerAddress.poscode.replace(/\s/g, "").length <=
              0
          ) {
            centerCheck["centerAddressError"]["poscodeError"] =
              "Please enter a valid postcode";
          } else {
            centerCheck["centerAddressError"]["poscodeError"] = "";
          }
          break;
        case "email":
          const filter =
            /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
          if (!filter.test(this.state.center.centerEmail.replace(/\s/g, "")))
            centerCheck["centerEmailError"] = "Please enter a valid email";
          else centerCheck["centerEmailError"] = "";
          break;
        case "doses":
          if (this.state.center.doses <= 0) {
            centerCheck["dosesError"] = "Doses Per Day cannot be empty";
          } else {
            centerCheck["dosesError"] = "";
          }
          break;
        case "volGeneral":
          if (this.state.center.volGeneral <= 0) {
            centerCheck["volGeneralError"] =
              "General Volunteer cannot be empty";
          } else {
            centerCheck["volGeneralError"] = "";
          }
          break;
        case "volHealth":
          if (this.state.center.volHealth < 0) {
            centerCheck["volHealthError"] =
              "Healthcare Volunteer cannot be empty";
          } else {
            centerCheck["volHealthError"] = "";
          }
          break;
        case "description":
          if (this.state.center.description.replace(/\s/g, "").length <= 0) {
            centerCheck["descriptionError"] = "Description cannot be empty";
          } else {
            centerCheck["descriptionError"] = "";
          }
          break;
        case "startDate":
        case "endDate":
          if (this.state.center.personnelNeeded.length > 0) {
            let condition = "";
            this.state.center.personnelNeeded.map((eachPersonnelDate) => {
              if (!condition) {
                const plannedStart = new Date(this.state.startDate);
                const plannedEnd = new Date(this.state.endDate);
                const startDate = new Date(eachPersonnelDate.startDate);
                const endDate = new Date(eachPersonnelDate.endDate);
                plannedStart.setHours(0, 0, 0, 0);
                plannedEnd.setHours(0, 0, 0, 0);
                startDate.setHours(0, 0, 0, 0);
                endDate.setHours(0, 0, 0, 0);

                if (eachType === "startDate") {
                  if (
                    moment(plannedStart).isBetween(
                      moment(startDate),
                      moment(endDate)
                    ) ||
                    moment(plannedStart).isSame(startDate, "day") ||
                    moment(startDate).isBetween(
                      moment(plannedStart),
                      moment(plannedEnd)
                    ) ||
                    moment(startDate).isSame(plannedStart, "day")
                  ) {
                    condition = "overlapStart";
                  }
                } else {
                  if (
                    moment(plannedEnd).isBetween(
                      moment(startDate),
                      moment(endDate)
                    ) ||
                    moment(plannedEnd).isSame(startDate, "day") ||
                    moment(endDate).isBetween(
                      moment(plannedStart),
                      moment(plannedEnd)
                    ) ||
                    moment(endDate).isSame(plannedStart, "day")
                  ) {
                    condition = "overlapEnd";
                  }
                }
              }
              return null;
            });

            if (eachType === "endDate") {
              if (this.state.endDate < this.state.startDate) {
                condition = "overlapEndStart";
              }
            }

            if (condition === "overlapStart") {
              centerCheck["startDateError"] = "Date overlap";
            } else if (condition === "overlapEnd") {
              centerCheck["endDateError"] = "Date overlap";
            } else if (condition === "overlapEndStart") {
              centerCheck["endDateError"] =
                "End date should be the future of start date";
            } else if (condition === "") {
              if (eachType === "startDate") {
                centerCheck["startDateError"] = "";
              } else {
                centerCheck["endDateError"] = "";
              }
            }
          }
          break;
        case "volGeneralNo":
          if (this.state.volGeneralNo <= 0) {
            centerCheck["volGeneralNoError"] =
              "General Volunteer cannot be empty";
          } else {
            centerCheck["volGeneralNoError"] = "";
          }
          break;
        case "volHealthVacNo":
          if (this.state.volHealthVacNo < 0) {
            centerCheck["volHealthVacNoError"] =
              "Vaccination Healthcare Volunteer cannot be empty";
          } else {
            centerCheck["volHealthVacNoError"] = "";
          }
          break;
        case "volHealthObsNo":
          if (this.state.volHealthObsNo < 0) {
            centerCheck["volHealthObsNoError"] =
              "Observation Bay Healthcare Volunteer cannot be empty";
          } else {
            centerCheck["volHealthObsNoError"] = "";
          }
          break;
        case "volHealthCounsNo":
          if (this.state.volHealthCounsNo < 0) {
            centerCheck["volHealthCounsNoError"] =
              "Counselling Healthcare Volunteer cannot be empty";
          } else {
            centerCheck["volHealthCounsNoError"] = "";
          }
          break;
        case "imageToken":
          if (!this.state.uploadImageFile) {
            centerCheck["imageTokenError"] = "Please upload a center image";
          } else {
            centerCheck["imageTokenError"] = "";
          }
          break;
        default:
          break;
      }
      return null;
    });
    this.setState({
      centerError: centerCheck,
    });
  };

  handleAvailabilityDeletionModal = (state: boolean, index: number) => {
    this.setState({
      deletionModal: state,
      deletionIndex: index,
    });
  };

  handleCloseDeletionModal() {
    this.setState({
      deletionModal: false,
      deletionIndex: -1,
    });
  }

  handleDeleteAvailability = async () => {
    if (this.state.deletionIndex >= 0) {
      const deletionIndex = this.state.deletionIndex;
      this.handleLoading(true);
      this.handleCloseDeletionModal();
      await sleep(150);
      this.state.center.personnelNeeded.splice(deletionIndex, 1);
      this.handleLoading(false);
      this.handleNotification("success", "Availability Deleted", "");
    }
  };

  handleAvailability = async () => {
    this.handleLoading(true);

    const conditionsList = [
      "volGeneralNo",
      "volHealthVacNo",
      "volHealthObsNo",
      "volHealthCounsNo",
    ];
    if (this.state.modal === ModalState.CREATE) {
      conditionsList.push("startDate");
      conditionsList.push("endDate");
    }
    this.handleCheckCondition(conditionsList);
    await sleep(250);

    let conditionPassed: boolean = false;
    conditionPassed =
      !this.state.centerError.volGeneralNoError &&
      !this.state.centerError.volHealthVacNoError &&
      !this.state.centerError.volHealthObsNoError &&
      !this.state.centerError.volHealthCounsNoError
        ? true
        : false;
    if (this.state.modal === ModalState.CREATE) {
      conditionPassed =
        conditionPassed &&
        !this.state.centerError.startDateError &&
        !this.state.centerError.endDateError;
    }

    if (conditionPassed) {
      if (this.state.modal === ModalState.CREATE) {
        const newlyAddedPersonnelNeeded: Personnel = {
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          volGeneralNo: Number(this.state.volGeneralNo),
          volHealthVacNo: Number(this.state.volHealthVacNo),
          volHealthObsNo: Number(this.state.volHealthObsNo),
          volHealthCounsNo: Number(this.state.volHealthCounsNo),
          shift: Object.keys(BookingSlot)[2] as keyof typeof BookingSlot,
        };
        this.state.center.personnelNeeded.push(newlyAddedPersonnelNeeded);
      } else if (this.state.modal === ModalState.UPDATE) {
        this.state.center.personnelNeeded[
          this.state.selectedPersonnelIndex
        ].volGeneralNo = Number(this.state.volGeneralNo);
        this.state.center.personnelNeeded[
          this.state.selectedPersonnelIndex
        ].volHealthVacNo = Number(this.state.volHealthVacNo);
        this.state.center.personnelNeeded[
          this.state.selectedPersonnelIndex
        ].volHealthObsNo = Number(this.state.volHealthObsNo);
        this.state.center.personnelNeeded[
          this.state.selectedPersonnelIndex
        ].volHealthCounsNo = Number(this.state.volHealthCounsNo);
      }

      this.handleNotification(
        "success",
        `Date Availability Successfully ${
          this.state.modal === ModalState.CREATE ? "Added" : "Updated"
        }`,
        ""
      );
      this.handleModal(ModalState.INVISIBLE, -1);
    }

    this.handleLoading(false);
  };

  handleDeleteCenter = async () => {
    this.handleLoading(true);
    if (this.state.center.id) {
      const centersDelete = await deleteCenter({
        id: this.handleGetParams(),
      });
      if (centersDelete) {
        this.handleNotification(
          "warning",
          "Center Could Not Be Deleted",
          centersDelete
        );
        this.handleLoading(false);
      } else {
        handleNotiNavigation(this.props.history, 3, "/centers");
        return null;
      }
    } else {
      this.handleNotification(
        "warning",
        "Center Could Not Be Deleted",
        "Please refresh your page"
      );
      this.handleLoading(false);
    }
  };

  handleUpdateCenter = async () => {
    this.handleLoading(true);
    this.handleCheckCondition([
      "name",
      "email",
      "contactNo",
      "street",
      "poscode",
      "description",
      "doses",
      "volGeneral",
      "volHealth",
    ]);
    await sleep(250);
    let conditionPassed: boolean = false;
    conditionPassed =
      !this.state.centerError.centerNameError &&
      !this.state.centerError.centerContactNoError &&
      !this.state.centerError.centerEmailError &&
      !this.state.centerError.centerAddressError.streetError &&
      !this.state.centerError.centerAddressError.poscodeError &&
      !this.state.centerError.dosesError &&
      !this.state.centerError.volGeneralError &&
      !this.state.centerError.volHealthError &&
      !this.state.centerError.descriptionError
        ? true
        : false;
    if (conditionPassed) {
      const centersUpdate = await updateCenter(
        {
          id: this.props.match.params.id,
          centerName: this.state.center.centerName,
          centerContactNo: this.state.center.centerContactNo,
          centerAddress: {
            street: this.state.center.centerAddress.street,
            poscode: this.state.center.centerAddress.poscode,
            state: this.state.center.centerAddress.state,
            district: this.state.center.centerAddress.district,
          },
          centerType: this.state.center.centerType,
          centerEmail: this.state.center.centerEmail,
          description: this.state.center.description,
          doses: Number(this.state.center.doses),
          volGeneral: Number(this.state.center.volGeneral),
          volHealth: Number(this.state.center.volHealth),
          personnelNeeded: this.state.center.personnelNeeded,
        },
        this.state.uploadImageFile as UploadImageFile
      );
      if (centersUpdate) {
        this.handleNotification(
          "warning",
          "Center Could Not Be Updated",
          centersUpdate
        );
        this.handleLoading(false);
      } else {
        handleNotiNavigation(this.props.history, 2, "/centers");
      }
    } else {
      this.handleNotification("warning", "Center Could Not Be Updated", "");
      this.handleLoading(false);
    }
  };

  handleCreateCenter = async () => {
    this.handleLoading(true);
    this.handleCheckCondition([
      "name",
      "email",
      "contactNo",
      "street",
      "poscode",
      "description",
      "doses",
      "volGeneral",
      "volHealth",
      "imageToken",
    ]);
    await sleep(250);
    let conditionPassed: boolean = false;
    conditionPassed =
      !this.state.centerError.centerNameError &&
      !this.state.centerError.centerContactNoError &&
      !this.state.centerError.centerEmailError &&
      !this.state.centerError.centerAddressError.streetError &&
      !this.state.centerError.centerAddressError.poscodeError &&
      !this.state.centerError.dosesError &&
      !this.state.centerError.volGeneralError &&
      !this.state.centerError.volHealthError &&
      !this.state.centerError.descriptionError &&
      !this.state.centerError.imageTokenError
        ? true
        : false;
    if (conditionPassed) {
      const centersCreation = await createCenter(
        {
          centerName: this.state.center.centerName,
          centerContactNo: this.state.center.centerContactNo,
          centerAddress: {
            street: this.state.center.centerAddress.street,
            poscode: this.state.center.centerAddress.poscode,
            state: this.state.center.centerAddress.state,
            district: this.state.center.centerAddress.district,
          },
          doses: Number(this.state.center.doses),
          volGeneral: Number(this.state.center.volGeneral),
          volHealth: Number(this.state.center.volHealth),
          centerType: this.state.center.centerType,
          centerEmail: this.state.center.centerEmail,
          description: this.state.center.description,
          personnelNeeded: this.state.center.personnelNeeded,
        },
        this.state.uploadImageFile as UploadImageFile
      );
      if (centersCreation) {
        this.handleNotification(
          "warning",
          "Center Could Not Be Created",
          centersCreation
        );
        this.handleLoading(false);
      } else {
        handleNotiNavigation(this.props.history, 1, "/centers");
      }
    } else {
      this.handleNotification("warning", "Center Could Not Be Created", "");
      this.handleLoading(false);
    }
  };

  handleCloseModal() {
    this.setState({
      modal: false,
    });
  }

  handleCenterConfirmation() {
    confirm({
      title: "Are you sure to delete this center?",
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        this.handleDeleteCenter();
      },
      onCancel: () => {},
    });
  }

  renderCenterPreviewImage = () => {
    let imageUrl = "";

    if (this.state.uploadImageFile && this.state.uploadImageFile.url) {
      imageUrl = this.state.uploadImageFile.url;
    } else if (this.state.center.imageToken) {
      const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
      const storageBucketId = isProd
        ? process.env.REACT_APP_PROD_STORAGE_BUCKET
        : process.env.REACT_APP_DEV_STORAGE_BUCKET;
      imageUrl = `https://firebasestorage.googleapis.com/v0/b/${storageBucketId}/o/centers%2F${this.state.center.id}%2Fimage.jpg?alt=media&token=${this.state.center.imageToken}`;
    }

    if (imageUrl) {
      return (
        <img
          src={imageUrl}
          alt="Place Preview"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            marginBottom: 20,
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
      );
    } else {
      return null;
    }
  };

  renderAvailabilityList = () => {
    const bookingColumns: any = [
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Mobile No",
        dataIndex: "mobileNo",
      },
      {
        title: "Start Date - End Date",
        dataIndex: "date",
      },
    ];

    interface BookingTable {
      name: string;
      email: string;
      mobileNo: string;
      date: string;
    }

    if (this.state.deletionIndex >= 0) {
      const personnelNeeded =
        this.state.center.personnelNeeded[this.state.deletionIndex];
      const bookingList: BookingTable[] = [];
      this.state.bookings.map((eachBookings: UsersBookingModel) => {
        if (
          eachBookings.startDate.setHours(0, 0, 0, 0) >=
            personnelNeeded.startDate.setHours(0, 0, 0, 0) &&
          eachBookings.endDate.setHours(0, 0, 0, 0) <=
            personnelNeeded.endDate.setHours(0, 0, 0, 0)
        ) {
          bookingList.push({
            name: eachBookings.user.name,
            email: eachBookings.user.email,
            mobileNo: eachBookings.user.mobileNo,
            date: `${moment(eachBookings.startDate).format(
              "DD/MM/YYYY"
            )} - ${moment(eachBookings.endDate).format("DD/MM/YYYY")}`,
          });
        }
        return null;
      });
      const listComponent: ReactElement[] = [];
      if (bookingList.length > 0) {
        listComponent.push(
          <Table
            style={{
              width: "100%",
              maxWidth: "100%",
              cursor: "pointer",
              overflowX: "auto",
            }}
            columns={bookingColumns}
            dataSource={bookingList}
            pagination={false}
          />
        );
      } else {
        listComponent.push(<Body>No bookings within these date range</Body>);
      }
      return listComponent;
    } else {
      return "Error occurred";
    }
  };

  renderAvailabilityDeletionModal = () => {
    let modalWidth = "60%";
    if (this.props.utilsStore.deviceType === DeviceType.Mobile) {
      modalWidth = "90%";
    }

    return (
      <Modal
        title={"Are you sure to delete this date availability?"}
        visible={this.state.deletionModal ? true : false}
        onCancel={this.handleCloseDeletionModal.bind(this)}
        onOk={this.handleDeleteAvailability.bind(this)}
        width={modalWidth}
      >
        {this.renderAvailabilityList()}
      </Modal>
    );
  };

  renderAddAvailabilityModal = () => {
    return (
      <Modal
        title={
          this.state.modal === ModalState.CREATE
            ? "Add New Availability Date"
            : "Update Volunteering Slots"
        }
        visible={this.state.modal !== ModalState.INVISIBLE ? true : false}
        onCancel={this.handleModal.bind(this, ModalState.INVISIBLE, -1)}
        onOk={this.handleAvailability.bind(this)}
      >
        {this.state.modal === ModalState.CREATE && (
          <RowContainer style={{ width: "100%", alignItems: "flex-start" }}>
            <VerticalContainer
              style={{ width: "48%", alignItems: "flex-start" }}
            >
              <Body style={{ marginBottom: 5 }}>Start Date</Body>
              <DatePicker
                id="startDate"
                format={dateFormat}
                value={moment(this.state.startDate)}
                style={{
                  width: "100%",
                }}
                onChange={this.handleDateChange.bind(this, "startDate")}
              />
              {this.state.centerError.startDateError && (
                <Warning style={{ marginBottom: 0 }}>
                  {this.state.centerError.startDateError}
                </Warning>
              )}
            </VerticalContainer>
            <div style={{ flex: 1 }} />
            <VerticalContainer
              style={{ width: "48%", alignItems: "flex-start" }}
            >
              <Body style={{ marginBottom: 5 }}>End Date</Body>
              <DatePicker
                id="endDate"
                format={dateFormat}
                value={moment(this.state.endDate)}
                style={{
                  width: "100%",
                }}
                onChange={this.handleDateChange.bind(this, "endDate")}
              />
              {this.state.centerError.endDateError && (
                <Warning style={{ marginBottom: 0 }}>
                  {this.state.centerError.endDateError}
                </Warning>
              )}
            </VerticalContainer>
          </RowContainer>
        )}
        <VerticalContainer style={{ alignItems: "flex-start", marginTop: 20 }}>
          <Body style={{ marginBottom: 5 }}>General Volunteer</Body>
          <TextInput
            id="volGeneralNo"
            type="number"
            value={this.state.volGeneralNo}
            width={"100%"}
            placeholder={"General Volunteer"}
            onChange={this.handleVolChange}
          />
          {this.state.centerError.volGeneralNoError && (
            <Warning style={{ marginBottom: 0 }}>
              {this.state.centerError.volGeneralNoError}
            </Warning>
          )}
        </VerticalContainer>
        <VerticalContainer style={{ alignItems: "flex-start", marginTop: 20 }}>
          <Body style={{ marginBottom: 5 }}>
            Vaccination Healthcare Volunteer
          </Body>
          <TextInput
            id="volHealthVacNo"
            type="number"
            value={this.state.volHealthVacNo}
            width={"100%"}
            placeholder={"Healthcare Volunteer"}
            onChange={this.handleVolChange}
          />
          {this.state.centerError.volHealthVacNoError && (
            <Warning style={{ marginBottom: 0 }}>
              {this.state.centerError.volHealthVacNoError}
            </Warning>
          )}
        </VerticalContainer>
        <VerticalContainer style={{ alignItems: "flex-start", marginTop: 20 }}>
          <Body style={{ marginBottom: 5 }}>
            Observation Healthcare Volunteer
          </Body>
          <TextInput
            id="volHealthObsNo"
            type="number"
            value={this.state.volHealthObsNo}
            width={"100%"}
            placeholder={"Healthcare Volunteer"}
            onChange={this.handleVolChange}
          />
          {this.state.centerError.volHealthObsNoError && (
            <Warning style={{ marginBottom: 0 }}>
              {this.state.centerError.volHealthObsNoError}
            </Warning>
          )}
        </VerticalContainer>
        <VerticalContainer style={{ alignItems: "flex-start", marginTop: 20 }}>
          <Body style={{ marginBottom: 5 }}>
            Counselling Healthcare Volunteer
          </Body>
          <TextInput
            id="volHealthCounsNo"
            type="number"
            value={this.state.volHealthCounsNo}
            width={"100%"}
            placeholder={"Healthcare Volunteer"}
            onChange={this.handleVolChange}
          />
          {this.state.centerError.volHealthCounsNoError && (
            <Warning style={{ marginBottom: 0 }}>
              {this.state.centerError.volHealthCounsNoError}
            </Warning>
          )}
        </VerticalContainer>
      </Modal>
    );
  };

  renderButtons = () => {
    let buttonWidth = 150;
    let marginTop = 0;
    if (this.props.utilsStore.deviceType === DeviceType.Mobile) {
      buttonWidth = 85;
      marginTop = 10;
    }

    if (this.handleGetParams() !== "create" && this.handleGetParams()) {
      return (
        <RowContainer style={{ marginTop: marginTop }}>
          <Button
            text="Analytics"
            width={buttonWidth}
            margin={"0px 10px"}
            onClick={this.handleNavigate.bind(
              this,
              "centerAnalytics",
              this.handleGetParams()
            )}
            small={true}
          />
          <Button
            text="Delete"
            width={buttonWidth}
            margin={"0px 10px"}
            onClick={this.handleCenterConfirmation.bind(this)}
            small={true}
          />
          <Button
            text="Update"
            width={buttonWidth}
            margin={"0px 10px"}
            onClick={this.handleUpdateCenter}
            small={true}
          />
        </RowContainer>
      );
    } else {
      return (
        <RowContainer style={{ marginTop: marginTop }}>
          <Button
            text="Add"
            width={buttonWidth}
            margin={"0px 10px"}
            onClick={this.handleCreateCenter}
            small={true}
          />
        </RowContainer>
      );
    }
  };

  renderPersonnelAvailability = () => {
    const data: any = [];
    if (this.state.center.personnelNeeded.length > 0) {
      this.state.center.personnelNeeded.map(
        (eachPersonnelNeeded: any, index: number) => {
          eachPersonnelNeeded["key"] = index;
          data.push(eachPersonnelNeeded);
          return null;
        }
      );
    }

    return data;
  };

  render() {
    if (this.props.authStore.userLoading) {
      return null;
    }
    return (
      <Spin
        spinning={this.state.loading}
        size="large"
        style={{
          width: "100vw",
          height: "100vh",
          maxHeight: "none",
        }}
      >
        <Header history={this.props.history} location={this.props.location} />
        <WindowResizer />
        <SecurityNavigator location={this.props.location} role={"SUPER"} />
        {this.renderAddAvailabilityModal()}
        {this.renderAvailabilityDeletionModal()}
        <VerticalContainer
          style={{
            width: "100%",
          }}
        >
          <VerticalContainer
            style={{
              alignItems: "flex-start",
              width: "90%",
              height: "100%",
              maxWidth: 1200,
              padding: "40px 0px",
            }}
          >
            <FlexibleRowContainer
              style={{
                width: "100%",
                alignItems: "flex-start",
              }}
            >
              <RowContainer>
                <CustomActionTitle
                  onClick={this.handleNavigate.bind(this, "centers", "")}
                  title="Centers"
                >
                  Centers
                </CustomActionTitle>
                <Title3 style={{ margin: "0px 10px" }}>/</Title3>
                <Title3 style={{ marginBottom: 0 }}>
                  {this.props.match.params.id === "create"
                    ? "New"
                    : this.state.center.centerName}
                </Title3>
              </RowContainer>
              <div style={{ flex: 1 }} />
              {this.renderButtons()}
            </FlexibleRowContainer>
            <Divider style={{ marginTop: 10, marginBottom: 30 }} />
            <Row style={{ width: "100%" }} gutter={8}>
              <Col
                span={
                  this.props.utilsStore.deviceType === DeviceType.Mobile
                    ? 24
                    : 12
                }
              >
                <VerticalContainer
                  style={{
                    width: "95%",
                    alignItems: "flex-start",
                  }}
                >
                  <Body>Image Preview</Body>
                  <VerticalContainer
                    style={{
                      width: "100%",
                      background: COLOR.LIGHTGREY,
                      height: 300,
                      position: "relative",
                    }}
                  >
                    {this.renderCenterPreviewImage()}
                    <VerticalContainer style={{ zIndex: 3 }}>
                      <Button
                        text="Edit Photo"
                        width="auto"
                        onClick={this.handleInputImage}
                      />
                      <input
                        type="file"
                        id="upload-button"
                        accept=".jpg, .jpeg, .png"
                        style={{ display: "none" }}
                        onChange={this.handleImageUpload}
                      />
                      {this.state.centerError.imageTokenError && (
                        <Warning style={{ marginBottom: 0 }}>
                          {this.state.centerError.imageTokenError}
                        </Warning>
                      )}
                    </VerticalContainer>
                  </VerticalContainer>
                </VerticalContainer>
              </Col>
              <Col
                span={
                  this.props.utilsStore.deviceType === DeviceType.Mobile
                    ? 24
                    : 12
                }
              >
                <VerticalContainer
                  style={{ alignItems: "flex-start", marginTop: 20 }}
                >
                  <Body style={{ marginBottom: 5 }}>Name</Body>
                  <TextInput
                    id="centerName"
                    value={this.state.center.centerName}
                    width={"100%"}
                    placeholder={"Center Name"}
                    onChange={this.handleChange}
                    maxLength={50}
                  />
                  {this.state.centerError.centerNameError && (
                    <Warning style={{ marginBottom: 0 }}>
                      {this.state.centerError.centerNameError}
                    </Warning>
                  )}
                </VerticalContainer>
                <VerticalContainer
                  style={{ alignItems: "flex-start", marginTop: 20 }}
                >
                  <Body style={{ marginBottom: 5 }}>Contact No.</Body>
                  <TextInput
                    id="centerContactNo"
                    value={this.state.center.centerContactNo}
                    width={"100%"}
                    placeholder={"+60123456789"}
                    onChange={this.handleChange}
                  />
                  {this.state.centerError.centerContactNoError && (
                    <Warning style={{ marginBottom: 0 }}>
                      {this.state.centerError.centerContactNoError}
                    </Warning>
                  )}
                </VerticalContainer>
                <VerticalContainer
                  style={{ alignItems: "flex-start", marginTop: 20 }}
                >
                  <Body style={{ marginBottom: 5 }}>Email</Body>
                  <TextInput
                    id="centerEmail"
                    value={this.state.center.centerEmail}
                    width={"100%"}
                    placeholder={"Email"}
                    onChange={this.handleChange}
                  />
                  {this.state.centerError.centerEmailError && (
                    <Warning style={{ marginBottom: 0 }}>
                      {this.state.centerError.centerEmailError}
                    </Warning>
                  )}
                </VerticalContainer>
                <VerticalContainer
                  style={{ alignItems: "flex-start", marginTop: 20 }}
                >
                  <Body style={{ marginBottom: 5 }}>Center Type</Body>
                  <Select
                    id="centerType"
                    value={this.state.center.centerType}
                    style={{ width: "100%" }}
                    placeholder={"Type"}
                    onChange={this.handleSelectChange}
                  >
                    {Object.keys(CenterType).map((key) => (
                      <Option key={key} value={key}>
                        {Object(CenterType)[key]}
                      </Option>
                    ))}
                  </Select>
                </VerticalContainer>
                <VerticalContainer
                  style={{ alignItems: "flex-start", marginTop: 20 }}
                >
                  <Body style={{ marginBottom: 5 }}>Doses Per Day</Body>
                  <TextInput
                    id="doses"
                    type="number"
                    value={this.state.center.doses}
                    width={"100%"}
                    placeholder={"Doses Per Day"}
                    onChange={this.handleChange}
                  />
                  {this.state.centerError.dosesError && (
                    <Warning style={{ marginBottom: 0 }}>
                      {this.state.centerError.dosesError}
                    </Warning>
                  )}
                </VerticalContainer>
                <VerticalContainer
                  style={{ alignItems: "flex-start", marginTop: 20 }}
                >
                  <Body style={{ marginBottom: 5 }}>General Volunteer</Body>
                  <TextInput
                    id="volGeneral"
                    type="number"
                    value={this.state.center.volGeneral}
                    width={"100%"}
                    placeholder={"General Volunteer"}
                    onChange={this.handleChange}
                  />
                  {this.state.centerError.volGeneralError && (
                    <Warning style={{ marginBottom: 0 }}>
                      {this.state.centerError.volGeneralError}
                    </Warning>
                  )}
                </VerticalContainer>
                <VerticalContainer
                  style={{ alignItems: "flex-start", marginTop: 20 }}
                >
                  <Body style={{ marginBottom: 5 }}>Healthcare Volunteer</Body>
                  <TextInput
                    id="volHealth"
                    type="number"
                    value={this.state.center.volHealth}
                    width={"100%"}
                    placeholder={"Healthcare Volunteer"}
                    onChange={this.handleChange}
                  />
                  {this.state.centerError.volHealthError && (
                    <Warning style={{ marginBottom: 0 }}>
                      {this.state.centerError.volHealthError}
                    </Warning>
                  )}
                </VerticalContainer>
                <VerticalContainer
                  style={{ alignItems: "flex-start", marginTop: 20 }}
                >
                  <Body style={{ marginBottom: 5 }}>Street</Body>
                  <TextInput
                    id="street"
                    value={this.state.center.centerAddress.street}
                    width={"100%"}
                    placeholder={"Street"}
                    onChange={this.handleAddressChange}
                  />
                  {this.state.centerError.centerAddressError.streetError && (
                    <Warning style={{ marginBottom: 0 }}>
                      {this.state.centerError.centerAddressError.streetError}
                    </Warning>
                  )}
                </VerticalContainer>
                <VerticalContainer
                  style={{ alignItems: "flex-start", marginTop: 20 }}
                >
                  <Body style={{ marginBottom: 5 }}>Postcode</Body>
                  <TextInput
                    id="poscode"
                    type="number"
                    value={this.state.center.centerAddress.poscode}
                    width={"100%"}
                    placeholder={"Postcode"}
                    onChange={this.handleAddressChange}
                  />
                  {this.state.centerError.centerAddressError.poscodeError && (
                    <Warning style={{ marginBottom: 0 }}>
                      {this.state.centerError.centerAddressError.poscodeError}
                    </Warning>
                  )}
                </VerticalContainer>
                <RowContainer style={{ width: "100%" }}>
                  <VerticalContainer
                    style={{
                      width: "48%",
                      alignItems: "flex-start",
                      marginTop: 20,
                    }}
                  >
                    <Body style={{ marginBottom: 5 }}>State</Body>
                    <Select
                      style={{ width: "100%" }}
                      value={this.state.center.centerAddress.state}
                      onChange={this.handleAddressChangeSelect.bind(
                        this,
                        "state"
                      )}
                    >
                      {getStateList().map(
                        (eachState: string, index: number) => (
                          <Option key={index} value={eachState}>
                            {eachState}
                          </Option>
                        )
                      )}
                    </Select>
                  </VerticalContainer>
                  <div style={{ flexGrow: 1 }} />
                  <VerticalContainer
                    style={{
                      width: "48%",
                      alignItems: "flex-start",
                      marginTop: 20,
                    }}
                  >
                    <Body style={{ marginBottom: 5 }}>District</Body>
                    <Select
                      style={{ width: "100%" }}
                      value={this.state.center.centerAddress.district}
                      onChange={this.handleAddressChangeSelect.bind(
                        this,
                        "district"
                      )}
                    >
                      {getDistrictList(
                        this.state.center.centerAddress.state
                      ).map((eachDistrict: string, index: number) => (
                        <Option key={index} value={eachDistrict}>
                          {eachDistrict}
                        </Option>
                      ))}
                    </Select>
                  </VerticalContainer>
                </RowContainer>
                <VerticalContainer
                  style={{ alignItems: "flex-start", marginTop: 20 }}
                >
                  <Body style={{ marginBottom: 5 }}>Description</Body>
                  <TextArea
                    id="description"
                    value={this.state.center.description}
                    onChange={this.handleChange}
                    width={"100%"}
                    placeholder="How to get there and description"
                    autoSize={{ minRows: 3, maxRows: 10 }}
                  />
                  {this.state.centerError.descriptionError && (
                    <Warning style={{ marginBottom: 0 }}>
                      {this.state.centerError.descriptionError}
                    </Warning>
                  )}
                </VerticalContainer>
              </Col>
            </Row>
            <RowContainer style={{ marginTop: 30 }}>
              <Title3 style={{ marginBottom: 0 }}>Date Availability</Title3>
              <PlusCircleOutlined
                style={{
                  marginLeft: 5,
                  cursor: "pointer",
                  color: COLOR.RED,
                  fontSize: 20,
                }}
                onClick={this.handleModal.bind(this, ModalState.CREATE, -1)}
              />
            </RowContainer>
            <Divider style={{ marginTop: 10, marginBottom: 30 }} />
            <Table
              style={{
                width: "100%",
                maxWidth: "100%",
                overflowX: "auto",
              }}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "30"],
              }}
              columns={this.columns}
              dataSource={this.renderPersonnelAvailability()}
            />
          </VerticalContainer>
        </VerticalContainer>
      </Spin>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(CenterEditor);
