import ResourceModel from "../../models/Resources";
import firebase from "../../utils/firebase";

let resourcesSocket: any = null;

export const getAllResources = () => {
  return async (dispatch: any, getState: any) => {
    try {
      if (!resourcesSocket) {
        let resourcesList: ResourceModel[] = [];

        resourcesSocket = firebase
          .firestore()
          .collection("resource")
          .onSnapshot((querySnapshot) => {
            resourcesList = [];
            querySnapshot.forEach((doc) => {
              resourcesList.push(doc.data() as ResourceModel);
            });

            dispatch({
              type: "UPDATE_RESOURCES",
              payload: {
                resources: resourcesList,
              },
            });
          });
      }
    } catch (err) {}
  };
};

export interface ResourceRes {
  err: string;
  data: ResourceModel | null;
}

export const getResource = async (id: string) => {
  let resourceRes: ResourceRes = {
    err: "",
    data: null,
  };

  try {
    const resourceQuery = await firebase
      .firestore()
      .collection("resource")
      .doc(id)
      .get();
    if (resourceQuery.exists) {
      const resourceData: ResourceModel = resourceQuery.data() as ResourceModel;
      resourceRes.data = resourceData;
    } else {
      resourceRes.err = "Cannot be found";
    }
  } catch (err) {
    resourceRes.err = err.message;
  }

  return resourceRes;
};

export const createResources = async (items: {
  title: string;
  description: string;
  link: string;
}) => {
  try {
    const resourcesRef = firebase.firestore().collection("resource").doc();
    if (resourcesRef.id) {
      const resourcesModel: ResourceModel = {
        id: resourcesRef.id,
        title: items.title,
        description: items.description,
        link: items.link,
        createdAt: new Date(),
      };
      await firebase
        .firestore()
        .collection("resource")
        .doc(resourcesRef.id)
        .set(resourcesModel);
      return "";
    } else return "Resources is not created. Unknown Error.";
  } catch (err) {
    return err.message;
  }
};

export const updateResource = async (items: {
  id: string;
  title: string;
  description: string;
  link: string;
}) => {
  try {
    const resourcesRef = items.id;
    if (resourcesRef) {
      await firebase
        .firestore()
        .collection("resource")
        .doc(resourcesRef)
        .update({
          title: items.title,
          description: items.description,
          link: items.link,
        });
      return "";
    } else return "Resources is not updated. Unknown Error.";
  } catch (err) {
    return err.message;
  }
};

export const deleteResource = async (items: { id: string }) => {
  try {
    const resourcesRef = items.id;
    if (resourcesRef) {
      await firebase
        .firestore()
        .collection("resource")
        .doc(resourcesRef)
        .delete();
      return "";
    } else return "Resources is not deleted. Unknown Error.";
  } catch (err) {
    return err.message;
  }
};

export const clearResources = () => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: "UPDATE_RESOURCES",
        payload: {
          resources: [],
        },
      });
    } catch (err) {}
  };
};

export const clearResourcesSocket = () => {
  if (resourcesSocket) {
    resourcesSocket();
  }
};
