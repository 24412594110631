import { Address } from "../models/User";

export enum BookingSlot {
  MOR = "8AM - 3PM",
  EVE = "3PM - 8PM",
  WHOLE = "8PM - 8PM",
}

export enum CenterType {
  ONE = "Type 1",
  TWO = "Type 2",
  THREE = "Type 3",
  FOUR = "Type 4",
  FIVE = "Type 5",
  SIX = "Type 6",
  SEVEN = "Type 7",
  EIGHT = "Type 8",
}

export interface Personnel {
  startDate: Date;
  endDate: Date;
  volGeneralNo: number;
  volHealthVacNo: number;
  volHealthObsNo: number;
  volHealthCounsNo: number;
  shift: keyof typeof BookingSlot;
}

export default interface Centers {
  id: string;
  centerName: string;
  centerAddress: Address;
  centerEmail: string;
  centerContactNo: string;
  imageToken: string;
  centerType: keyof typeof CenterType;
  doses: number;
  volHealth: number;
  volGeneral: number;
  description: string;
  personnelNeeded: Personnel[];
}
