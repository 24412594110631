import firebase from "../../utils/firebase";

//INFO : Auth Listener
let unsubscribeAuthListener: any = null;
let unsubscribeUserListener: any = null;

export const getAuthListener = () => {
  return async (dispatch: any, getState: any) => {
    if (!unsubscribeAuthListener)
      unsubscribeAuthListener = firebase
        .auth()
        .onAuthStateChanged(function (user) {
          if (user) {
            dispatch({
              type: "UPDATE_USER_AUTH",
              payload: {
                userAuth: user,
              },
            });
            getUserInfoListener(dispatch);
          } else {
            dispatch({
              type: "UPDATE_USER_AUTH",
              payload: {
                userAuth: null,
              },
            });
            removeUserInfoListener();
          }
        });
  };
};

export const removeAuthListener = () => {
  return async (dispatch: any, getState: any) => {
    if (unsubscribeAuthListener) {
      unsubscribeAuthListener();
      unsubscribeAuthListener = null;
    }
    removeUserInfoListener();
  };
};

export const signIn = async (credentials: {
  email: string;
  password: string;
}) => {
  await firebase
    .auth()
    .signInWithEmailAndPassword(credentials.email, credentials.password);
};

export const signOut = async () => {
  try {
    await firebase.auth().signOut();
  } catch (err) {}
};

export const resetPassword = async (email: string) => {
  await firebase.auth().sendPasswordResetEmail(email);
};

const getUserInfoListener = async (dispatch: any) => {
  try {
    if (firebase.auth().currentUser?.uid) {
      try {
        const adminQuery = await firebase
          .firestore()
          .collection("admin")
          .doc(firebase.auth().currentUser?.uid)
          .get();
        if (!adminQuery.exists) {
          signOut();
        }
      } catch (err) {
        signOut();
      }

      unsubscribeUserListener = firebase
        .firestore()
        .collection("admin")
        .doc(firebase.auth().currentUser?.uid)
        .onSnapshot(async (doc) => {
          if (doc) {
            dispatch({
              type: "UPDATE_USER",
              payload: {
                user: doc.data(),
              },
            });
          }
        });
    }
  } catch (err) {}
};

const removeUserInfoListener = () => {
  if (unsubscribeUserListener) {
    unsubscribeUserListener();
    unsubscribeUserListener = null;
  }
};
