export const handleNavigation = (history: any, path: string) => {
  history.push(path);
  window.scrollTo(0, 0);
};

export const handleNotiNavigation = (
  history: any,
  notification: number,
  path: string
) => {
  history.push(path);
  history.push({ notification: notification });
  window.scrollTo(0, 0);
};
