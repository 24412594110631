import HealthVerificationLinkModel from "../../models/HealthVerificationLink";
import firebase from "../../utils/firebase";

export interface HealthVerificationLinkRes {
  err: string;
  data: HealthVerificationLinkModel | null;
}

export const healthVerificationLinkQueryLimit = 10;

export const getHealthVerificationLinkWithPagination = (
  paginationQuery: string,
  paginationType: string,
  paginationSearch: string
) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: "SET_HEALTH_VERIFICATION_LINK_LOADING",
      });

      const healthVerificationLinkList: HealthVerificationLinkModel[] = [];
      const healthLinkCollectionQ = firebase
        .firestore()
        .collection("healthVerificationLinks");

      let healthVerificationLinkQuery;
      if (!paginationSearch) {
        const healthLinkCollection = healthLinkCollectionQ.limit(
          healthVerificationLinkQueryLimit
        );
        if (paginationQuery) {
          if (paginationType === "asc") {
            healthVerificationLinkQuery = healthLinkCollection
              .orderBy("title")
              .startAfter(paginationQuery);
          } else {
            healthVerificationLinkQuery = healthLinkCollection
              .orderBy("title", "desc")
              .startAfter(paginationQuery);
          }
        } else {
          healthVerificationLinkQuery = healthLinkCollection.orderBy("title");
        }
      } else {
        healthVerificationLinkQuery = healthLinkCollectionQ
          .orderBy("title")
          .startAt(paginationSearch)
          .endAt(paginationSearch + "\uf8ff");
      }

      const healthVerificationLinkSnapshot = await healthVerificationLinkQuery.get();

      if (healthVerificationLinkSnapshot) {
        healthVerificationLinkSnapshot.forEach((eachDoc) => {
          healthVerificationLinkList.push(
            eachDoc.data() as HealthVerificationLinkModel
          );
        });
      }

      if (paginationType === "desc" && healthVerificationLinkList.length > 0) {
        healthVerificationLinkList.reverse();
      }

      let pagination = "";
      if (
        healthVerificationLinkList.length ===
          healthVerificationLinkQueryLimit &&
        !paginationSearch
      ) {
        pagination =
          healthVerificationLinkList[healthVerificationLinkQueryLimit - 1]
            .title;
      }

      let healthVerificationLink = [];
      if (healthVerificationLinkList.length > 0) {
        healthVerificationLink = healthVerificationLinkList;
      } else if (!paginationSearch) {
        healthVerificationLink = getState().healthVerificationLinkStore
          .healthVerificationLink;
      }

      dispatch({
        type: "UPDATE_HEALTH_VERIFICATION_LINK",
        payload: {
          healthVerificationLink: healthVerificationLink,
          pagination: pagination,
        },
      });
    } catch (err) {}
  };
};

export const getHealthVerificationLink = async (id: string) => {
  let healthVerificationLinkRes: HealthVerificationLinkRes = {
    err: "",
    data: null,
  };

  try {
    const healthVerificationLinkQuery = await firebase
      .firestore()
      .collection("healthVerificationLinks")
      .doc(id)
      .get();
    if (healthVerificationLinkQuery.exists) {
      const healthVerificationLinkData: HealthVerificationLinkModel = healthVerificationLinkQuery.data() as HealthVerificationLinkModel;
      healthVerificationLinkRes.data = healthVerificationLinkData;
    } else {
      healthVerificationLinkRes.err = "Cannot be found";
    }
  } catch (err) {
    healthVerificationLinkRes.err = err.message;
  }

  return healthVerificationLinkRes;
};

export const createHealthVerificationLink = async (items: {
  applyDate: Date;
  title: string;
}) => {
  try {
    const healthVerificationLinkRef = firebase
      .firestore()
      .collection("healthVerificationLinks")
      .doc();
    if (healthVerificationLinkRef.id) {
      const healthVerificationLinkModel: HealthVerificationLinkModel = {
        id: healthVerificationLinkRef.id,
        title: items.title,
        applyDate: items.applyDate,
      };
      await firebase
        .firestore()
        .collection("healthVerificationLinks")
        .doc(healthVerificationLinkRef.id)
        .set(healthVerificationLinkModel);
      return "";
    } else return "Health Verification Link is not created. Unknown Error.";
  } catch (err) {
    return err.message;
  }
};

export const updateHealthVerificationLink = async (items: {
  id: string;
  title: string;
  applyDate: Date;
}) => {
  try {
    const healthVerificationLinkRef = items.id;
    if (healthVerificationLinkRef) {
      await firebase
        .firestore()
        .collection("healthVerificationLinks")
        .doc(healthVerificationLinkRef)
        .update({
          title: items.title,
          applyDate: items.applyDate,
        });
      return "";
    } else return "Health Verification Link is not updated. Unknown Error.";
  } catch (err) {
    return err.message;
  }
};

export const deleteHealthVerificationLink = async (items: { id: string }) => {
  try {
    const healthVerificationLinkRef = items.id;
    if (healthVerificationLinkRef) {
      await firebase
        .firestore()
        .collection("healthVerificationLinks")
        .doc(healthVerificationLinkRef)
        .delete();
      return "";
    } else return "Health Verification Link is not deleted. Unknown Error.";
  } catch (err) {
    return err.message;
  }
};
