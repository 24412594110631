import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Divider, notification, Table } from "antd";
import moment from "moment";
import { Component } from "react";
import { connect } from "react-redux";
import Button from "../components/Button";
import Header from "../components/Header";
import TextInput from "../components/TextInput";
import SecurityNavigator from "../navigator/SecurityNavigator";
import WindowResizer from "../navigator/WindowResizer";
import { getHealthVerificationLinkWithPagination } from "../store/actions/healthVerificationLinkActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { healthVerificationLinkStateInterface } from "../store/reducers/healthVerificationLinkReducer";
import {
  DeviceType,
  utilsStateInterface,
} from "../store/reducers/utilsReducer";
import { COLOR } from "../styles/Colors";
import {
  FlexibleRowContainer,
  RowContainer,
  VerticalContainer,
} from "../styles/Layout";
import { Body, Title3 } from "../styles/Typography";
import { handleNavigation } from "../utils/navigator";

interface Props {
  history: any;
  location: any;
  authStore: authStateInterface;
  utilsStore: utilsStateInterface;
  healthVerificationLinkStore: healthVerificationLinkStateInterface;
  getHealthVerificationLinkWithPagination: (
    pagination: string,
    type: string,
    search: string
  ) => void;
}

interface State {
  searchText: string;
  pageIndex: number;
}

const buttonDisableStyle = (value: boolean) => ({
  cursor: value ? "auto" : "pointer",
  color: value ? COLOR.GREY : COLOR.RED,
});

class HealthVerificationLink extends Component<Props> {
  searchTimeout: ReturnType<typeof setTimeout> | null = null;

  state: State = {
    searchText: "",
    pageIndex: 1,
  };

  columns: any = [
    {
      title: "Title",
      dataIndex: "title",
      sorter: (a: any, b: any) => a.title.localeCompare(b.title),
    },
    {
      title: "Apply Date",
      dataIndex: "applyDate",
      sorter: (a: any, b: any) => a.applyDate.localeCompare(b.applyDate),
    },
    {
      title: "Link",
      dataIndex: "link",
      sorter: (a: any, b: any) => a.title.localeCompare(b.title),
    },
  ];

  componentDidMount() {
    if (this.props.authStore.user) {
      this.handleGetPreviewData();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.authStore.user !== prevProps.authStore.user) {
      this.handleGetPreviewData();
    }
  }

  handleGetPreviewData = async () => {
    this.props.getHealthVerificationLinkWithPagination(
      "",
      "",
      this.state.searchText
    );
  };

  handleLoading = (value: boolean) => {
    this.setState({
      loading: value,
    });
  };

  handlePagination = (value: string) => {
    const pageIndex = this.state.pageIndex;
    if (value === "prev" && pageIndex > 1) {
      this.props.getHealthVerificationLinkWithPagination(
        this.props.healthVerificationLinkStore.healthVerificationLink[0].title,
        "desc",
        this.state.searchText
      );
      this.setState({
        pageIndex: pageIndex - 1,
      });
    } else if (value === "next") {
      this.props.getHealthVerificationLinkWithPagination(
        this.props.healthVerificationLinkStore.pagination,
        "asc",
        this.state.searchText
      );
      this.setState({
        pageIndex: pageIndex + 1,
      });
    }
  };

  handleNavigate = (type: string, id: string) => {
    if (type === "healthVerificationLink") {
      handleNavigation(this.props.history, "/editHealthVerificationLink/" + id);
    }
  };

  handleOnClickRow = (e: any) => {
    return {
      onClick: () => {
        handleNavigation(
          this.props.history,
          this.props.authStore.user?.role === "SUPER"
            ? "/editHealthVerificationLink/" + e.key
            : "healthVerificationLink"
        );
      },
    };
  };

  handleFilter = (e: any) => {
    this.setState(
      {
        searchText: e.target.value,
      },
      () => {
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(() => {
          if (this.state.searchText.length === 0) {
            this.handleGetPreviewData();
            this.setState({ pageIndex: 1 });
          } else if (this.state.searchText.length <= 2) {
            notification.warning({
              message: "Please input at least 3 characters",
            });
          } else {
            this.handleGetPreviewData();
            this.setState({ pageIndex: 1 });
          }
        }, 500);
      }
    );
  };

  renderFilteredData = () => {
    let listView: any = [];
    if (
      this.props.healthVerificationLinkStore.healthVerificationLink.length > 0
    ) {
      this.props.healthVerificationLinkStore.healthVerificationLink.map(
        (eachHealthVerificationLink) =>
          listView.push({
            key: eachHealthVerificationLink.id,
            title: eachHealthVerificationLink.title,
            applyDate: moment(
              eachHealthVerificationLink.applyDate.seconds * 1000
            ).format("DD/MM/YYYY"),
            link: `https://myvac.com.my/attendance/${eachHealthVerificationLink.id}`,
          })
      );
    }
    return listView;
  };

  render() {
    if (this.props.authStore.userLoading) {
      return null;
    }

    return (
      <>
        <Header history={this.props.history} location={this.props.location} />
        <WindowResizer />
        <SecurityNavigator location={this.props.location} />
        <VerticalContainer
          style={{
            width: "100%",
          }}
        >
          <VerticalContainer
            style={{
              alignItems: "flex-start",
              width: "90%",
              height: "100%",
              maxWidth: 1200,
              padding: "40px 0px",
            }}
          >
            <FlexibleRowContainer
              style={{
                width: "100%",
                alignItems: "flex-start",
              }}
            >
              <Title3 style={{ marginBottom: 0 }}>
                Health Verification Link
              </Title3>
              <div style={{ flex: 1, marginTop: 5 }} />
              <TextInput
                placeholder="Filter : Title"
                value={this.state.searchText}
                width={
                  this.props.utilsStore.deviceType === DeviceType.Mobile
                    ? 280
                    : 400
                }
                onChange={this.handleFilter}
              />
              {this.props.authStore.user?.role === "SUPER" && (
                <>
                  <div style={{ flex: 1, marginTop: 10 }} />
                  <Button
                    text="Add"
                    width={150}
                    onClick={this.handleNavigate.bind(
                      this,
                      "healthVerificationLink",
                      "create"
                    )}
                    small={true}
                  />
                </>
              )}
            </FlexibleRowContainer>
            <Divider style={{ marginTop: 10, marginBottom: 30 }} />
            <Table
              style={{
                width: "100%",
                maxWidth: "100%",
                cursor: "pointer",
                overflowX: "auto",
              }}
              columns={this.columns}
              dataSource={this.renderFilteredData()}
              onRow={this.handleOnClickRow}
              loading={this.props.healthVerificationLinkStore.loading}
              pagination={false}
            />
            <RowContainer
              style={{
                width: "100%",
                justifyContent: "flex-end",
                marginTop: 30,
              }}
            >
              {!this.props.healthVerificationLinkStore.loading && (
                <>
                  <LeftOutlined
                    style={buttonDisableStyle(
                      this.state.pageIndex === 1 ? true : false
                    )}
                    onClick={this.handlePagination.bind(this, "prev")}
                  />
                  <Body
                    style={{ marginBottom: 0, marginLeft: 15, marginRight: 15 }}
                  >
                    {`${this.state.pageIndex} / -`}
                  </Body>
                  <RightOutlined
                    onClick={
                      this.props.healthVerificationLinkStore.pagination
                        ? this.handlePagination.bind(this, "next")
                        : () => {}
                    }
                    style={buttonDisableStyle(
                      this.props.healthVerificationLinkStore.pagination
                        ? false
                        : true
                    )}
                  />
                </>
              )}
            </RowContainer>
          </VerticalContainer>
        </VerticalContainer>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
    healthVerificationLinkStore: state.healthVerificationLinkStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getHealthVerificationLinkWithPagination: (
      pagination: string,
      type: string,
      search: string
    ) =>
      dispatch(
        getHealthVerificationLinkWithPagination(pagination, type, search)
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthVerificationLink);
