import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Divider, Modal, notification, Spin } from "antd";
import { Component } from "react";
import { connect } from "react-redux";
import Button from "../components/Button";
import Header from "../components/Header";
import TextInput from "../components/TextInput";
import NgoModel from "../models/Ngo";
import SecurityNavigator from "../navigator/SecurityNavigator";
import WindowResizer from "../navigator/WindowResizer";
import {
  createNgo,
  deleteNgo,
  getNgo,
  NgoRes,
  updateNgo,
} from "../store/actions/ngoActions";
import { sleep } from "../store/actions/utilsActions";
import { authStateInterface } from "../store/reducers/authReducer";
import {
  DeviceType,
  utilsStateInterface,
} from "../store/reducers/utilsReducer";
import {
  FlexibleRowContainer,
  RowContainer,
  VerticalContainer,
} from "../styles/Layout";
import {
  CustomActionTitle,
  Footer as Warning,
  Title3,
} from "../styles/Typography";
import { handleNavigation, handleNotiNavigation } from "../utils/navigator";

interface Props {
  history: any;
  match: any;
  location: any;
  authStore: authStateInterface;
  utilsStore: utilsStateInterface;
}

interface ngoStateError {
  shorthandError: string;
  nameError: string;
}

interface State {
  loading: boolean;
  ngo: NgoModel;
  ngoError: ngoStateError;
}

const { confirm } = Modal;

class NgoEditor extends Component<Props> {
  state: State = {
    loading: true,
    ngo: {
      shorthand: "",
      name: "",
      id: "",
    },
    ngoError: {
      shorthandError: "",
      nameError: "",
    },
  };

  componentDidMount = () => {
    if (this.getParams()) {
      if (this.props.authStore.user) {
        this.handleGetPreviewData();
      }
    } else {
      this.handleNavigate("ngo");
    }
  };

  componentDidUpdate = async (prevProps: Props) => {
    if (
      JSON.stringify(prevProps.authStore.user) !==
        JSON.stringify(this.props.authStore.user) &&
      this.props.authStore.user
    ) {
      this.handleGetPreviewData();
    }
  };

  getParams() {
    const id = this.props.match.params.id;
    return id;
  }

  handleLoading = (value: boolean) => {
    this.setState({
      loading: value,
    });
  };

  handleNavigate = (type: string) => {
    if (type === "ngo") {
      handleNavigation(this.props.history, "/ngo");
    }
  };

  handleNotification = (type: string, message: string, description: string) => {
    if (type === "success") {
      notification.success({ message: message, description: "" });
    } else if (type === "warning") {
      notification.warning({
        message: message,
        description: description,
      });
    }
  };

  handleGetPreviewData = async () => {
    if (this.getParams() !== "create") {
      this.handleLoading(true);
      const ngoQuery: NgoRes = await getNgo(this.getParams());
      if (ngoQuery.err) {
        this.handleNavigate("ngo");
      } else {
        this.setState({
          ngo: ngoQuery.data,
        });
        this.handleLoading(false);
      }
    } else {
      this.setState({ loading: false });
    }
  };

  handleChange = (e: any) => {
    let ngo = JSON.parse(JSON.stringify(this.state.ngo));

    ngo[e.target.id] = e.target.value;
    this.setState({
      ngo,
    });
  };

  handleCheckCondition = (typeList: string[]) => {
    let ngoCheck = JSON.parse(JSON.stringify(this.state.ngoError));
    typeList.map((eachType) => {
      switch (eachType) {
        case "shorthand":
          if (this.state.ngo.shorthand.replace(/\s/g, "").length <= 0) {
            ngoCheck["shorthandError"] = "Shorthand cannot be empty";
          } else {
            ngoCheck["shorthandError"] = "";
          }
          break;
        case "name":
          if (this.state.ngo.name.replace(/\s/g, "").length <= 0) {
            ngoCheck["nameError"] = "Name cannot be empty";
          } else {
            ngoCheck["nameError"] = "";
          }
          break;
        default:
          break;
      }
      return null;
    });
    this.setState({
      ngoError: ngoCheck,
    });
  };

  handleDeleteNgo = async () => {
    this.handleLoading(true);
    if (this.state.ngo.id) {
      const ngodelete = await deleteNgo({
        id: this.getParams(),
      });
      if (ngodelete) {
        this.handleNotification(
          "warning",
          "Ngo Could Not Be Deleted",
          ngodelete
        );
        this.handleLoading(false);
      } else {
        handleNotiNavigation(this.props.history, 3, "/ngo");
      }
    }
  };

  handleEditNgo = async () => {
    this.handleLoading(true);
    this.handleCheckCondition(["shorthand", "name"]);
    await sleep(250);
    let conditionPassed: boolean = false;
    conditionPassed =
      !this.state.ngoError.shorthandError && !this.state.ngoError.nameError
        ? true
        : false;
    if (conditionPassed) {
      const ngoUpdate = await updateNgo({
        id: this.state.ngo.id,
        shorthand: this.state.ngo.shorthand,
        name: this.state.ngo.name,
      });
      if (ngoUpdate) {
        this.handleNotification(
          "warning",
          "Ngo Could Not Be Updated",
          ngoUpdate
        );
        this.handleLoading(false);
      } else {
        handleNotiNavigation(this.props.history, 2, "/ngo");
      }
    } else {
      this.handleNotification("warning", "Ngo Could Not Be Updated", "");
      this.handleLoading(false);
    }
  };

  handleCreateNgo = async () => {
    this.handleLoading(true);
    this.handleCheckCondition(["shorthand", "name"]);
    await sleep(250);
    let conditionPassed: boolean = false;
    conditionPassed =
      !this.state.ngoError.shorthandError && !this.state.ngoError.nameError
        ? true
        : false;
    if (conditionPassed) {
      const ngoCreation = await createNgo({
        shorthand: this.state.ngo.shorthand,
        name: this.state.ngo.name,
      });
      if (ngoCreation) {
        this.handleNotification(
          "warning",
          "Ngo Could Not Be Added",
          ngoCreation
        );
        this.handleLoading(false);
      } else {
        handleNotiNavigation(this.props.history, 1, "/ngo");
      }
    } else {
      this.handleNotification("warning", "Ngo Could Not Be Added", "");
      this.handleLoading(false);
    }
  };

  handleDeleteConfirmation() {
    confirm({
      title: "Are you sure want to delete this NGO?",
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        this.handleDeleteNgo();
      },
      onCancel() {},
    });
  }

  renderButtons = () => {
    let buttonWidth = 150;
    let marginTop = 0;
    if (this.props.utilsStore.deviceType === DeviceType.Mobile) {
      buttonWidth = 85;
      marginTop = 10;
    }

    if (this.getParams() !== "create" && this.getParams()) {
      return (
        <RowContainer style={{ marginTop: marginTop }}>
          <Button
            text="Delete"
            width={buttonWidth}
            margin={"0px 10px"}
            onClick={this.handleDeleteConfirmation.bind(this)}
            small={true}
          />
          <Button
            text="Update"
            width={buttonWidth}
            margin={"0px 10px"}
            onClick={this.handleEditNgo}
            small={true}
          />
        </RowContainer>
      );
    } else {
      return (
        <>
          <div style={{ marginTop: 5 }} />
          <Button
            text="Add"
            width={buttonWidth}
            margin={"0px 10px"}
            onClick={this.handleCreateNgo}
            small={true}
          />
        </>
      );
    }
  };

  render() {
    if (this.props.authStore.userLoading) {
      return null;
    }

    return (
      <Spin
        spinning={this.state.loading}
        size="large"
        style={{
          width: "100vw",
          height: "100vh",
          maxHeight: "none",
        }}
      >
        <Header history={this.props.history} location={this.props.location} />
        <WindowResizer />
        <SecurityNavigator location={this.props.location} role="SUPER" />
        <VerticalContainer
          style={{
            width: "100%",
          }}
        >
          <VerticalContainer
            style={{
              alignItems: "flex-start",
              width: "90%",
              height: "100%",
              maxWidth: 1200,
              padding: "40px 0px",
            }}
          >
            <FlexibleRowContainer
              style={{
                width: "100%",
                alignItems: "flex-start",
              }}
            >
              <RowContainer>
                <CustomActionTitle
                  onClick={this.handleNavigate.bind(this, "ngo")}
                  title="NGO"
                >
                  NGO
                </CustomActionTitle>
                <Title3 style={{ margin: "0px 10px" }}>/</Title3>
                <Title3 style={{ marginBottom: 0 }}>
                  {this.props.match.params.id === "create"
                    ? "New"
                    : this.state.ngo.shorthand}
                </Title3>
              </RowContainer>
              <div style={{ flex: 1 }} />
              {this.renderButtons()}
            </FlexibleRowContainer>
            <Divider style={{ marginTop: 10, marginBottom: 30 }} />
            <RowContainer style={{ width: "100%", alignItems: "flex-start" }}>
              <VerticalContainer
                style={{ width: "38%", alignItems: "flex-start" }}
              >
                <Title3 style={{ marginBottom: 5 }}>Shorthand</Title3>
                <TextInput
                  id="shorthand"
                  value={this.state.ngo.shorthand}
                  width={"100%"}
                  placeholder={"Shorthand"}
                  onChange={this.handleChange}
                  maxLength={60}
                />
                {this.state.ngoError.shorthandError && (
                  <Warning style={{ marginBottom: 0 }}>
                    {this.state.ngoError.shorthandError}
                  </Warning>
                )}
              </VerticalContainer>
              <div style={{ flexGrow: 1 }} />
              <VerticalContainer
                style={{ width: "58%", alignItems: "flex-start" }}
              >
                <Title3 style={{ marginBottom: 5 }}>Name</Title3>
                <TextInput
                  id="name"
                  value={this.state.ngo.name}
                  width={"100%"}
                  placeholder={"Name"}
                  onChange={this.handleChange}
                />
                {this.state.ngoError.nameError && (
                  <Warning style={{ marginBottom: 0 }}>
                    {this.state.ngoError.nameError}
                  </Warning>
                )}
              </VerticalContainer>
            </RowContainer>
          </VerticalContainer>
        </VerticalContainer>
      </Spin>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(NgoEditor);
