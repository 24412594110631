import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Divider, notification, Select, Table } from "antd";
import { Component } from "react";
import { connect } from "react-redux";
import Button from "../components/Button";
import Header from "../components/Header";
import TextInput from "../components/TextInput";
import SecurityNavigator from "../navigator/SecurityNavigator";
import WindowResizer from "../navigator/WindowResizer";
import { getUsersWithPagination } from "../store/actions/userActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { userStateInterface } from "../store/reducers/userReducer";
import {
  DeviceType,
  utilsStateInterface,
} from "../store/reducers/utilsReducer";
import { COLOR } from "../styles/Colors";
import {
  FlexibleRowContainer,
  RowContainer,
  VerticalContainer,
} from "../styles/Layout";
import { Body, Title3 } from "../styles/Typography";
import { getDistrictList } from "../utils/districtList";

interface Props {
  history: any;
  location: any;
  authStore: authStateInterface;
  userStore: userStateInterface;
  utilsStore: utilsStateInterface;
  getUsersWithPagination: (
    pagination: string,
    type: string,
    search: string,
    districtFilter: string
  ) => void;
}

interface State {
  pageIndex: number;
  searchText: string;
  districtFilter: string;
}

const buttonDisableStyle = (value: boolean) => ({
  cursor: value ? "auto" : "pointer",
  color: value ? COLOR.GREY : COLOR.RED,
});

const { Option } = Select;

class Volunteers extends Component<Props> {
  searchTimeout: ReturnType<typeof setTimeout> | null = null;

  state: State = {
    pageIndex: 1,
    searchText: "",
    districtFilter: "",
  };

  columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: "Age",
      dataIndex: "age",
      sorter: (a: any, b: any) => a.age - b.age,
    },
    {
      title: "Profession",
      dataIndex: "profession",
      sorter: (a: any, b: any) => a.profession.localeCompare(b.profession),
    },
    {
      title: "District",
      dataIndex: "district",
      sorter: (a: any, b: any) => a.district.localeCompare(b.district),
    },
    {
      title: "State",
      dataIndex: "state",
      sorter: (a: any, b: any) => a.state.localeCompare(b.state),
    },
    {
      title: "Action",
      dataIndex: "view",
      render: (view: any) => (
        <Button
          text="View"
          small={true}
          width={100}
          onClick={() => {
            if (view) {
              window.open("/editVolunteer/" + view);
            }
          }}
        />
      ),
    },
  ];

  componentDidMount() {
    if (this.props.authStore.user) {
      this.handleGetPreviewData();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.authStore.user !== prevProps.authStore.user) {
      this.handleGetPreviewData();
    }
  }

  handleGetPreviewData = async () => {
    this.props.getUsersWithPagination(
      "",
      "",
      this.state.searchText,
      this.state.districtFilter
    );
  };

  handleLoading = (value: boolean) => {
    this.setState({
      loading: value,
    });
  };

  handlePagination = (value: string) => {
    const pageIndex = this.state.pageIndex;
    if (value === "prev" && pageIndex > 1) {
      this.props.getUsersWithPagination(
        this.props.userStore.users[0].email,
        "desc",
        this.state.searchText,
        this.state.districtFilter
      );
      this.setState({
        pageIndex: pageIndex - 1,
      });
    } else if (value === "next") {
      this.props.getUsersWithPagination(
        this.props.userStore.pagination,
        "asc",
        this.state.searchText,
        this.state.districtFilter
      );
      this.setState({
        pageIndex: pageIndex + 1,
      });
    }
  };

  handleFilter = (e: any) => {
    this.setState(
      {
        searchText: e.target.value,
      },
      () => {
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(() => {
          if (this.state.searchText.length === 0) {
            this.handleGetPreviewData();
            this.setState({ pageIndex: 1 });
          } else if (this.state.searchText.length <= 2) {
            notification.warning({
              message: "Please input at least 3 characters",
            });
          } else {
            this.handleGetPreviewData();
            this.setState({ pageIndex: 1 });
          }
        }, 500);
      }
    );
  };

  handleSelectDistrict = (value: string) => {
    this.setState(
      {
        districtFilter: value,
        pageIndex: 1,
      },
      () => {
        if (this.state.districtFilter) {
          this.props.getUsersWithPagination(
            "",
            "asc",
            this.state.searchText,
            this.state.districtFilter
          );
        } else {
          this.handleGetPreviewData();
        }
      }
    );
  };

  renderFilteredData = () => {
    let listView: any = [];
    if (this.props.userStore.users.length > 0) {
      this.props.userStore.users.map((eachUsers) => {
        if (this.state.districtFilter) {
          if (this.state.districtFilter !== eachUsers.address.district) {
            return null;
          }
        }
        listView.push({
          key: eachUsers.id,
          name: eachUsers.name,
          age: eachUsers.age,
          profession: eachUsers.profession,
          district: eachUsers.address.district,
          state: eachUsers.address.state,
          view: eachUsers.id,
        });
        return null;
      });
    }
    return listView;
  };

  render() {
    if (this.props.authStore.userLoading) {
      return null;
    }

    return (
      <>
        <Header history={this.props.history} location={this.props.location} />
        <WindowResizer />
        <SecurityNavigator location={this.props.location} />
        <VerticalContainer
          style={{
            width: "100%",
          }}
        >
          <VerticalContainer
            style={{
              alignItems: "flex-start",
              width: "90%",
              height: "100%",
              maxWidth: 1200,
              padding: "40px 0px",
            }}
          >
            <FlexibleRowContainer
              style={{
                width: "100%",
                alignItems:
                  this.props.utilsStore.deviceType === DeviceType.Mobile
                    ? "flex-start"
                    : "center",
              }}
            >
              <Title3
                style={{
                  marginBottom:
                    this.props.utilsStore.deviceType === DeviceType.Mobile
                      ? 5
                      : 0,
                }}
              >
                Volunteers
              </Title3>
              <div style={{ flex: 1, marginTop: 5 }} />
              <Select
                style={{
                  width: 250,
                  marginRight: 10,
                  marginBottom:
                    this.props.utilsStore.deviceType === DeviceType.Mobile
                      ? 10
                      : 0,
                }}
                value={this.state.districtFilter || undefined}
                onChange={this.handleSelectDistrict}
                placeholder="Filter by District"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  if (option) {
                    return (
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  } else {
                    return false;
                  }
                }}
                showSearch
                allowClear={true}
              >
                {getDistrictList(this.props.authStore.user?.state ?? "").map(
                  (eachDistrict: string, index: number) => (
                    <Option key={index} value={eachDistrict}>
                      {eachDistrict}
                    </Option>
                  )
                )}
              </Select>
              <TextInput
                placeholder="Search by Email"
                value={this.state.searchText}
                width={250}
                onChange={this.handleFilter}
              />
            </FlexibleRowContainer>
            <Divider style={{ marginTop: 10, marginBottom: 30 }} />
            <Table
              style={{
                width: "100%",
                maxWidth: "100%",
                cursor: "pointer",
                overflowX: "auto",
              }}
              columns={this.columns}
              dataSource={this.renderFilteredData()}
              loading={this.props.userStore.loading}
              pagination={false}
            />
            <RowContainer
              style={{
                width: "100%",
                justifyContent: "flex-end",
                marginTop: 30,
              }}
            >
              {!this.props.userStore.loading && (
                <>
                  <LeftOutlined
                    style={buttonDisableStyle(
                      this.state.pageIndex === 1 ? true : false
                    )}
                    onClick={this.handlePagination.bind(this, "prev")}
                  />
                  <Body
                    style={{ marginBottom: 0, marginLeft: 15, marginRight: 15 }}
                  >
                    {`${this.state.pageIndex} / -`}
                  </Body>
                  <RightOutlined
                    onClick={
                      this.props.userStore.pagination
                        ? this.handlePagination.bind(this, "next")
                        : () => {}
                    }
                    style={buttonDisableStyle(
                      this.props.userStore.pagination ? false : true
                    )}
                  />
                </>
              )}
            </RowContainer>
          </VerticalContainer>
        </VerticalContainer>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    userStore: state.userStore,
    utilsStore: state.utilsStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getUsersWithPagination: (
      pagination: string,
      type: string,
      search: string,
      districtFilter: string
    ) =>
      dispatch(
        getUsersWithPagination(pagination, type, search, districtFilter)
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Volunteers);
