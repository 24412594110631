import Ngo from "../../models/Ngo";

export interface ngoStateInterface {
  ngo: Ngo[];
  loading: boolean;
  pagination: string;
}

const initState: ngoStateInterface = {
  ngo: [],
  loading: false,
  pagination: "",
};

const ngoReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_NGO":
      return {
        ...state,
        ngo: action.payload.ngo,
        pagination: action.payload.pagination,
        loading: false,
      };
    case "SET_NGO_LOADING":
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default ngoReducer;
