import { User } from "../../models/User";

export interface userStateInterface {
  users: User[];
  suggestedUsers: User[];
  loading: boolean;
  pagination: string;
}

const initState: userStateInterface = {
  users: [],
  suggestedUsers: [],
  loading: false,
  pagination: "",
};

const userReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_USERS":
      return {
        ...state,
        users: action.payload.users,
        pagination: action.payload.pagination,
        loading: false,
      };
    case "UPDATE_SUGGESTED_USER":
      return {
        ...state,
        suggestedUsers: action.payload.suggestedUsers,
      };
    case "SET_USER_LOADING":
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default userReducer;
