export const getStateList = () => {
  const stateTemp: any = [
    "Johor",
    "Kedah",
    "Kelantan",
    "Melaka",
    "Negeri Sembilan",
    "Pahang",
    "Perak",
    "Perlis",
    "Pulau Pinang",
    "Sabah",
    "Sarawak",
    "Selangor",
    "Terengganu",
    "WP Kuala Lumpur",
    "WP Labuan",
    "WP Putrajaya",
  ];

  stateTemp.sort((a: string, b: string) => a.localeCompare(b));

  return stateTemp;
};
