import {
  CaretDownOutlined,
  CloseOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Popover } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Logo from "../assets/logo.png";
import Button from "../components/Button";
import { AdminRole } from "../models/Admin";
import { signOut } from "../store/actions/authActions";
import { authStateInterface } from "../store/reducers/authReducer";
import {
  DeviceType,
  utilsStateInterface,
} from "../store/reducers/utilsReducer";
import { COLOR } from "../styles/Colors";
import { RowContainer, VerticalContainer } from "../styles/Layout";
import { ActionText, BaseActionText, Body } from "../styles/Typography";
import { handleNavigation } from "../utils/navigator";

interface Props {
  history: any;
  location: any;
  authStore: authStateInterface;
  utilsStore: utilsStateInterface;
}

interface State {
  menuBarOpen: boolean;
  loading: boolean;
}

class Header extends Component<Props> {
  state: State = {
    menuBarOpen: false,
    loading: false,
  };

  handleMenuOpen = (state: boolean) => {
    this.setState({
      menuBarOpen: state,
    });
  };

  handleLoading = (value: boolean) => {
    this.setState({
      loading: value,
    });
  };

  handleNavigate = (path: string) => {
    if (path === "volunteer") {
      handleNavigation(this.props.history, "/volunteer");
    } else if (path === "dashboard") {
      handleNavigation(this.props.history, "/dashboard");
    } else if (path === "centers") {
      handleNavigation(this.props.history, "/centers");
    } else if (path === "roles") {
      handleNavigation(this.props.history, "/roles");
    } else if (path === "bookings") {
      handleNavigation(this.props.history, "/bookings");
    } else if (path === "resources") {
      handleNavigation(this.props.history, "/resources");
    } else if (path === "volunteers") {
      handleNavigation(this.props.history, "/volunteers");
    } else if (path === "healthVerificationLink") {
      handleNavigation(this.props.history, "/healthVerificationLink");
    } else if (path === "ngo") {
      handleNavigation(this.props.history, "/ngo");
    }
  };

  renderAuthItems = () => {
    const ngoAdmin = this.props.authStore.user?.role === "NGO" ? true : false;
    const popOverContent = (
      <VerticalContainer
        style={{
          alignItems: "flex-end",
          padding: 10,
        }}
      >
        <RowContainer
          style={{
            marginBottom: 10,
          }}
        >
          <Avatar
            style={{ backgroundColor: COLOR.RED, marginRight: 10 }}
            icon={<UserOutlined />}
          />
          <Body style={{ marginBottom: 0 }}>
            {this.props.authStore.user?.email}
          </Body>
        </RowContainer>
        <Body>
          {AdminRole[this.props.authStore.user?.role ?? "CENTER"]}
          {ngoAdmin && ` - ${this.props.authStore.user?.ngoName}`}
        </Body>

        <Button
          text="Log Out"
          small={true}
          width={120}
          margin="10px 0px 10px 0px"
          onClick={() => {
            signOut();
          }}
        />
      </VerticalContainer>
    );

    return (
      <RowContainer>
        <Popover placement="bottom" content={popOverContent} trigger="click">
          <RowContainer style={{ marginLeft: 20, cursor: "pointer" }}>
            <Avatar
              style={{ backgroundColor: COLOR.RED, marginRight: 5 }}
              icon={<UserOutlined />}
            />
            <CaretDownOutlined style={{ color: COLOR.RED }} />
          </RowContainer>
        </Popover>
      </RowContainer>
    );
  };

  renderMobileMenuItems = (path: string, pathName: string) => {
    return (
      <ActionText onClick={this.handleNavigate.bind(this, path)}>
        <Body style={{ color: COLOR.RED, marginTop: 5, marginBottom: 0 }}>
          {pathName}
        </Body>
      </ActionText>
    );
  };

  renderSelectedMenuItems = (path: string, pathName: string) => {
    if (this.props.location.pathname === "/" + path) {
      return (
        <BaseActionText style={{ color: COLOR.OPAQUERED, marginLeft: 25 }}>
          {pathName}
        </BaseActionText>
      );
    } else {
      return (
        <ActionText
          style={{
            marginLeft: 25,
          }}
          onClick={this.handleNavigate.bind(this, path)}
        >
          {pathName}
        </ActionText>
      );
    }
  };

  renderMenuItems = () => {
    const superAdmin =
      this.props.authStore.user?.role === "SUPER" ? true : false;
    return (
      <RowContainer>
        {superAdmin && this.renderSelectedMenuItems("roles", "Roles")}
        {this.renderSelectedMenuItems("volunteers", "Volunteers")}
        {this.renderSelectedMenuItems("centers", "Centers")}
        {this.renderSelectedMenuItems("bookings", "Bookings")}
        {superAdmin && this.renderSelectedMenuItems("resources", "Resources")}
        {superAdmin &&
          this.renderSelectedMenuItems("healthVerificationLink", "Meet")}
        {superAdmin && this.renderSelectedMenuItems("ngo", "NGO")}
        {this.renderAuthItems()}
      </RowContainer>
    );
  };

  renderMenu = () => {
    if (this.props.utilsStore.deviceType === DeviceType.Mobile) {
      return (
        <svg
          style={{ cursor: "pointer" }}
          width="18"
          height="12"
          viewBox="0 0 18 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={this.handleMenuOpen.bind(this, true)}
        >
          <path
            d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z"
            fill="#FD5C5E"
          />
        </svg>
      );
    } else {
      return this.renderMenuItems();
    }
  };

  renderMobileMenu = () => {
    const superAdmin =
      this.props.authStore.user?.role === "SUPER" ? true : false;
    const ngoAdmin = this.props.authStore.user?.role === "NGO" ? true : false;

    return (
      <Overlay>
        <VerticalContainer
          style={{
            width: "90%",
            height: "100%",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <CloseOutlined
            onClick={this.handleMenuOpen.bind(this, false)}
            style={{
              marginTop: 20,
              alignSelf: "flex-end",
              color: COLOR.RED,
              fontSize: 25,
            }}
          />
          <img
            style={{
              height: 40,
              marginTop: 10,
              marginBottom: 10,
              cursor: "pointer",
            }}
            alt="Logo"
            src={Logo}
            onClick={this.handleNavigate.bind(this, "dashboard")}
          />
          <RowContainer
            style={{
              marginBottom: 5,
            }}
          >
            <Avatar
              style={{ backgroundColor: COLOR.RED, marginRight: 10 }}
              icon={<UserOutlined />}
            />
            <Body style={{ marginBottom: 10, marginTop: 10 }}>
              {this.props.authStore.user?.email}
            </Body>
          </RowContainer>
          <Body>
            {AdminRole[this.props.authStore.user?.role ?? "CENTER"]}
            {ngoAdmin && ` - ${this.props.authStore.user?.ngoName}`}
          </Body>
          {superAdmin && this.renderMobileMenuItems("roles", "Roles")}
          {this.renderMobileMenuItems("volunteers", "Volunteers")}
          {this.renderMobileMenuItems("centers", "Centers")}
          {this.renderMobileMenuItems("bookings", "Bookings")}
          {superAdmin && this.renderMobileMenuItems("resources", "Resources")}
          {superAdmin &&
            this.renderMobileMenuItems("healthVerificationLink", "Meet")}
          {superAdmin && this.renderMobileMenuItems("ngo", "NGO")}
          <ActionText
            onClick={() => {
              signOut();
            }}
          >
            <Body style={{ color: COLOR.RED, marginTop: 5 }}>Log Out</Body>
          </ActionText>
        </VerticalContainer>
      </Overlay>
    );
  };

  render() {
    return (
      <>
        <RowContainer
          style={{
            width: "100%",
            height: 70,
            borderBottom: `solid 2px ${COLOR.LIGHTGREY}`,
          }}
        >
          <RowContainer
            style={{
              width: "90%",
              maxWidth: 1200,
            }}
          >
            <img
              style={{
                height: 45,
                marginTop: 10,
                marginBottom: 10,
                cursor: "pointer",
              }}
              alt="Logo"
              src={Logo}
              onClick={this.handleNavigate.bind(this, "dashboard")}
            />

            <div style={{ flexGrow: 1 }} />
            {this.renderMenu()}
            {this.props.utilsStore.deviceType <= DeviceType.Tablet &&
              this.state.menuBarOpen &&
              this.renderMobileMenu()}
          </RowContainer>
        </RowContainer>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(Header);

export const Overlay = styled(VerticalContainer)`
  z-index: 5;
  overflow-y: auto;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background ${COLOR.WHITE};
`;
