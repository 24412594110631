import Admin from "../../models/Admin";

export interface roleStateInterface {
  users: Admin[];
  loading: boolean;
  pagination: string;
}

const initState: roleStateInterface = {
  users: [],
  loading: false,
  pagination: "",
};

const roleReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_ROLES":
      return {
        ...state,
        users: action.payload.users,
        pagination: action.payload.pagination,
        loading: false,
      };
    case "SET_ROLE_LOADING":
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default roleReducer;
