import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { DatePicker, Divider, notification, Select, Switch, Table } from "antd";
import moment from "moment";
import { Component } from "react";
import { connect } from "react-redux";
import Button from "../components/Button";
import Header from "../components/Header";
import TextInput from "../components/TextInput";
import { VolunteerRoles } from "../models/Booking";
import Centers from "../models/Center";
import SecurityNavigator from "../navigator/SecurityNavigator";
import WindowResizer from "../navigator/WindowResizer";
import { getBookingsWithPagination } from "../store/actions/bookingActions";
import { getAllCenters } from "../store/actions/centerActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { bookingStateInterface } from "../store/reducers/bookingReducer";
import { centerStateInterface } from "../store/reducers/centerReducer";
import {
  DeviceType,
  utilsStateInterface,
} from "../store/reducers/utilsReducer";
import { COLOR } from "../styles/Colors";
import {
  FlexibleRowContainer,
  RowContainer,
  VerticalContainer,
} from "../styles/Layout";
import { Body, Title3 } from "../styles/Typography";
import { handleNavigation } from "../utils/navigator";

interface Props {
  history: any;
  location: any;
  authStore: authStateInterface;
  bookingStore: bookingStateInterface;
  centerStore: centerStateInterface;
  utilsStore: utilsStateInterface;
  getAllCenters: (state: string) => void;
  getBookingsWithPagination: (
    pagination: string,
    type: string,
    search: string,
    searchType: string,
    searchRange?: moment.Moment
  ) => void;
}

interface State {
  pageIndex: number;
  filterCenter: boolean;
  centerId: string;
  userId: string;
  dateRange: moment.Moment | null;
}

const buttonDisableStyle = (value: boolean) => ({
  cursor: value ? "auto" : "pointer",
  color: value ? COLOR.GREY : COLOR.RED,
});

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Volunteer",
    dataIndex: "userEmail",
  },
  {
    title: "Center Name",
    dataIndex: "centerName",
  },
  {
    title: "District",
    dataIndex: "district",
  },
  {
    title: "Volunteer Date",
    dataIndex: "volunteerDate",
  },
  {
    title: "Role",
    dataIndex: "role",
  },
  {
    title: "Shift",
    dataIndex: "volunteerShift",
  },
  {
    title: "Action",
    dataIndex: "view",
    render: (view: any) => (
      <Button
        text="View"
        small={true}
        width={100}
        onClick={() => {
          if (view) {
            window.open("/editBooking/" + view);
          }
        }}
      />
    ),
  },
];

const { Option } = Select;

class Bookings extends Component<Props> {
  searchTimeout: ReturnType<typeof setTimeout> | null = null;

  state: State = {
    pageIndex: 1,
    filterCenter: true,
    centerId: "",
    userId: "",
    dateRange: null,
  };

  componentDidMount() {
    if (this.props.authStore.user) {
      this.handleGetPreviewData();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.authStore.user !== prevProps.authStore.user &&
      this.props.authStore.user
    ) {
      this.handleGetPreviewData();
    }
  }

  handleGetPreviewData = async () => {
    if (this.props.authStore.user?.role === "SUPER") {
      this.props.getAllCenters("");
    } else {
      this.props.getAllCenters(this.props.authStore.user?.state ?? "");
    }
  };

  handlePagination = (value: string) => {
    const pageIndex = this.state.pageIndex;

    const searchValue = this.state.filterCenter
      ? this.state.centerId
      : this.state.userId;
    const searchType = this.state.filterCenter ? "center" : "user";
    if (value === "prev" && pageIndex > 1) {
      this.props.getBookingsWithPagination(
        this.props.bookingStore.bookings[0].id,
        "desc",
        searchValue,
        searchType
      );
      this.setState({
        pageIndex: pageIndex - 1,
      });
    } else if (value === "next") {
      this.props.getBookingsWithPagination(
        this.props.bookingStore.pagination,
        "asc",
        searchValue,
        searchType
      );
      this.setState({
        pageIndex: pageIndex + 1,
      });
    }
  };

  handleChangeFilterType = () => {
    this.setState({
      filterCenter: !this.state.filterCenter,
    });
  };

  handleSelectCenter = (value: string) => {
    this.setState(
      {
        centerId: value,
        pageIndex: 1,
        dateRange: null,
      },
      () => {
        if (this.state.centerId) {
          this.props.getBookingsWithPagination(
            "",
            "asc",
            this.state.centerId,
            "center"
          );
        }
      }
    );
  };

  handleSelectDateRange = (value: any) => {
    this.setState(
      {
        dateRange: value,
        pageIndex: 1,
      },
      () => {
        if (this.state.dateRange) {
          this.props.getBookingsWithPagination(
            "",
            "asc",
            this.state.centerId,
            "range",
            value
          );
        } else {
          this.props.getBookingsWithPagination(
            "",
            "asc",
            this.state.centerId,
            "center"
          );
        }
      }
    );
  };

  handleFilter = (e: any) => {
    this.setState(
      {
        userId: e.target.value,
      },
      () => {
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(() => {
          if (this.state.userId.length <= 2) {
            notification.warning({
              message: "Please input a valid system user id",
            });
          } else {
            this.setState({ pageIndex: 1 });
            this.props.getBookingsWithPagination(
              "",
              "asc",
              this.state.userId,
              "user"
            );
          }
        }, 500);
      }
    );
  };

  handleAddBooking = () => {
    handleNavigation(this.props.history, "/addBooking");
  };

  renderFilteredData = () => {
    let listView: any = [];
    if (
      this.props.bookingStore.bookings.length > 0 &&
      this.props.centerStore.centers.length > 0
    ) {
      this.props.bookingStore.bookings.map((eachBook, index) => {
        let selectedCenter = {
          centerName: "",
          district: "",
        };
        this.props.centerStore.centers.map((eachCenter: Centers) => {
          if (eachBook.centerId === eachCenter.id) {
            selectedCenter = {
              centerName: eachCenter.centerName,
              district: eachCenter.centerAddress.district,
            };
          }
          return null;
        });

        let volunteerShift = "";
        eachBook.shift.forEach((shift, index) => {
          let shiftType = "";
          switch (shift) {
            case "MOR":
              shiftType = "M";
              break;
            case "EVE":
              shiftType = "E";
              break;
            case "WHOLE":
              shiftType = "W";
              break;
          }

          if (index !== eachBook.shift.length - 1) {
            volunteerShift = volunteerShift.concat(shiftType + ", ");
          } else {
            volunteerShift = volunteerShift.concat(shiftType);
          }
          return null;
        });

        //INFO : Security Condition
        if (this.state.filterCenter) {
          if (this.state.centerId !== eachBook.centerId) {
            return null;
          }
        } else {
          if (this.state.userId !== eachBook.userId) {
            return null;
          }
        }
        if (this.props.authStore.user?.role === "CENTER") {
          if (!this.props.authStore.user.center?.includes(eachBook.centerId)) {
            return null;
          }
        }

        listView.push({
          key: eachBook.id,
          id: index + 1,
          district: selectedCenter.district,
          centerName: selectedCenter.centerName,
          volunteerDate:
            moment(eachBook.startDate.seconds * 1000).format("DD/MM/YYYY") +
            " - " +
            moment(eachBook.endDate.seconds * 1000).format("DD/MM/YYYY"),
          role: VolunteerRoles[eachBook.roles],
          volunteerShift: volunteerShift,
          userEmail: eachBook.userEmail ?? "",
          view: eachBook.id,
        });
        return null;
      });
    }
    return listView;
  };

  renderEmptyText = () => {
    if (this.state.filterCenter) {
      return "Select A Center To Begin";
    } else {
      return "Enter A System User Id To Begin (Go to Volunteers Page & Select a Volunteer to see his/her User ID)";
    }
  };

  render() {
    if (this.props.authStore.userLoading) {
      return null;
    }

    const centerList: Centers[] = [];
    if (this.props.centerStore.centers.length > 0) {
      this.props.centerStore.centers.map((eachCenter) => {
        if (this.props.authStore.user?.role === "CENTER") {
          if (!this.props.authStore.user.center?.includes(eachCenter.id)) {
            return null;
          }
        }
        centerList.push(eachCenter);
        return null;
      });
    }
    centerList.sort((a, b) => (a.centerName > b.centerName ? 1 : -1));

    return (
      <>
        <Header history={this.props.history} location={this.props.location} />
        <WindowResizer />
        <SecurityNavigator location={this.props.location} />
        <VerticalContainer
          style={{
            width: "100%",
          }}
        >
          <VerticalContainer
            style={{
              alignItems: "flex-start",
              width: "90%",
              height: "100%",
              maxWidth: 1200,
              padding: "40px 0px",
            }}
          >
            <FlexibleRowContainer
              style={{
                width: "100%",
                alignItems:
                  this.props.utilsStore.deviceType === DeviceType.Mobile
                    ? "flex-start"
                    : "center",
              }}
            >
              <Title3 style={{ marginBottom: 0 }}>Bookings</Title3>
              <div style={{ flex: 1, marginTop: 5 }} />
              <RowContainer
                style={{
                  marginBottom:
                    this.props.utilsStore.deviceType === DeviceType.Mobile
                      ? 10
                      : 0,
                }}
              >
                <Body style={{ marginBottom: 0 }}>Filter By : </Body>
                <Switch
                  style={{ margin: "0px 10px" }}
                  checked={this.state.filterCenter}
                  checkedChildren="Centers"
                  unCheckedChildren="System User Id"
                  onChange={this.handleChangeFilterType}
                />
              </RowContainer>
              {this.state.filterCenter ? (
                <Select
                  style={{ width: 200, marginRight: 10 }}
                  value={this.state.centerId}
                  placeholder="Select a center"
                  onChange={this.handleSelectCenter}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    if (option) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    } else {
                      return false;
                    }
                  }}
                  showSearch
                >
                  {centerList.map((eachCenter, index: number) => {
                    return (
                      <Option
                        key={index}
                        label={eachCenter.centerName}
                        value={eachCenter.id}
                      >
                        {eachCenter.centerName}
                      </Option>
                    );
                  })}
                </Select>
              ) : (
                <TextInput
                  placeholder="User Id"
                  value={this.state.userId}
                  width={200}
                  onChange={this.handleFilter}
                />
              )}
              <DatePicker
                picker="week"
                style={{
                  width: 200,
                  marginTop:
                    this.props.utilsStore.deviceType <= DeviceType.Mobile
                      ? 10
                      : 0,
                }}
                onChange={this.handleSelectDateRange}
                disabled={!this.state.centerId}
                value={this.state.dateRange?.startOf("isoWeek")}
              />
            </FlexibleRowContainer>
            {this.props.authStore.user?.role === "SUPER" && (
              <RowContainer
                style={{
                  width: "100%",
                  justifyContent: "flex-end",
                  marginTop: 15,
                }}
              >
                <Button
                  text="Add Booking"
                  small={true}
                  width={"auto"}
                  onClick={this.handleAddBooking}
                />
              </RowContainer>
            )}
            <Divider style={{ marginTop: 10, marginBottom: 30 }} />
            <Table
              style={{
                width: "100%",
                maxWidth: "100%",
                cursor: "pointer",
                overflowX: "auto",
              }}
              locale={{
                emptyText: this.renderEmptyText(),
              }}
              columns={columns}
              dataSource={this.renderFilteredData()}
              loading={this.props.bookingStore.loading}
              pagination={false}
            />
            <RowContainer
              style={{
                width: "100%",
                justifyContent: "flex-end",
                marginTop: 30,
              }}
            >
              {!this.props.bookingStore.loading && (
                <>
                  <LeftOutlined
                    style={buttonDisableStyle(
                      this.state.pageIndex === 1 ? true : false
                    )}
                    onClick={this.handlePagination.bind(this, "prev")}
                  />
                  <Body
                    style={{ marginBottom: 0, marginLeft: 15, marginRight: 15 }}
                  >
                    {`${this.state.pageIndex} / -`}
                  </Body>
                  <RightOutlined
                    onClick={
                      this.props.bookingStore.pagination
                        ? this.handlePagination.bind(this, "next")
                        : () => {}
                    }
                    style={buttonDisableStyle(
                      this.props.bookingStore.pagination ? false : true
                    )}
                  />
                </>
              )}
            </RowContainer>
          </VerticalContainer>
        </VerticalContainer>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    bookingStore: state.bookingStore,
    centerStore: state.centerStore,
    utilsStore: state.utilsStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getBookingsWithPagination: (
      pagination: string,
      type: string,
      search: any,
      searchType: string,
      searchRange?: moment.Moment
    ) =>
      dispatch(
        getBookingsWithPagination(
          pagination,
          type,
          search,
          searchType,
          searchRange
        )
      ),
    getAllCenters: (state: string) => dispatch(getAllCenters(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bookings);
