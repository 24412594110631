import {
  CloseCircleOutlined,
  FileTextFilled,
  InfoCircleOutlined,
  LeftOutlined,
  MailOutlined,
  PushpinOutlined,
  RightOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Col,
  Divider,
  notification,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
} from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import AvatarImage from "../assets/avatar.png";
import Button from "../components/Button";
import Header from "../components/Header";
import TextInput from "../components/TextInput";
import {
  BankType,
  GenderType,
  ProfessionHealthType,
  ProfessionType,
  RaceType,
  User as UserModel,
} from "../models/User";
import SecurityNavigator from "../navigator/SecurityNavigator";
import WindowResizer from "../navigator/WindowResizer";
import { getBookingsWithPagination } from "../store/actions/bookingActions";
import {
  getUser,
  updateGeneralUser,
  updateHealthUser,
  UserRes,
} from "../store/actions/userActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { bookingStateInterface } from "../store/reducers/bookingReducer";
import {
  DeviceType,
  utilsStateInterface,
} from "../store/reducers/utilsReducer";
import { COLOR } from "../styles/Colors";
import { RowContainer, VerticalContainer } from "../styles/Layout";
import { Body, CustomActionTitle, Footer, Title3 } from "../styles/Typography";
import { getDistrictList, getStateList } from "../utils/districtList";
import { handleNavigation, handleNotiNavigation } from "../utils/navigator";

const { TabPane } = Tabs;
const { Option } = Select;

interface Props {
  history: any;
  location: any;
  match: any;
  authStore: authStateInterface;
  utilsStore: utilsStateInterface;
  bookingStore: bookingStateInterface;
  getBookingsWithPagination: (
    pagination: string,
    type: string,
    search: string,
    searchType: string
  ) => void;
}

interface UserError {
  icError: string;
  nameError: string;
  professionHealthTypeError: string;
  verifiedHealthIDError: string;
}

interface State {
  loading: boolean;
  user: UserModel;
  pageIndex: number;
  userError: UserError;
}

const buttonDisableStyle = (value: boolean) => ({
  cursor: value ? "auto" : "pointer",
  color: value ? COLOR.GREY : COLOR.RED,
});

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Volunteer Date",
    dataIndex: "volunteerDate",
  },
  {
    title: "Shift",
    dataIndex: "volunteerShift",
  },
  {
    title: "Validation",
    dataIndex: "validation",
  },
  {
    title: "Payment",
    dataIndex: "payment",
  },
];

class ProfileEditor extends Component<Props> {
  state: State = {
    pageIndex: 1,
    loading: false,
    user: {
      id: "",
      name: "",
      address: {
        street: "",
        poscode: "",
        state: getStateList()[0],
        district: getDistrictList("")[0],
      },
      ic: "",
      age: 0,
      gender: Object.keys(GenderType)[0] as keyof typeof GenderType,
      race: Object.keys(RaceType)[0] as keyof typeof RaceType,
      raceOthers: "",
      email: "",
      mobileNo: "",
      profession: Object.keys(ProfessionType)[0] as keyof typeof ProfessionType,
      professionOthers: "",
      professionHealthId: "",
      professionHealthType: "" as keyof typeof ProfessionHealthType,
      organisationName: "",
      verifiedHealthID: false,
      organisationAddress: "",
      bankName: "" as keyof typeof BankType,
      bankAccountName: "",
      bankAccountNo: "",
      imageToken: "",
      createdAt: moment().toDate(),
    },
    userError: {
      icError: "",
      nameError: "",
      professionHealthTypeError: "",
      verifiedHealthIDError: "",
    },
  };

  componentDidMount() {
    if (this.props.authStore.user) {
      this.handleGetPreviewData();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.authStore.user !== prevProps.authStore.user &&
      this.props.authStore.user
    ) {
      this.handleGetPreviewData();
    }
  }

  handleGetParams() {
    const id = this.props.match.params.id;
    return id;
  }

  handleLoading = (value: boolean) => {
    this.setState({
      loading: value,
    });
  };

  handleGetPreviewData = async () => {
    this.handleLoading(true);
    const userQuery: UserRes = await getUser(this.handleGetParams());
    if (userQuery.err) {
      handleNotiNavigation(this.props.history, 4, "/volunteers");
    } else {
      this.setState({
        user: userQuery.data,
      });
      this.props.getBookingsWithPagination(
        "",
        "asc",
        this.state.user.id,
        "user"
      );
      this.handleLoading(false);
    }
  };

  handlePagination = (value: string) => {
    const pageIndex = this.state.pageIndex;

    if (value === "prev" && pageIndex > 1) {
      this.props.getBookingsWithPagination(
        this.props.bookingStore.bookings[0].id,
        "desc",
        this.state.user.id,
        "user"
      );
      this.setState({
        pageIndex: pageIndex - 1,
      });
    } else if (value === "next") {
      this.props.getBookingsWithPagination(
        this.props.bookingStore.pagination,
        "asc",
        this.state.user.id,
        "user"
      );
      this.setState({
        pageIndex: pageIndex + 1,
      });
    }
  };

  handleChange = (e: any) => {
    let originalUser = JSON.parse(JSON.stringify(this.state.user));
    originalUser[e.target.id] = e.target.value;
    this.setState({
      user: originalUser,
    });
  };

  renderFilteredData = () => {
    let listView: any = [];
    if (this.props.bookingStore.bookings.length > 0) {
      this.props.bookingStore.bookings.map((eachBook, index) => {
        if (this.state.user.id !== eachBook.userId) {
          return null;
        }

        let volunteerShift = "";
        let validation = "";
        let payment = 0;

        eachBook.shift.map((shift, index) => {
          let status = "IV";
          const date = moment(eachBook.bookDate[index].seconds * 1000);

          if (eachBook.validation) {
            eachBook.validation.map((eachValidation) => {
              if (
                moment(eachValidation.date.seconds * 1000).format(
                  "DD/MM/YY"
                ) === date.format("DD/MM/YY")
              ) {
                status = eachValidation.result;
              }
              return null;
            });
          }

          let shiftType = "";
          switch (shift) {
            case "MOR":
              shiftType = "M";
              break;
            case "EVE":
              shiftType = "E";
              break;
            case "WHOLE":
              shiftType = "W";
              break;
          }

          if (status === "V") {
            if (shiftType === "W") {
              payment += 50;
            } else {
              payment += 25;
            }
          }

          if (index !== eachBook.shift.length - 1) {
            volunteerShift = volunteerShift.concat(shiftType + ", ");
            validation = validation.concat(status + ", ");
          } else {
            volunteerShift = volunteerShift.concat(shiftType);
            validation = validation.concat(status);
          }
          return null;
        });

        listView.push({
          key: eachBook.id,
          id: index + 1,
          volunteerDate:
            moment(eachBook.startDate.seconds * 1000).format("DD/MM/YYYY") +
            " - " +
            moment(eachBook.endDate.seconds * 1000).format("DD/MM/YYYY"),
          validation: validation,
          volunteerShift: volunteerShift,
          payment: "RM " + payment,
        });
        return null;
      });
    }
    return listView;
  };

  handleNavigate = (type: string) => {
    if (type === "volunteers") {
      handleNavigation(this.props.history, "/volunteers");
    }
  };

  handleNotification = (type: string, message: string, description: string) => {
    if (type === "success") {
      notification["success"]({ message: message, description: "" });
    } else if (type === "warning") {
      notification["warning"]({
        message: message,
        description: description,
      });
    }
  };

  handleSelectChange = (id: string, value: any) => {
    const userState = JSON.parse(JSON.stringify(this.state.user));
    if (id === "verifiedHealthID") {
      if (value === "false") {
        value = false;
      } else {
        value = true;
      }
    } else if (id === "blacklisted") {
      if (value === "False") {
        value = false;
      } else {
        value = true;
      }
    }

    userState[id] = value;
    this.setState({
      user: userState,
    });
  };

  handleUpdateUser = async () => {
    const regex = /^\d{6}-\d{2}-\d{4}$/;

    if (this.state.user.profession !== "HEALTH") {
      if (!regex.test(this.state.user.ic.replace(/\s/g, ""))) {
        this.setState({
          userError: {
            icError: "Please enter IC with the correct format",
            nameError: "",
            professionHealthTypeError: "",
            verifiedHealthIDError: "",
          },
        });
      } else if (this.state.user.name.replace(/\s/g, "").length <= 0) {
        this.setState({
          userError: {
            icError: "",
            nameError: "Please enter the users' name",
            professionHealthTypeError: "",
            verifiedHealthIDError: "",
          },
        });
      } else {
        const userUpdate = await updateGeneralUser({
          id: this.props.match.params.id,
          ic: this.state.user.ic,
          name: this.state.user.name,
          gender: this.state.user.gender,
          blacklisted: this.state.user.blacklisted ? true : false,
        });
        if (userUpdate) {
          this.handleNotification(
            "warning",
            "User Could Not Be Updated",
            userUpdate
          );
        } else {
          this.handleNavigate("volunteers");
        }
      }
    } else {
      if (this.state.user.professionHealthType) {
        if (this.state.user.verifiedHealthID !== undefined) {
          if (!regex.test(this.state.user.ic.replace(/\s/g, ""))) {
            this.setState({
              userError: {
                icError: "Please enter IC with the correct format",
                nameError: "",
                professionHealthTypeError: "",
                verifiedHealthIDError: "",
              },
            });
          } else if (this.state.user.name.replace(/\s/g, "").length <= 0) {
            this.setState({
              userError: {
                icError: "",
                nameError: "Please enter the users' name",
                professionHealthTypeError: "",
                verifiedHealthIDError: "",
              },
            });
          } else {
            const userUpdate = await updateHealthUser({
              id: this.props.match.params.id,
              professionHealthType: this.state.user.professionHealthType,
              verifiedHealthID: this.state.user.verifiedHealthID,
              ic: this.state.user.ic,
              name: this.state.user.name,
              gender: this.state.user.gender,
              blacklisted: this.state.user.blacklisted ? true : false,
            });
            if (userUpdate) {
              this.handleNotification(
                "warning",
                "User Could Not Be Updated",
                userUpdate
              );
            } else {
              this.handleNavigate("volunteers");
            }
          }
        } else {
          this.handleNotification(
            "warning",
            "User Could Not be Updated",
            "Medical ID Verification cannot be empty"
          );
        }
      } else {
        this.handleNotification(
          "warning",
          "User Could Not be Updated",
          "Profession Type cannot be empty"
        );
      }
    }
  };

  renderOccupation = () => {
    if (this.state.user?.profession) {
      const profession = Object(ProfessionType)[this.state.user.profession];
      if (profession === ProfessionType.OTHERS) {
        return this.state.user.professionOthers;
      } else if (profession === ProfessionType.HEALTH) {
        return "Doctor/Nurse/Medical Workers";
      } else if (profession === ProfessionType.NGO) {
        return "Society/Body/Agency";
      } else {
        return "Student";
      }
    }
    return "";
  };

  renderRace = () => {
    if (this.state.user?.race) {
      const race = Object(RaceType)[this.state.user.race];
      if (race === RaceType.MALAY) {
        return "Malay";
      } else if (race === RaceType.CHINESE) {
        return "Chinese";
      } else if (race === RaceType.INDIAN) {
        return "Indian";
      } else if (race === RaceType.SABAHBUMI) {
        return "Bumiputera - Sabah";
      } else if (race === RaceType.SARAWAKBUMI) {
        return "Bumiputera - Sarawak";
      } else if (race === RaceType.SEMENANJUNGBUMI) {
        return "Bumiputera - Semenanjung";
      } else {
        return "Others";
      }
    }
    return "";
  };

  renderImage = (): string => {
    let link: string = "";
    if (this.state.user.imageToken) {
      const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
      const storageBucketId = isProd
        ? process.env.REACT_APP_PROD_STORAGE_BUCKET
        : process.env.REACT_APP_DEV_STORAGE_BUCKET;
      link = `https://firebasestorage.googleapis.com/v0/b/${storageBucketId}/o/users%2F${this.state.user?.id}%2Favatar.jpeg?alt=media&token=${this.state.user?.imageToken}`;
    } else {
      link = AvatarImage;
    }

    return link;
  };

  renderHealthTypeSelection = () => {
    if (this.state.user.profession === "HEALTH") {
      return (
        <RowContainer style={{ width: "100%", marginTop: 20 }}>
          <VerticalContainer style={{ width: "48%", alignItems: "flex-start" }}>
            <Body style={{ marginBottom: 5 }}>Profession Type</Body>
            <Select
              id="professionHealthType"
              value={this.state.user.professionHealthType}
              style={{ width: "100%" }}
              placeholder={"Profession Type"}
              onChange={this.handleSelectChange.bind(
                this,
                "professionHealthType"
              )}
            >
              {Object.keys(ProfessionHealthType).map((key) => (
                <Option key={key} value={key}>
                  {Object(ProfessionHealthType)[key]}
                </Option>
              ))}
            </Select>
          </VerticalContainer>
          <div style={{ flex: 1 }} />
          <VerticalContainer style={{ width: "48%", alignItems: "flex-start" }}>
            <Body style={{ marginBottom: 5 }}>Medical ID Verified?</Body>
            <Select
              id="verifiedHealthID"
              value={JSON.stringify(this.state.user.verifiedHealthID)}
              style={{ width: "100%" }}
              placeholder={"Medical ID Verified?"}
              onChange={this.handleSelectChange.bind(this, "verifiedHealthID")}
            >
              <Option key={"false"} value={"false"}>
                No
              </Option>
              <Option key={"true"} value={"true"}>
                Yes
              </Option>
            </Select>
          </VerticalContainer>
        </RowContainer>
      );
    }
  };

  renderUpdateButton = () => {
    if (this.props.authStore.user?.role === "SUPER") {
      return (
        <Button
          text="Update"
          width={150}
          margin={"0px 10px"}
          onClick={this.handleUpdateUser}
          small={true}
        />
      );
    }
  };

  renderUserFirebaseAPI = () => {
    const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
    const storageBucketId = isProd
      ? process.env.REACT_APP_PROD_STORAGE_BUCKET
      : process.env.REACT_APP_DEV_STORAGE_BUCKET;
    return `https://firebasestorage.googleapis.com/v0/b/${storageBucketId}/o/users%2F`;
  };

  renderSecretAgreementForm = () => {
    return (
      this.renderUserFirebaseAPI() +
      this.state.user?.id +
      "%2Fforms%2FBorang%20Akta%20Rahsia." +
      this.state.user.secretAgreement?.name.split(".")[1] +
      "?alt=media&token=" +
      this.state.user.secretAgreement?.token
    );
  };

  renderLiabilityForm = () => {
    return (
      this.renderUserFirebaseAPI() +
      this.state.user?.id +
      "%2Fforms%2FBorang%20Penetapan%20Liabiliti." +
      this.state.user.liabilityAgreement?.name.split(".")[1] +
      "?alt=media&token=" +
      this.state.user.liabilityAgreement?.token
    );
  };

  renderHealthForm = () => {
    return (
      this.renderUserFirebaseAPI() +
      this.state.user?.id +
      "%2Fforms%2FBorang%20Kesihatan." +
      this.state.user.healthDeclaration?.name.split(".")[1] +
      "?alt=media&token=" +
      this.state.user.healthDeclaration?.token
    );
  };

  renderMedicalDegree = () => {
    return (
      this.renderUserFirebaseAPI() +
      this.state.user?.id +
      "%2Fforms%2FSijil%20APC." +
      this.state.user.medicalDegree?.name.split(".")[1] +
      "?alt=media&token=" +
      this.state.user.medicalDegree?.token
    );
  };

  renderOfferLetter = () => {
    return (
      this.renderUserFirebaseAPI() +
      this.state.user?.id +
      "%2Fforms%2FTawaran%20Pelatikan." +
      this.state.user.offerLetter?.name.split(".")[1] +
      "?alt=media&token=" +
      this.state.user.offerLetter?.token
    );
  };

  returnDocInfo = (position: number) => {
    let docInfo: any = [
      {
        title: "Akta Rahsia Form",
        link: this.renderSecretAgreementForm(),
        token: this.state.user.secretAgreement?.token,
      },
      {
        title: "Penetapan Liabiliti Form",
        link: this.renderLiabilityForm(),
        token: this.state.user.liabilityAgreement?.token,
      },
      {
        title: "Health Form",
        link: this.renderHealthForm(),
        token: this.state.user.healthDeclaration?.token,
      },
      {
        title: "Offer Letter",
        link: this.renderOfferLetter(),
        token: this.state.user.offerLetter?.token,
      },
      {
        title: "Medical Degree/APC Certificate",
        link: this.renderMedicalDegree(),
        token: this.state.user.medicalDegree?.token,
      },
    ];
    return docInfo[position];
  };

  renderDocInfo = (detail: any) => {
    return (
      <Row style={{ width: "100%", alignItems: "center" }}>
        <Col span={24} lg={18}>
          <RowContainer
            style={{
              width: "100%",
              justifyContent: "flex-start",
            }}
          >
            <FileTextFilled
              style={{ fontSize: 30, color: COLOR.RED, marginRight: 10 }}
            />
            <Title3 style={{ marginBottom: 0 }}>{detail.title}</Title3>
          </RowContainer>
        </Col>
        <Col span={24} lg={6}>
          <VerticalContainer
            style={{
              alignItems: "flex-end",
              margin:
                this.props.utilsStore.deviceType >= DeviceType.Laptop
                  ? "0px 5px"
                  : "20px 0px 0px 0px",
            }}
          >
            {detail.token ? (
              <RowContainer>
                <Button
                  text="View"
                  width={120}
                  small={true}
                  onClick={() => {
                    window.open(detail.link, "_blank");
                  }}
                />
              </RowContainer>
            ) : (
              <RowContainer>
                <Body
                  style={{
                    color: COLOR.RED,
                    marginBottom: 0,
                  }}
                >
                  Not Completed
                </Body>

                <CloseCircleOutlined
                  style={{
                    color: COLOR.RED,
                    marginLeft: 5,
                  }}
                />
              </RowContainer>
            )}
          </VerticalContainer>
        </Col>
      </Row>
    );
  };

  render() {
    const superAdmin = this.props.authStore.user?.role === "SUPER";

    if (this.props.authStore.userLoading) {
      return null;
    }

    return (
      <Spin
        spinning={this.state.loading}
        size="large"
        style={{
          width: "100vw",
          height: "100vh",
          maxHeight: "none",
        }}
      >
        <Header history={this.props.history} location={this.props.location} />
        <WindowResizer />
        <SecurityNavigator location={this.props.location} />
        <VerticalContainer
          style={{
            width: "100%",
          }}
        >
          <VerticalContainer
            style={{
              alignItems: "flex-start",
              width: "90%",
              height: "100%",
              maxWidth: 1200,
              padding: "40px 0px",
            }}
          >
            <RowContainer
              style={{ width: "100%", justifyContent: "flex-start" }}
            >
              <CustomActionTitle
                onClick={this.handleNavigate.bind(this, "volunteers")}
              >
                Volunteers
              </CustomActionTitle>
              <Title3 style={{ margin: "0px 10px" }}>/</Title3>
              <Title3 style={{ marginBottom: 0 }}>
                {this.state.user.name}
              </Title3>
              <div style={{ flex: 1 }} />
              {this.renderUpdateButton()}
            </RowContainer>
            <Divider style={{ marginTop: 10, marginBottom: 30 }} />
            <Row
              style={{
                width: "100%",
              }}
            >
              <Col span={24} lg={8}>
                <VerticalContainer
                  style={{
                    padding: 30,
                    borderRadius: 10,
                    border: `solid 1px #ccc`,
                  }}
                >
                  <VerticalContainer>
                    <img
                      src={this.renderImage()}
                      style={{
                        height: 130,
                        width: 130,
                        objectFit: "cover",
                        borderRadius: 200,
                      }}
                      alt={"Default Avatar"}
                    />
                  </VerticalContainer>
                  <VerticalContainer
                    style={{
                      alignItems: "flex-start",
                      marginTop: 5,
                      width: "100%",
                    }}
                  >
                    <Title3 style={{ marginBottom: 0 }}>
                      {this.state.user.name}
                    </Title3>
                    <RowContainer style={{ margin: "10px 0px" }}>
                      <MailOutlined
                        style={{ color: COLOR.RED, marginRight: 10 }}
                      />
                      <Body style={{ marginBottom: 0, color: COLOR.GREY }}>
                        {this.state.user.email}
                      </Body>
                    </RowContainer>
                    <RowContainer style={{ margin: "10px 0px" }}>
                      <InfoCircleOutlined
                        style={{ color: COLOR.RED, marginRight: 10 }}
                      />
                      <Body style={{ marginBottom: 0, color: COLOR.GREY }}>
                        {this.renderRace()}, {this.state.user.age}
                      </Body>
                    </RowContainer>
                    <RowContainer style={{ margin: "10px 0px" }}>
                      <UserOutlined
                        style={{ color: COLOR.RED, marginRight: 10 }}
                      />
                      <Body style={{ marginBottom: 0 }}>
                        {this.renderOccupation()}
                      </Body>
                    </RowContainer>
                    <RowContainer style={{ margin: "10px 0px" }}>
                      <PushpinOutlined
                        style={{ color: COLOR.RED, marginRight: 10 }}
                      />
                      <Body style={{ marginBottom: 0 }}>
                        {this.state.user.address.district},{" "}
                        {this.state.user.address.state}
                      </Body>
                    </RowContainer>
                  </VerticalContainer>
                </VerticalContainer>
              </Col>
              <Col span={1} />
              <Col span={24} lg={15}>
                <VerticalContainer
                  style={{
                    width: "100%",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginTop:
                      this.props.utilsStore.deviceType < DeviceType.Laptop
                        ? 30
                        : 0,
                  }}
                >
                  <Tabs
                    style={{
                      width: "100%",
                    }}
                    defaultActiveKey="1"
                  >
                    <TabPane tab="Information" key="1">
                      <VerticalContainer
                        style={{
                          width: "100%",
                          alignItems: "flex-start",
                          padding: 5,
                        }}
                      >
                        <VerticalContainer
                          style={{ width: "100%", alignItems: "flex-start" }}
                        >
                          {this.state.user.professionHealthId && (
                            <>
                              <Body style={{ marginBottom: 5, marginTop: 5 }}>
                                Medical ID
                              </Body>
                              <TextInput
                                id="professionHealthId"
                                value={this.state.user.professionHealthId}
                                disabled={true}
                                background={COLOR.LIGHTGREY}
                                width="100%"
                                placeholder=""
                              />
                            </>
                          )}
                          <Body
                            style={{
                              margin: "5px 0px",
                            }}
                          >
                            System User Id
                          </Body>
                          <TextInput
                            id="userId"
                            width="100%"
                            disabled={true}
                            placeholder={""}
                            background={COLOR.LIGHTGREY}
                            value={this.state.user.id}
                          />
                          <Body
                            style={{
                              marginBottom: 5,
                              marginTop: 20,
                            }}
                          >
                            IC
                          </Body>
                          <TextInput
                            id="ic"
                            width="100%"
                            disabled={superAdmin ? false : true}
                            placeholder="123456-11-1234"
                            background={
                              superAdmin ? undefined : COLOR.LIGHTGREY
                            }
                            onChange={this.handleChange}
                            value={this.state.user.ic}
                          />
                          {this.state.userError.icError && (
                            <Footer style={{ marginBottom: 0 }}>
                              {this.state.userError.icError}
                            </Footer>
                          )}
                          <Body
                            style={{
                              marginBottom: 5,
                              marginTop: 20,
                            }}
                          >
                            Name
                          </Body>
                          <TextInput
                            id="name"
                            width="100%"
                            disabled={superAdmin ? false : true}
                            placeholder="Name"
                            background={
                              superAdmin ? undefined : COLOR.LIGHTGREY
                            }
                            onChange={this.handleChange}
                            value={this.state.user.name}
                          />
                          {this.state.userError.nameError && (
                            <Footer style={{ marginBottom: 0 }}>
                              {this.state.userError.nameError}
                            </Footer>
                          )}
                          <Body style={{ marginBottom: 5, marginTop: 20 }}>
                            Gender
                          </Body>
                          <Select
                            id="gender"
                            value={this.state.user.gender}
                            style={{ width: "100%" }}
                            placeholder={"Gender"}
                            onChange={this.handleSelectChange.bind(
                              this,
                              "gender"
                            )}
                          >
                            {Object.keys(GenderType).map((key) => (
                              <Option key={key} value={key}>
                                {Object(GenderType)[key]}
                              </Option>
                            ))}
                          </Select>
                          <Body style={{ marginBottom: 5, marginTop: 20 }}>
                            Blacklisted
                          </Body>
                          <Select
                            id="blacklisted"
                            value={
                              this.state.user.blacklisted ? "True" : "False"
                            }
                            style={{ width: "100%" }}
                            placeholder={"Blacklisted"}
                            onChange={this.handleSelectChange.bind(
                              this,
                              "blacklisted"
                            )}
                          >
                            {["False", "True"].map((key) => (
                              <Option key={key} value={key}>
                                {key}
                              </Option>
                            ))}
                          </Select>
                          <Body
                            style={{
                              marginBottom: 5,
                              marginTop: 20,
                            }}
                          >
                            Company/Organisation/University Name
                          </Body>
                          <TextInput
                            id="organisationName"
                            width="100%"
                            disabled={true}
                            placeholder={""}
                            background={COLOR.LIGHTGREY}
                            value={this.state.user.organisationName}
                          />
                          <Body style={{ marginTop: 20 }}>
                            Company/Organisation/University Address
                          </Body>
                          <TextInput
                            id="organisationAddress"
                            width="100%"
                            disabled={true}
                            placeholder=""
                            background={COLOR.LIGHTGREY}
                            value={this.state.user.organisationAddress}
                          />
                          <Body style={{ marginBottom: 5, marginTop: 20 }}>
                            Telephone Number
                          </Body>
                          <TextInput
                            id="mobileNo"
                            width="100%"
                            disabled={true}
                            placeholder=""
                            background={COLOR.LIGHTGREY}
                            value={this.state.user.mobileNo}
                          />
                          <Body style={{ marginBottom: 5, marginTop: 20 }}>
                            Home Address
                          </Body>
                          <TextInput
                            id="street"
                            width="100%"
                            disabled={true}
                            placeholder=""
                            background={COLOR.LIGHTGREY}
                            value={this.state.user.address.street}
                          />
                          <Body style={{ marginBottom: 5, marginTop: 20 }}>
                            Postcode
                          </Body>
                          <TextInput
                            id="poscode"
                            width="100%"
                            disabled={true}
                            placeholder=""
                            background={COLOR.LIGHTGREY}
                            value={this.state.user.address.poscode}
                          />
                          <RowContainer
                            style={{ width: "100%", marginTop: 20 }}
                          >
                            <VerticalContainer
                              style={{ width: "48%", alignItems: "flex-start" }}
                            >
                              <Body style={{ marginBottom: 5 }}>State</Body>
                              <TextInput
                                id="state"
                                width="100%"
                                disabled={true}
                                placeholder=""
                                background={COLOR.LIGHTGREY}
                                value={this.state.user.address.state}
                              />
                            </VerticalContainer>
                            <div style={{ flex: 1 }} />
                            <VerticalContainer
                              style={{ width: "48%", alignItems: "flex-start" }}
                            >
                              <Body style={{ marginBottom: 5 }}>District</Body>
                              <TextInput
                                id="district"
                                width="100%"
                                disabled={true}
                                placeholder=""
                                background={COLOR.LIGHTGREY}
                                value={this.state.user.address.district}
                              />
                            </VerticalContainer>
                          </RowContainer>

                          <Body style={{ marginBottom: 5, marginTop: 20 }}>
                            Bank Name
                          </Body>
                          <TextInput
                            id="bankName"
                            width="100%"
                            disabled={true}
                            placeholder={""}
                            background={COLOR.LIGHTGREY}
                            value={this.state.user.bankName}
                          />

                          <Body style={{ marginBottom: 5, marginTop: 20 }}>
                            Bank Account No
                          </Body>
                          <TextInput
                            id="bankAccountNo"
                            width="100%"
                            disabled={true}
                            placeholder={""}
                            background={COLOR.LIGHTGREY}
                            value={this.state.user.bankAccountNo}
                          />

                          <Body style={{ marginBottom: 5, marginTop: 20 }}>
                            Bank Account Name
                          </Body>
                          <TextInput
                            id="bankAccountName"
                            width="100%"
                            disabled={true}
                            placeholder={""}
                            background={COLOR.LIGHTGREY}
                            value={this.state.user.bankAccountName}
                          />
                        </VerticalContainer>
                        {this.renderHealthTypeSelection()}
                      </VerticalContainer>
                    </TabPane>
                    <TabPane tab="Documents" key="2">
                      <VerticalContainer
                        style={{
                          width: "100%",
                          padding: 5,
                        }}
                      >
                        {this.renderDocInfo(this.returnDocInfo(0))}
                        <Divider />
                        {this.renderDocInfo(this.returnDocInfo(1))}
                        <Divider />
                        {this.renderDocInfo(this.returnDocInfo(2))}
                        <Divider />
                        {this.renderDocInfo(
                          this.returnDocInfo(
                            this.state.user.profession === "HEALTH" ? 4 : 3
                          )
                        )}
                        <Divider />
                      </VerticalContainer>
                    </TabPane>
                    <TabPane tab="Bookings" key="3">
                      <VerticalContainer
                        style={{
                          width: "100%",
                          padding: 5,
                        }}
                      >
                        <Table
                          style={{
                            width: "100%",
                            maxWidth: "100%",
                            overflowX: "auto",
                          }}
                          columns={columns}
                          dataSource={this.renderFilteredData()}
                          locale={{
                            emptyText: "No Bookings Available",
                          }}
                          bordered
                          loading={this.props.bookingStore.loading}
                          pagination={false}
                        />
                        <RowContainer
                          style={{
                            width: "100%",
                            justifyContent: "flex-end",
                            marginTop: 30,
                          }}
                        >
                          {!this.props.bookingStore.loading && (
                            <>
                              <LeftOutlined
                                style={buttonDisableStyle(
                                  this.state.pageIndex === 1 ? true : false
                                )}
                                onClick={this.handlePagination.bind(
                                  this,
                                  "prev"
                                )}
                              />
                              <Body
                                style={{
                                  marginBottom: 0,
                                  marginLeft: 15,
                                  marginRight: 15,
                                }}
                              >
                                {`${this.state.pageIndex} / -`}
                              </Body>
                              <RightOutlined
                                onClick={
                                  this.props.bookingStore.pagination
                                    ? this.handlePagination.bind(this, "next")
                                    : () => {}
                                }
                                style={buttonDisableStyle(
                                  this.props.bookingStore.pagination
                                    ? false
                                    : true
                                )}
                              />
                            </>
                          )}
                        </RowContainer>
                      </VerticalContainer>
                    </TabPane>
                  </Tabs>
                </VerticalContainer>
              </Col>
            </Row>
            {/* <RowContainer
              style={{
                marginTop: 15,
              }}
            >
              <Title3>Bookings</Title3>
              <Input
                placeholder="Search"
                value={this.state.searchText}
                allowClear
                style={{
                  flex: 3,

                  marginLeft: 10,
                  marginBottom: 10,
                  maxWidth: "100%",
                }}
                size="middle"
                onChange={this.handleFilter}
              />
            </RowContainer>

            <Divider />
            <Table
              style={{
                width: "100%",
                maxWidth: "100%",
                cursor: "pointer",
              }}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "30"],
              }}
              columns={this.columns}
              dataSource={this.state.filteredData}
            /> */}
          </VerticalContainer>
        </VerticalContainer>
      </Spin>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
    bookingStore: state.bookingStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getBookingsWithPagination: (
      pagination: string,
      type: string,
      search: string,
      searchType: string
    ) =>
      dispatch(getBookingsWithPagination(pagination, type, search, searchType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEditor);
