import CenterAnalytics from "../../models/CenterAnalytics";
import { PaymentAnalytics } from "../../models/PaymentAnalytics";
import { SpreadsheetAnalytics } from "../../models/SpreadsheetAnalytics";
import { StateAnalytics } from "../../models/StateAnalytics";

export interface analyticStateInterface {
  stateAnalytics: StateAnalytics | null;
  stateAnalyticsGroup: StateAnalytics[];
  previousStateAnalytics: StateAnalytics | null;
  spreadsheetAnalytics: SpreadsheetAnalytics | null;
  centerAnalytics: CenterAnalytics | null;
  paymentAnalytics: PaymentAnalytics | null;
}

const initState: analyticStateInterface = {
  stateAnalytics: null,
  stateAnalyticsGroup: [],
  previousStateAnalytics: null,
  spreadsheetAnalytics: null,
  centerAnalytics: null,
  paymentAnalytics: null,
};

const analyticReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_STATE_ANALYTICS":
      return {
        ...state,
        stateAnalytics: action.payload.stateAnalytics,
        previousStateAnalytics: action.payload.previousStateAnalytics,
      };
    case "UPDATE_STATE_ANALYTICS_GROUP":
      return {
        ...state,
        stateAnalyticsGroup: action.payload.stateAnalyticsGroup,
      };
    case "UPDATE_SPREADSHEET_ANALYTICS":
      return {
        ...state,
        spreadsheetAnalytics: action.payload.spreadsheetAnalytics,
      };
    case "UPDATE_CENTER_ANALYTICS":
      return {
        ...state,
        centerAnalytics: action.payload.centerAnalytics,
      };
    case "UPDATE_PAYMENT_ANALYTICS":
      return {
        ...state,
        paymentAnalytics: action.payload.paymentAnalytics,
      };
    default:
      return state;
  }
};

export default analyticReducer;
