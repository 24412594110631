import { DeviceType } from "../reducers/utilsReducer";

export const updateDeviceType = (deviceType: DeviceType) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: "UPDATE_DEVICE_TYPE",
        payload: {
          deviceType: deviceType,
        },
      });
    } catch (err) {}
  };
};

export const updateDeviceSize = (deviceSize: number) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: "UPDATE_DEVICE_SIZE",
        payload: {
          deviceSize: deviceSize,
        },
      });
    } catch (err) {}
  };
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
