import moment from "moment";
import {
  BookingModel,
  Validation,
  VolunteerRoles,
  VolunteerShiftType,
} from "../../models/Booking";
import CenterModel from "../../models/Center";
import { User as UserModel } from "../../models/User";
import { DateSelection } from "../../pages/AddBooking";
import firebase from "../../utils/firebase";

export interface BookRes {
  success: boolean;
  err: string;
}

export interface BookingRes {
  success: boolean;
  err: string;
  data: BookingModel | null;
}

export interface EachBookRes {
  data: {
    center: CenterModel;
    user: UserModel;
    booking: BookingModel;
  } | null;
  err: string;
}

export interface BookingWithUserModel extends BookingModel {
  userEmail?: string;
  userState?: string;
}

export const bookingQueryLimit = 10;

export const getBookingsWithPagination = (
  paginationQuery: string,
  paginationType: string,
  paginationSearch: any,
  paginationSearchType: string,
  paginationSearchRange?: moment.Moment
) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: "SET_BOOKING_LOADING",
      });

      const isSuperAdmin = getState().authStore.user?.role === "SUPER";
      const isHealthAdmin = getState().authStore.user?.role === "HEALTH";
      const bookingsList: BookingWithUserModel[] = [];
      const bookingCollectionQuery = firebase
        .firestore()
        .collection("bookings");

      //INFO : Will not order by id if paginationSearchType === 'range'
      let bookingCollection;
      if (paginationSearchType === "range") {
        bookingCollection = bookingCollectionQuery;
      } else {
        bookingCollection = bookingCollectionQuery.limit(bookingQueryLimit);
      }

      if (isHealthAdmin) {
        bookingCollection = bookingCollection.where("roles", "in", [
          "V",
          "O",
          "C",
        ]);
      } else if (!isSuperAdmin) {
        bookingCollection = bookingCollection.where("roles", "==", "G");
      }

      let bookingQuery;
      if (paginationSearchType === "range") {
        bookingQuery = bookingCollection;
      } else if (paginationQuery) {
        if (paginationType === "asc") {
          bookingQuery = bookingCollection
            .orderBy("id")
            .startAfter(paginationQuery);
        } else {
          bookingQuery = bookingCollection
            .orderBy("id", "desc")
            .startAfter(paginationQuery);
        }
      } else {
        bookingQuery = bookingCollection.orderBy("id");
      }

      if (paginationSearchType === "center") {
        bookingQuery = bookingQuery.where("centerId", "==", paginationSearch);
      } else if (paginationSearchType === "range" && paginationSearchRange) {
        const startWeek = paginationSearchRange.startOf("isoWeek").toDate();
        const endWeek = paginationSearchRange.endOf("isoWeek").toDate();
        bookingQuery = bookingQuery
          .where("centerId", "==", paginationSearch)
          .where("startDate", ">=", startWeek)
          .where("startDate", "<=", endWeek);
      } else {
        bookingQuery = bookingQuery.where("userId", "==", paginationSearch);
      }

      const bookingSnapshot = await bookingQuery.get();
      const userIdList: string[] = [];

      if (bookingSnapshot) {
        bookingSnapshot.forEach((eachDoc) => {
          const selectedBooking = eachDoc.data() as BookingModel;
          bookingsList.push(selectedBooking);

          if (!userIdList.includes(selectedBooking.userId)) {
            userIdList.push(selectedBooking.userId);
          }
        });
      }

      if (userIdList.length > 0) {
        const userList: UserModel[] = [];
        while (userIdList.length > 0) {
          const spliceUserIdList = userIdList.splice(0, 10);
          const userSnapshot = await firebase
            .firestore()
            .collection("users")
            .where("id", "in", spliceUserIdList)
            .get();
          if (userSnapshot) {
            userSnapshot.forEach((eachUser) => {
              const eachUserData = eachUser.data() as UserModel;
              userList.push(eachUserData);
            });
          }
        }

        userList.map((eachUser) => {
          bookingsList.map((eachBooking) => {
            if (eachBooking.userId === eachUser.id) {
              eachBooking["userEmail"] = eachUser.email;
              eachBooking["userState"] = eachUser.address.state;
            }
            return null;
          });
          return null;
        });
      }

      if (paginationType === "desc" && bookingsList.length > 0) {
        bookingsList.reverse();
      }

      let pagination = "";
      if (paginationSearchType === "range") {
        pagination = "";
      } else if (bookingsList.length === bookingQueryLimit) {
        pagination = bookingsList[bookingQueryLimit - 1].id;
      }

      let bookings: BookingWithUserModel[] = [];
      if (bookingsList.length > 0) {
        bookings = bookingsList;
      } else if (paginationSearchType === "range") {
      } else {
        bookings = getState().bookingStore.bookings;
      }

      dispatch({
        type: "UPDATE_BOOKINGS",
        payload: {
          bookings: bookings,
          pagination: pagination,
        },
      });
    } catch (err) {}
  };
};

export const getBooking = async (id: string) => {
  const eachBookRes: EachBookRes = {
    data: null,
    err: "",
  };
  try {
    const bookingQuery = await firebase
      .firestore()
      .collection("bookings")
      .doc(id)
      .get();
    if (bookingQuery.exists) {
      const selectedBooking: BookingModel = bookingQuery.data() as BookingModel;

      const centerQuery = await firebase
        .firestore()
        .collection("centers")
        .doc(selectedBooking.centerId)
        .get();

      const userQuery = await firebase
        .firestore()
        .collection("users")
        .doc(selectedBooking.userId)
        .get();

      if (centerQuery.exists) {
        eachBookRes["data"] = {
          booking: selectedBooking,
          center: centerQuery.data() as CenterModel,
          user: userQuery.data() as UserModel,
        };
      } else {
        eachBookRes["err"] = "Center does not exist";
      }
    } else {
      eachBookRes["err"] = "Booking does not exist";
    }
  } catch (err) {
    eachBookRes["err"] = err.message;
  }
  return eachBookRes;
};

export const updateBookingValidation = async (items: {
  id: string;
  validation?: Validation[];
}) => {
  try {
    const bookingId = items.id;

    if (bookingId) {
      await firebase.firestore().collection("bookings").doc(bookingId).update({
        validation: items.validation,
      });
      return "";
    } else return "Validation is not updated. Unknown Error.";
  } catch (err) {
    return err.message;
  }
};

export const deleteBooking = async (id: string) => {
  let error: string = "";
  try {
    await firebase.firestore().collection("bookings").doc(id).delete();
  } catch (err) {
    error = err.message;
  }
  return error;
};

export const verifyBooking = async (bookingId: string) => {
  try {
    if (bookingId) {
      await firebase.firestore().collection("bookings").doc(bookingId).update({
        medicalVerified: true,
      });
      const bookingQuery = await firebase
        .firestore()
        .collection("bookings")
        .doc(bookingId)
        .get();
      if (bookingQuery.exists) {
        const selectedBooking: BookingModel =
          bookingQuery.data() as BookingModel;
        return selectedBooking;
      }
      return "Booking cannot be found";
    } else return "Validation is not updated. Unknown Error.";
  } catch (err) {
    return err.message;
  }
};

export const handleBookDates = async (
  userId: string,
  centerId: string,
  roles: keyof typeof VolunteerRoles,
  dateSelection: DateSelection[]
) => {
  let bookingResponse: BookRes = {
    success: false,
    err: "",
  };
  if (firebase.auth().currentUser) {
    const centersQuery = await firebase
      .firestore()
      .collection("centers")
      .doc(centerId)
      .get();
    if (centersQuery.exists) {
      const bookingRef = firebase.firestore().collection("bookings").doc();

      const startDate = dateSelection[0].date;
      const endDate = dateSelection[dateSelection.length - 1].date;
      const bookDate: Date[] = [];
      const volunteerShift: VolunteerShiftType[] = [];
      dateSelection.map((eachSelection) => {
        bookDate.push(eachSelection.date);
        volunteerShift.push(eachSelection.shift);
        return null;
      });

      const newBooking: BookingModel = {
        id: bookingRef.id,
        userId: userId,
        centerId: centerId,
        bookDate: bookDate,
        startDate: startDate,
        endDate: endDate,
        shift: volunteerShift,
        roles: roles,
        createdAt: new Date(),
        type: "WEEK",
      };

      await firebase
        .firestore()
        .collection("bookings")
        .doc(newBooking.id)
        .set(newBooking);

      bookingResponse.success = true;
    } else {
      bookingResponse.err = "Center does not exist";
    }
  } else {
    bookingResponse.err = "User is not authenticated";
  }

  return bookingResponse;
};
