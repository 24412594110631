import { ExclamationCircleOutlined } from "@ant-design/icons";
import { DatePicker, Divider, Modal, notification, Spin } from "antd";
import moment from "moment";
import { Component } from "react";
import { connect } from "react-redux";
import Button from "../components/Button";
import Header from "../components/Header";
import TextInput from "../components/TextInput";
import HealthVerificationLinkModel from "../models/HealthVerificationLink";
import SecurityNavigator from "../navigator/SecurityNavigator";
import WindowResizer from "../navigator/WindowResizer";
import {
  createHealthVerificationLink,
  deleteHealthVerificationLink,
  getHealthVerificationLink,
  HealthVerificationLinkRes,
  updateHealthVerificationLink,
} from "../store/actions/healthVerificationLinkActions";
import { sleep } from "../store/actions/utilsActions";
import { authStateInterface } from "../store/reducers/authReducer";
import {
  DeviceType,
  utilsStateInterface,
} from "../store/reducers/utilsReducer";
import {
  FlexibleRowContainer,
  RowContainer,
  VerticalContainer,
} from "../styles/Layout";
import {
  CustomActionTitle,
  Footer as Warning,
  Title3,
} from "../styles/Typography";
import { handleNavigation, handleNotiNavigation } from "../utils/navigator";

interface Props {
  history: any;
  match: any;
  location: any;
  authStore: authStateInterface;
  utilsStore: utilsStateInterface;
}

interface healthVerificationLinkStateError {
  titleError: string;
}

interface State {
  loading: false;
  healthVerificationLink: HealthVerificationLinkModel;
  healthVerificationLinkError: healthVerificationLinkStateError;
}

const { confirm } = Modal;

const dateFormat = "DD/MM/YYYY";

class HealthVerificationLinkEditor extends Component<Props> {
  state: State = {
    loading: false,
    healthVerificationLink: {
      id: "",
      title: "",
      applyDate: new Date(),
    },
    healthVerificationLinkError: {
      titleError: "",
    },
  };

  componentDidMount = () => {
    if (this.getParams()) {
      this.handleGetPreviewData();
    } else {
      this.handleNavigate("healthVerificationLink");
    }
  };

  getParams() {
    const id = this.props.match.params.id;
    return id;
  }

  handleLoading = (value: boolean) => {
    this.setState({
      loading: value,
    });
  };

  handleNavigate = (type: string) => {
    if (type === "healthVerificationLink") {
      handleNavigation(this.props.history, "/healthVerificationLink");
    }
  };

  handleNotification = (type: string, message: string, description: string) => {
    if (type === "success") {
      notification.success({ message: message, description: "" });
    } else if (type === "warning") {
      notification.warning({
        message: message,
        description: description,
      });
    }
  };

  handleGetPreviewData = async () => {
    if (this.getParams() !== "create") {
      this.handleLoading(true);
      const healthVerificationLinkQuery: HealthVerificationLinkRes = await getHealthVerificationLink(
        this.getParams()
      );
      if (healthVerificationLinkQuery.err) {
        this.handleNavigate("healthVerificationLink");
      } else {
        if (healthVerificationLinkQuery.data) {
          const healthVerificationLink: HealthVerificationLinkModel = {
            id: healthVerificationLinkQuery.data?.id,
            applyDate: new Date(
              healthVerificationLinkQuery.data?.applyDate.seconds * 1000
            ),
            title: healthVerificationLinkQuery.data?.title,
          };
          this.setState({
            healthVerificationLink: healthVerificationLink,
          });
        }
        this.handleLoading(false);
      }
    }
  };

  handleCheckCondition = (typeList: string[]) => {
    let healthVerificationLinkCheck = JSON.parse(
      JSON.stringify(this.state.healthVerificationLinkError)
    );

    typeList.map((eachType) => {
      switch (eachType) {
        case "title":
          if (
            this.state.healthVerificationLink.title.replace(/\s/g, "").length <=
            0
          ) {
            healthVerificationLinkCheck["titleError"] = "Title cannot be empty";
          } else {
            healthVerificationLinkCheck["titleError"] = "";
          }
          break;
        default:
          break;
      }
      return null;
    });
    this.setState({
      healthVerificationLinkError: healthVerificationLinkCheck,
    });
  };

  handleDeleteHealthVerificationLink = async () => {
    this.handleLoading(true);
    if (this.state.healthVerificationLink.id) {
      const healthVerificationLinkdelete = await deleteHealthVerificationLink({
        id: this.getParams(),
      });
      if (healthVerificationLinkdelete) {
        this.handleNotification(
          "warning",
          "Health Verification Link Could Not Be Deleted",
          healthVerificationLinkdelete
        );
        this.handleLoading(false);
      } else {
        handleNotiNavigation(this.props.history, 3, "/healthVerificationLink");
      }
    }
  };

  handleEditHealthVerificationLink = async () => {
    this.handleLoading(true);
    this.handleCheckCondition(["title", "applyDate"]);
    await sleep(250);
    let conditionPassed: boolean = false;
    conditionPassed = !this.state.healthVerificationLinkError.titleError
      ? true
      : false;
    if (conditionPassed) {
      const healthVerificationLinkUpdate = await updateHealthVerificationLink({
        id: this.state.healthVerificationLink.id,
        title: this.state.healthVerificationLink.title,
        applyDate: this.state.healthVerificationLink.applyDate,
      });
      if (healthVerificationLinkUpdate) {
        this.handleNotification(
          "warning",
          "Health Verification Link Could Not Be Updated",
          healthVerificationLinkUpdate
        );
        this.handleLoading(false);
      } else {
        handleNotiNavigation(this.props.history, 2, "/healthVerificationLink");
      }
    } else {
      this.handleNotification(
        "warning",
        "Health Verification Link Could Not Be Updated",
        ""
      );
      this.handleLoading(false);
    }
  };

  handleCreateHealthVerificationLink = async () => {
    this.handleLoading(true);
    this.handleCheckCondition(["title", "applyDate"]);
    await sleep(250);
    let conditionPassed: boolean = false;
    conditionPassed = !this.state.healthVerificationLinkError.titleError
      ? true
      : false;
    if (conditionPassed) {
      const healthVerificationLinkCreation = await createHealthVerificationLink(
        {
          title: this.state.healthVerificationLink.title,
          applyDate: this.state.healthVerificationLink.applyDate,
        }
      );
      if (healthVerificationLinkCreation) {
        this.handleNotification(
          "warning",
          "Health Verification Link Could Not Be Added",
          healthVerificationLinkCreation
        );
        this.handleLoading(false);
      } else {
        handleNotiNavigation(this.props.history, 1, "/healthVerificationLink");
      }
    } else {
      this.handleNotification(
        "warning",
        "Health Verification Link Could Not Be Added",
        ""
      );
      this.handleLoading(false);
    }
  };

  handleDeleteConfirmation() {
    confirm({
      title: "Are you sure want to delete this Health Verification link?",
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        this.handleDeleteHealthVerificationLink();
      },
      onCancel() {},
    });
  }

  handleDateChange = (date: any) => {
    let healthVerificationLink = JSON.parse(
      JSON.stringify(this.state.healthVerificationLink)
    );
    healthVerificationLink["applyDate"] = date.toDate();
    this.handleVerificationLink(healthVerificationLink);
  };

  handleChange = (e: any) => {
    let healthVerificationLink = JSON.parse(
      JSON.stringify(this.state.healthVerificationLink)
    );
    let applyDate;
    if (this.state.healthVerificationLink.applyDate.seconds * 1000) {
      applyDate = this.state.healthVerificationLink.applyDate;
    } else {
      applyDate = new Date(this.state.healthVerificationLink.applyDate);
    }
    healthVerificationLink["title"] = e.target.value;
    healthVerificationLink["applyDate"] = applyDate;
    this.handleVerificationLink(healthVerificationLink);
  };

  handleVerificationLink = (
    healthVerificationLink: HealthVerificationLinkModel
  ) => {
    this.setState({ healthVerificationLink });
  };

  renderButtons = () => {
    let buttonWidth = 150;
    let marginTop = 0;
    if (this.props.utilsStore.deviceType === DeviceType.Mobile) {
      buttonWidth = 85;
      marginTop = 10;
    }

    if (this.getParams() !== "create" && this.getParams()) {
      return (
        <RowContainer style={{ marginTop: marginTop }}>
          <Button
            text="Delete"
            width={buttonWidth}
            margin={"0px 10px"}
            onClick={this.handleDeleteConfirmation.bind(this)}
            small={true}
          />
          <Button
            text="Update"
            width={buttonWidth}
            margin={"0px 10px"}
            onClick={this.handleEditHealthVerificationLink}
            small={true}
          />
        </RowContainer>
      );
    } else {
      return (
        <>
          <div style={{ marginTop: 5 }} />
          <Button
            text="Add"
            width={buttonWidth}
            margin={"0px 10px"}
            onClick={this.handleCreateHealthVerificationLink}
            small={true}
          />
        </>
      );
    }
  };

  render() {
    if (this.props.authStore.userLoading) {
      return null;
    }
    return (
      <Spin
        spinning={this.state.loading}
        size="large"
        style={{
          width: "100vw",
          height: "100vh",
          maxHeight: "none",
        }}
      >
        <Header history={this.props.history} location={this.props.location} />
        <WindowResizer />
        <SecurityNavigator location={this.props.location} role="SUPER" />
        <VerticalContainer
          style={{
            width: "100%",
          }}
        >
          <VerticalContainer
            style={{
              alignItems: "flex-start",
              width: "90%",
              height: "100%",
              maxWidth: 1200,
              padding: "40px 0px",
            }}
          >
            <FlexibleRowContainer
              style={{
                width: "100%",
                alignItems: "flex-start",
              }}
            >
              <RowContainer>
                <CustomActionTitle
                  onClick={this.handleNavigate.bind(
                    this,
                    "healthVerificationLink"
                  )}
                  title="Health Verification Link"
                >
                  Health Verification Link
                </CustomActionTitle>
                <Title3 style={{ margin: "0px 10px" }}>/</Title3>
                <Title3 style={{ marginBottom: 0 }}>
                  {this.props.match.params.id === "create"
                    ? "New"
                    : this.state.healthVerificationLink.title}
                </Title3>
              </RowContainer>
              <div style={{ flex: 1 }} />
              {this.renderButtons()}
            </FlexibleRowContainer>
            <Divider style={{ marginTop: 10, marginBottom: 30 }} />
            <RowContainer style={{ width: "100%", alignItems: "flex-start" }}>
              <VerticalContainer
                style={{ width: "48%", alignItems: "flex-start" }}
              >
                <Title3 style={{ marginBottom: 5 }}>Title</Title3>
                <TextInput
                  id="title"
                  value={this.state.healthVerificationLink.title}
                  width={"100%"}
                  placeholder={"Title"}
                  onChange={this.handleChange}
                  maxLength={60}
                />
                {this.state.healthVerificationLinkError.titleError && (
                  <Warning style={{ marginBottom: 0 }}>
                    {this.state.healthVerificationLinkError.titleError}
                  </Warning>
                )}
              </VerticalContainer>
              <div style={{ flexGrow: 1 }} />
              <VerticalContainer
                style={{ width: "48%", alignItems: "flex-start" }}
              >
                <Title3 style={{ marginBottom: 5 }}>Apply Date</Title3>
                <DatePicker
                  id="applyDate"
                  style={{
                    width: "100%",
                  }}
                  allowClear={false}
                  value={moment(this.state.healthVerificationLink.applyDate)}
                  format={dateFormat}
                  onChange={this.handleDateChange}
                />
              </VerticalContainer>
            </RowContainer>
            <RowContainer
              style={{
                width: "100%",
                justifyContent: "flex-start",
                marginTop: 20,
              }}
            >
              <VerticalContainer
                style={{ width: "48%", alignItems: "flex-start" }}
              >
                <Title3 style={{ marginBottom: 5 }}>Verification Link</Title3>
                <TextInput
                  value={`https://myvac.com.my/attendance/${this.state.healthVerificationLink.id}`}
                  width={"100%"}
                  placeholder={"Link"}
                  maxLength={60}
                  disabled={true}
                />
              </VerticalContainer>
            </RowContainer>
          </VerticalContainer>
        </VerticalContainer>
      </Spin>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(HealthVerificationLinkEditor);
