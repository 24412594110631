import NgoModel from "../../models/Ngo";
import firebase from "../../utils/firebase";

export interface NgoRes {
  err: string;
  data: NgoModel | null;
}

export interface AllNgoRes {
  err: string;
  data: NgoModel[] | null;
}

export const ngoQueryLimit = 10;

export const getNgoWithPagination = (
  paginationQuery: string,
  paginationType: string,
  paginationSearch: string
) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: "SET_NGO_LOADING",
      });

      const ngoList: NgoModel[] = [];

      const ngoCollectionQuery = firebase.firestore().collection("ngoList");

      let ngoCollection;
      ngoCollection = ngoCollectionQuery;

      let ngoQuery;

      if (!paginationSearch) {
        ngoCollection = ngoCollection.limit(ngoQueryLimit);
        if (paginationQuery) {
          if (paginationType === "asc") {
            ngoQuery = ngoCollection
              .orderBy("shorthand")
              .startAfter(paginationQuery);
          } else {
            ngoQuery = ngoCollection
              .orderBy("shorthand", "desc")
              .startAfter(paginationQuery);
          }
        } else {
          ngoQuery = ngoCollection.orderBy("shorthand");
        }
      } else {
        ngoQuery = ngoCollection
          .orderBy("name")
          .startAt(paginationSearch)
          .endAt(paginationSearch + "\uf8ff");
      }

      const ngoSnapshot = await ngoQuery.get();

      if (ngoSnapshot) {
        ngoSnapshot.forEach((eachDoc) => {
          ngoList.push(eachDoc.data() as NgoModel);
        });
      }

      if (paginationType === "desc" && ngoList.length > 0) {
        ngoList.reverse();
      }

      let pagination = "";
      if (ngoList.length === ngoQueryLimit && !paginationSearch) {
        pagination = ngoList[ngoQueryLimit - 1].shorthand;
      }

      let ngo = [];
      if (ngoList.length > 0) {
        ngo = ngoList;
      } else if (!paginationSearch) {
        ngo = getState().ngoStore.ngo;
      }

      dispatch({
        type: "UPDATE_NGO",
        payload: {
          ngo: ngo,
          pagination: pagination,
        },
      });
    } catch (err) {}
  };
};

export const getAllNgo = async () => {
  let allNgoRes: AllNgoRes = {
    err: "",
    data: [],
  };

  try {
    const ngoSnapshot = await firebase.firestore().collection("ngoList").get();
    const ngoList: NgoModel[] = [];
    ngoSnapshot.forEach((ngo) => {
      ngoList.push(ngo.data() as NgoModel);
    });

    if (ngoList) {
      allNgoRes.data = ngoList;
    } else {
      allNgoRes.err = "Cannot be found";
    }
  } catch (err) {
    allNgoRes.err = err.message;
  }

  return allNgoRes;
};

export const getNgo = async (id: string) => {
  let ngoRes: NgoRes = {
    err: "",
    data: null,
  };

  try {
    const ngoQuery = await firebase
      .firestore()
      .collection("ngoList")
      .doc(id)
      .get();
    if (ngoQuery.exists) {
      const ngoData: NgoModel = ngoQuery.data() as NgoModel;
      ngoRes.data = ngoData;
    } else {
      ngoRes.err = "Cannot be found";
    }
  } catch (err) {
    ngoRes.err = err.message;
  }

  return ngoRes;
};

export const createNgo = async (items: { name: string; shorthand: string }) => {
  try {
    const ngoRef = firebase.firestore().collection("ngoList").doc();
    if (ngoRef.id) {
      const ngoModel: NgoModel = {
        id: ngoRef.id,
        shorthand: items.shorthand,
        name: items.name,
      };
      await firebase
        .firestore()
        .collection("ngoList")
        .doc(ngoRef.id)
        .set(ngoModel);
      return "";
    } else return "NGO is not created. Unknown Error.";
  } catch (err) {
    return err.message;
  }
};

export const updateNgo = async (items: {
  id: string;
  shorthand: string;
  name: string;
}) => {
  try {
    const ngoRef = items.id;
    if (ngoRef) {
      await firebase.firestore().collection("ngoList").doc(ngoRef).update({
        shorthand: items.shorthand,
        name: items.name,
      });
      return "";
    } else return "NGO is not updated. Unknown Error.";
  } catch (err) {
    return err.message;
  }
};

export const deleteNgo = async (items: { id: string }) => {
  try {
    const ngoRef = items.id;
    if (ngoRef) {
      await firebase.firestore().collection("ngoList").doc(ngoRef).delete();
      return "";
    } else return "NGO is not deleted. Unknown Error.";
  } catch (err) {
    return err.message;
  }
};
