import moment from "moment";
import CenterAnalytics from "../../models/CenterAnalytics";
import { PaymentAnalytics } from "../../models/PaymentAnalytics";
import { SpreadsheetAnalytics } from "../../models/SpreadsheetAnalytics";
import { StateAnalytics } from "../../models/StateAnalytics";
import { StateAnalyticsGroup } from "../../models/StateAnalyticsGroup";
import firebase from "../../utils/firebase";

export const getStateAnalytics = () => {
  return async (dispatch: any, getState: any) => {
    try {
      const previousDate = moment().subtract(1, "days").startOf("day");

      const analyticsSnapshot = await firebase
        .firestore()
        .collection("stateAnalytics")
        .orderBy("analyticalDate", "desc")
        .limit(1)
        .get();

      const previousAnalyticSnapshot = await firebase
        .firestore()
        .collection("stateAnalytics")
        .where("analyticalDate", ">", previousDate.toDate())
        .orderBy("analyticalDate")
        .limit(1)
        .get();

      let eachStateAnalytics = null;
      if (analyticsSnapshot) {
        analyticsSnapshot.forEach((eachAnalytics) => {
          eachStateAnalytics = eachAnalytics.data() as StateAnalytics;
        });
      }

      let previousEachSateAnalysis = null;
      if (previousAnalyticSnapshot) {
        previousAnalyticSnapshot.forEach((eachAnalytics) => {
          previousEachSateAnalysis = eachAnalytics.data() as StateAnalytics;
        });
      }

      dispatch({
        type: "UPDATE_STATE_ANALYTICS",
        payload: {
          stateAnalytics: eachStateAnalytics,
          previousStateAnalytics: previousEachSateAnalysis,
        },
      });
    } catch (err) {
      dispatch({
        type: "UPDATE_STATE_ANALYTICS",
        payload: {
          stateAnalytics: null,
          previousStateAnalytics: null,
        },
      });
    }
  };
};

export const getStateAnalyticsGroup = () => {
  return async (dispatch: any, getState: any) => {
    try {
      const stateAnalyticsGroupQuery = await firebase
        .firestore()
        .collection("stateAnalyticsGroup")
        .doc("group")
        .get();

      const stateAnalyticsList: StateAnalytics[] = [];

      if (stateAnalyticsGroupQuery.exists) {
        const stateAnalyticsGroup =
          stateAnalyticsGroupQuery.data() as StateAnalyticsGroup;

        if (stateAnalyticsGroup.analyticalDates.length > 0) {
          await Promise.all(
            stateAnalyticsGroup.analyticalDates.map(
              async (eachStateAnalytics) => {
                const analyticSnapshot = await firebase
                  .firestore()
                  .collection("stateAnalytics")
                  .where(
                    "dateString",
                    "==",
                    moment(eachStateAnalytics.seconds * 1000).format(
                      "DMMMMYYYY"
                    )
                  )
                  .orderBy("analyticalDate", "desc")
                  .limit(1)
                  .get();

                let currentStateAnalytics: StateAnalytics | null = null;
                if (analyticSnapshot) {
                  analyticSnapshot.forEach((eachAnalytics) => {
                    currentStateAnalytics =
                      eachAnalytics.data() as StateAnalytics;
                  });
                  if (currentStateAnalytics) {
                    stateAnalyticsList.push(currentStateAnalytics);
                  }
                }
              }
            )
          );
        }
      }

      dispatch({
        type: "UPDATE_STATE_ANALYTICS_GROUP",
        payload: {
          stateAnalyticsGroup: stateAnalyticsList,
        },
      });
    } catch (err) {
      dispatch({
        type: "UPDATE_STATE_ANALYTICS_GROUP",
        payload: {
          stateAnalyticsGroup: [],
        },
      });
    }
  };
};

export const getSpreadsheetAnalytics = () => {
  return async (dispatch: any, getState: any) => {
    try {
      const analyticQuery = firebase
        .firestore()
        .collection("spreadsheetAnalytics")
        .orderBy("analyticalDate", "desc")
        .limit(1);
      const analyticsSnapshot = await analyticQuery.get();

      if (analyticsSnapshot) {
        analyticsSnapshot.forEach((eachAnalytics) => {
          const eachSpreadsheetAnalytics =
            eachAnalytics.data() as SpreadsheetAnalytics;
          dispatch({
            type: "UPDATE_SPREADSHEET_ANALYTICS",
            payload: {
              spreadsheetAnalytics: eachSpreadsheetAnalytics,
            },
          });
        });
      } else {
        dispatch({
          type: "UPDATE_SPREADSHEET_ANALYTICS",
          payload: {
            spreadsheetAnalytics: null,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "UPDATE_SPREADSHEET_ANALYTICS",
        payload: {
          spreadsheetAnalytics: null,
        },
      });
    }
  };
};

export const getCenterAnalytics = () => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: "UPDATE_CENTER_ANALYTICS",
        payload: {
          centerAnalytics: null,
        },
      });
      const analyticQuery = firebase
        .firestore()
        .collection("centerAnalytics")
        .orderBy("analyticalDate", "desc")
        .limit(1);
      const analyticsSnapshot = await analyticQuery.get();

      if (analyticsSnapshot) {
        analyticsSnapshot.forEach((eachAnalytics) => {
          const eachCenterAnalytics = eachAnalytics.data() as CenterAnalytics;
          dispatch({
            type: "UPDATE_CENTER_ANALYTICS",
            payload: {
              centerAnalytics: eachCenterAnalytics,
            },
          });
        });
      } else {
        dispatch({
          type: "UPDATE_CENTER_ANALYTICS",
          payload: {
            centerAnalytics: null,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "UPDATE_CENTER_ANALYTICS",
        payload: {
          centerAnalytics: null,
        },
      });
    }
  };
};

export const getPaymentAnalytics = () => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: "UPDATE_PAYMENT_ANALYTICS",
        payload: {
          paymentAnalytics: null,
        },
      });
      const analyticQuery = firebase
        .firestore()
        .collection("paymentAnalytics")
        .orderBy("createdAt", "desc")
        .limit(1);
      const analyticsSnapshot = await analyticQuery.get();

      if (analyticsSnapshot) {
        analyticsSnapshot.forEach((eachAnalytics) => {
          const eachPaymentAnalytics = eachAnalytics.data() as PaymentAnalytics;
          dispatch({
            type: "UPDATE_PAYMENT_ANALYTICS",
            payload: {
              paymentAnalytics: eachPaymentAnalytics,
            },
          });
        });
      } else {
        dispatch({
          type: "UPDATE_PAYMENT_ANALYTICS",
          payload: {
            paymentAnalytics: null,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "UPDATE_PAYMENT_ANALYTICS",
        payload: {
          paymentAnalytics: null,
        },
      });
    }
  };
};
