import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Divider, notification, Table, Tag } from "antd";
import { Component } from "react";
import { connect } from "react-redux";
import Button from "../components/Button";
import Header from "../components/Header";
import TextInput from "../components/TextInput";
import { AdminRole } from "../models/Admin";
import SecurityNavigator from "../navigator/SecurityNavigator";
import WindowResizer from "../navigator/WindowResizer";
import { getAllCenters } from "../store/actions/centerActions";
import { getAdminWithPagination } from "../store/actions/roleActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { centerStateInterface } from "../store/reducers/centerReducer";
import { roleStateInterface } from "../store/reducers/roleReducer";
import {
  DeviceType,
  utilsStateInterface,
} from "../store/reducers/utilsReducer";
import { COLOR } from "../styles/Colors";
import {
  FlexibleRowContainer,
  RowContainer,
  VerticalContainer,
} from "../styles/Layout";
import { Body, Title3 } from "../styles/Typography";
import { handleNavigation } from "../utils/navigator";

interface Props {
  location: any;
  history: any;
  authStore: authStateInterface;
  adminStore: roleStateInterface;
  centerStore: centerStateInterface;
  utilsStore: utilsStateInterface;
  getAdminWithPagination: (
    pagination: string,
    type: string,
    search: string
  ) => void;
  getAllCenters: (state: string) => void;
}

interface State {
  searchText: string;
  pageIndex: number;
}

const buttonDisableStyle = (value: boolean) => ({
  cursor: value ? "auto" : "pointer",
  color: value ? COLOR.GREY : COLOR.RED,
});

class Roles extends Component<Props> {
  searchTimeout: ReturnType<typeof setTimeout> | null = null;

  state: State = {
    searchText: "",
    pageIndex: 1,
  };

  columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a: any, b: any) => a.email.localeCompare(b.email),
    },
    {
      title: "Role",
      dataIndex: "role",
      sorter: (a: any, b: any) => a.role.localeCompare(b.role),
    },
    {
      title: "State",
      dataIndex: "state",
      sorter: (a: any, b: any) => a.state.localeCompare(b.state),
    },
    {
      title: "Center In-Charged",
      dataIndex: "center_incharged",
      render: (center_incharged: any) => (
        <>
          {center_incharged &&
            center_incharged.map((center: any) => {
              return this.props.centerStore.centers.map((eachCenter, index) => {
                if (eachCenter.id === center) {
                  return (
                    <Tag color={COLOR.BLUE} key={index}>
                      {eachCenter.centerName}
                    </Tag>
                  );
                } else {
                  return null;
                }
              });
            })}
        </>
      ),
    },
  ];

  componentDidMount() {
    if (
      this.props.authStore.user &&
      this.props.authStore.user.role === "SUPER"
    ) {
      this.handleGetPreviewData();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      JSON.stringify(prevProps.authStore.user) !==
        JSON.stringify(this.props.authStore.user) &&
      this.props.authStore.user &&
      this.props.authStore.user.role === "SUPER"
    ) {
      this.handleGetPreviewData();
    }
  }

  handleGetPreviewData = async () => {
    this.props.getAdminWithPagination("", "asc", this.state.searchText);
    this.props.getAllCenters("");
  };

  handleFilter = (e: any) => {
    this.setState(
      {
        searchText: e.target.value,
      },
      () => {
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(() => {
          if (this.state.searchText.length === 0) {
            this.handleGetPreviewData();
            this.setState({ pageIndex: 1 });
          } else if (this.state.searchText.length <= 2) {
            notification.warning({
              message: "Please input at least 3 characters",
            });
          } else {
            this.handleGetPreviewData();
            this.setState({ pageIndex: 1 });
          }
        }, 500);
      }
    );
  };

  renderFilteredData = () => {
    let listView: any = [];

    if (this.props.adminStore.users.length > 0) {
      this.props.adminStore.users.map((eachAdmin) =>
        listView.push({
          key: eachAdmin.id,
          name: eachAdmin.name,
          email: eachAdmin.email,
          role: Object(AdminRole)[eachAdmin.role],
          state: eachAdmin.state ?? "",
          center_incharged: eachAdmin.center,
        })
      );
    }

    return listView;
  };

  handlePagination = (value: string) => {
    const pageIndex = this.state.pageIndex;
    if (value === "prev" && pageIndex > 1) {
      this.props.getAdminWithPagination(
        this.props.adminStore.users[0].email,
        "desc",
        this.state.searchText
      );
      this.setState({
        pageIndex: pageIndex - 1,
      });
    } else if (value === "next") {
      this.props.getAdminWithPagination(
        this.props.adminStore.pagination,
        "asc",
        this.state.searchText
      );
      this.setState({
        pageIndex: pageIndex + 1,
      });
    }
  };

  handleOnClickRow = (e: any) => {
    return {
      onClick: () => {
        handleNavigation(this.props.history, "/editRole/" + e.key);
      },
    };
  };

  handleNavigate = (type: string, id: string) => {
    if (type === "roles") {
      handleNavigation(this.props.history, "/editRole/" + id);
    }
  };

  render() {
    return (
      <>
        <Header history={this.props.history} location={this.props.location} />
        <WindowResizer />
        <SecurityNavigator location={this.props.location} role="SUPER" />
        <VerticalContainer
          style={{
            width: "100%",
          }}
        >
          <VerticalContainer
            style={{
              alignItems: "flex-start",
              width: "90%",
              height: "100%",
              maxWidth: 1200,
              padding: "40px 0px",
            }}
          >
            <FlexibleRowContainer
              style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <Title3 style={{ marginBottom: 0, marginRight: 10 }}>
                Admin Center Roles
              </Title3>
              <div style={{ flex: 1, marginTop: 5 }} />
              <TextInput
                placeholder="Filter: Email"
                value={this.state.searchText}
                width={
                  this.props.utilsStore.deviceType === DeviceType.Mobile
                    ? 280
                    : 400
                }
                onChange={this.handleFilter}
              />
              <div style={{ flex: 1, marginTop: 10 }} />
              <Button
                text="Add"
                width={150}
                onClick={this.handleNavigate.bind(this, "roles", "add")}
                small={true}
              />
            </FlexibleRowContainer>
            <Divider
              style={{
                marginTop: 10,
                marginBottom: 20,
              }}
            />

            <Table
              style={{
                width: "100%",
                maxWidth: "100%",
                cursor: "pointer",
                overflowX: "auto",
              }}
              pagination={false}
              columns={this.columns}
              dataSource={this.renderFilteredData()}
              onRow={this.handleOnClickRow}
              loading={this.props.adminStore.loading}
            />
            <RowContainer
              style={{
                width: "100%",
                justifyContent: "flex-end",
                marginTop: 30,
              }}
            >
              {!this.props.adminStore.loading && (
                <>
                  <LeftOutlined
                    style={buttonDisableStyle(
                      this.state.pageIndex === 1 ? true : false
                    )}
                    onClick={this.handlePagination.bind(this, "prev")}
                  />
                  <Body
                    style={{ marginBottom: 0, marginLeft: 15, marginRight: 15 }}
                  >
                    {`${this.state.pageIndex} / -`}
                  </Body>
                  <RightOutlined
                    onClick={
                      this.props.adminStore.pagination
                        ? this.handlePagination.bind(this, "next")
                        : () => {}
                    }
                    style={buttonDisableStyle(
                      this.props.adminStore.pagination ? false : true
                    )}
                  />
                </>
              )}
            </RowContainer>
          </VerticalContainer>
        </VerticalContainer>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    adminStore: state.adminStore,
    centerStore: state.centerStore,
    utilsStore: state.utilsStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAdminWithPagination: (
      pagination: string,
      type: string,
      search: string
    ) => dispatch(getAdminWithPagination(pagination, type, search)),
    getAllCenters: (state: string) => dispatch(getAllCenters(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
