import styled from "styled-components";
import { DeviceSize } from "../store/reducers/utilsReducer";
import { COLOR } from "./Colors";

export const Title1 = styled.h1`
  font-family: dm-sans;
  font-weight: bold;
  font-size: 46px;
  color: ${COLOR.BLACK};

  @media only screen and (max-width: ${DeviceSize.minLaptopBreakpoints - 1}px) {
    font-size: 32px;
  }
`;

export const Title2 = styled.h2`
  font-family: dm-sans;
  font-weight: bold;
  font-size: 24px;
  color: ${COLOR.BLACK};
`;

export const Title3 = styled.h3`
  font-family: dm-sans;
  font-weight: bold;
  font-size: 20px;
  color: ${COLOR.BLACK};
`;

export const Body = styled.p`
  font-family: dm-sans;
  font-weight: 500;
  font-size: 14px;
  color: ${COLOR.GREY};
`;

export const List = styled.div`
  ul li {
    font-family: dm-sans;
    font-weight: 500;
    font-size: 14px;
    color: ${COLOR.GREY};
    padding: 4px 0px;
  }
`;

export const Footer = styled.p`
  font-family: dm-sans;
  font-weight: 500;
  font-size: 12px;
  color: ${COLOR.RED};
`;

export const BaseActionText = styled.a`
  font-family: dm-sans;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  color: ${COLOR.WHITE};

  &:hover {
    color: ${COLOR.WHITE};
  }

  @media only screen and (max-width: ${DeviceSize.minLaptopBreakpoints - 1}px) {
    font-size: 14px;
  }
`;

export const CustomActionTitle = styled(Title3)`
  color: ${COLOR.RED};
  cursor: pointer;
  margin-bottom: 0px;

  &:hover {
    color: ${COLOR.OPAQUERED} !important;
  }
`;

export const InvertedSmallActionText = styled.a`
  font-family: dm-sans;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  color: ${COLOR.RED};

  &:hover {
    color: ${COLOR.OPAQUERED} !important;
  }
`;

interface ActionTextProps {
  navigation?: boolean;
}

export const ActionText = styled(BaseActionText)<ActionTextProps>`
  color: ${COLOR.BLACK} !important;

  @media only screen and (max-width: ${DeviceSize.minLaptopBreakpoints - 1}px) {
    color: ${(props) =>
      props.navigation ? COLOR.RED : COLOR.BLACK} !important;
  }

  &:hover {
    color: ${COLOR.OPAQUERED} !important;
  }
`;

export const InvertedActionText = styled(BaseActionText)`
  color: ${COLOR.RED} !important;

  &:hover {
    color: ${COLOR.OPAQUERED} !important;
  }
`;
