import {
  GenderType,
  ProfessionHealthType,
  User as UserModel,
} from "../../models/User";
import firebase from "../../utils/firebase";

export interface UserRes {
  err: string;
  data: UserModel | null;
}

export const userQueryLimit = 10;

export const getSuggestedUsers = (paginationSearch: string) => {
  return async (dispatch: any, getState: any) => {
    try {
      const usersList: UserModel[] = [];
      const userCollectionQuery = firebase.firestore().collection("users");

      let userQuery;
      userQuery = userCollectionQuery
        .orderBy("email")
        .startAt(paginationSearch)
        .endAt(paginationSearch + "\uf8ff")
        .limit(10);

      const userSnapshot = await userQuery.get();
      if (userSnapshot) {
        userSnapshot.forEach((eachDoc) => {
          usersList.push(eachDoc.data() as UserModel);
        });
      }

      dispatch({
        type: "UPDATE_SUGGESTED_USER",
        payload: {
          suggestedUsers: usersList,
        },
      });
    } catch (err) {}
  };
};

export const getUsersWithPagination = (
  paginationQuery: string,
  paginationType: string,
  paginationSearch: string,
  paginationDistrict: string
) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: "SET_USER_LOADING",
      });

      const isSuperAdmin = getState().authStore.user?.role === "SUPER";
      const isHealthAdmin = getState().authStore.user?.role === "HEALTH";
      const isNgoAdmin = getState().authStore.user?.role === "NGO";
      const usersList: UserModel[] = [];
      const userCollectionQuery = firebase.firestore().collection("users");
      let userCollection;

      if (isSuperAdmin) {
        userCollection = userCollectionQuery;
      } else if (isHealthAdmin) {
        userCollection = userCollectionQuery.where(
          "profession",
          "==",
          "HEALTH"
        );
      } else if (isNgoAdmin) {
        userCollection = userCollectionQuery.where(
          "organisationName",
          "==",
          getState().authStore.user?.ngoName ?? "invalid"
        );
      } else {
        userCollection = userCollectionQuery
          .where("profession", "in", ["STUDENT", "NGO", "OTHERS"])
          .where(
            "address.state",
            "==",
            getState().authStore.user?.state ?? "Selangor"
          );
      }

      let userQuery;

      if (!paginationSearch) {
        userCollection = userCollection.limit(userQueryLimit);
        if (paginationQuery) {
          if (paginationType === "asc") {
            userQuery = userCollection
              .orderBy("email")
              .startAfter(paginationQuery);
          } else {
            userQuery = userCollection
              .orderBy("email", "desc")
              .startAfter(paginationQuery);
          }
        } else {
          userQuery = userCollection.orderBy("email");
        }
        if (paginationDistrict) {
          userQuery = userQuery.where(
            "address.district",
            "==",
            paginationDistrict
          );
        }
      } else {
        userQuery = userCollection
          .orderBy("email")
          .startAt(paginationSearch)
          .endAt(paginationSearch + "\uf8ff");
      }

      const userSnapshot = await userQuery.get();

      if (userSnapshot) {
        userSnapshot.forEach((eachDoc) => {
          usersList.push(eachDoc.data() as UserModel);
        });
      }

      if (paginationType === "desc" && usersList.length > 0) {
        usersList.reverse();
      }

      let pagination = "";
      if (usersList.length === userQueryLimit && !paginationSearch) {
        pagination = usersList[userQueryLimit - 1].email;
      }

      let users = [];
      if (usersList.length > 0) {
        users = usersList;
      } else if (!paginationSearch) {
        users = getState().userStore.users;
      }

      dispatch({
        type: "UPDATE_USERS",
        payload: {
          users: users,
          pagination: pagination,
        },
      });
    } catch (err) {}
  };
};

export const getUser = async (id: string) => {
  let userRes: UserRes = {
    err: "",
    data: null,
  };

  try {
    const userQuery = await firebase
      .firestore()
      .collection("users")
      .doc(id)
      .get();
    if (userQuery.exists) {
      const userData: UserModel = userQuery.data() as UserModel;
      userRes.data = userData;
    } else {
      userRes.err = "Cannot be found";
    }
  } catch (err) {
    userRes.err = err.message;
  }

  return userRes;
};

export const updateHealthUser = async (items: {
  id: string;
  professionHealthType: keyof typeof ProfessionHealthType;
  verifiedHealthID: boolean;
  ic: string;
  name: string;
  gender: keyof typeof GenderType;
  blacklisted: boolean;
}) => {
  let error = "";
  if (items.id) {
    try {
      const userUpdate = {
        professionHealthType: items.professionHealthType,
        verifiedHealthID: items.verifiedHealthID,
        ic: items.ic,
        name: items.name,
        gender: items.gender,
        blacklisted: items.blacklisted,
      };
      await firebase
        .firestore()
        .collection("users")
        .doc(items.id)
        .update(userUpdate);
    } catch (err) {
      error = err.message;
    }
  }

  return error;
};

export const updateGeneralUser = async (items: {
  id: string;
  ic: string;
  name: string;
  gender: keyof typeof GenderType;
  blacklisted: boolean;
}) => {
  let error = "";
  if (items.id) {
    try {
      const userUpdate = {
        ic: items.ic,
        name: items.name,
        gender: items.gender,
        blacklisted: items.blacklisted,
      };
      await firebase
        .firestore()
        .collection("users")
        .doc(items.id)
        .update(userUpdate);
    } catch (err) {
      error = err.message;
    }
  }

  return error;
};
