import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Col, Divider, Modal, notification, Row, Spin } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Button from "../components/Button";
import Header from "../components/Header";
import {
  BookingModel,
  Validation,
  ValidationResult,
  VolunteerRoles,
  VolunteerShift,
} from "../models/Booking";
import CenterModel, { CenterType } from "../models/Center";
import {
  BankType,
  GenderType,
  ProfessionType,
  RaceType,
  User as UserModel,
} from "../models/User";
import SecurityNavigator from "../navigator/SecurityNavigator";
import WindowResizer from "../navigator/WindowResizer";
import {
  deleteBooking,
  EachBookRes,
  getBooking,
  updateBookingValidation,
  verifyBooking,
} from "../store/actions/bookingActions";
import { authStateInterface } from "../store/reducers/authReducer";
import {
  DeviceType,
  utilsStateInterface,
} from "../store/reducers/utilsReducer";
import { COLOR } from "../styles/Colors";
import {
  FlexibleRowContainer,
  RowContainer,
  VerticalContainer,
} from "../styles/Layout";
import {
  Body,
  CustomActionTitle,
  List,
  Title2,
  Title3,
} from "../styles/Typography";
import { getDistrictList, getStateList } from "../utils/districtList";
import { handleNavigation, handleNotiNavigation } from "../utils/navigator";

interface Props {
  history: any;
  match: any;
  utilsStore: utilsStateInterface;
  authStore: authStateInterface;
  location: any;
}

interface State {
  loading: boolean;
  center: CenterModel;
  booking: BookingModel;
  user: UserModel;
}

const { confirm, error } = Modal;

class BookingEditor extends Component<Props> {
  state: State = {
    loading: false,
    center: {
      id: "",
      centerName: "",
      centerAddress: {
        street: "",
        district: "",
        poscode: "",
        state: "",
      },
      centerEmail: "",
      centerContactNo: "",
      imageToken: "",
      centerType: "Type 1" as keyof typeof CenterType,
      doses: 0,
      volHealth: 0,
      volGeneral: 0,
      description: "",
      personnelNeeded: [],
    },
    booking: {
      id: "",
      userId: "",
      centerId: "",
      bookDate: [],
      startDate: new Date(),
      endDate: new Date(),
      shift: [],
      roles: "G",
      type: "WEEK",
      createdAt: new Date(),
      validation: [],
    },
    user: {
      id: "",
      name: "",
      address: {
        street: "",
        poscode: "",
        state: getStateList()[0],
        district: getDistrictList("")[0],
      },
      ic: "",
      age: 0,
      gender: Object.keys(GenderType)[0] as keyof typeof GenderType,
      race: Object.keys(RaceType)[0] as keyof typeof RaceType,
      raceOthers: "",
      email: "",
      mobileNo: "",
      profession: Object.keys(ProfessionType)[0] as keyof typeof ProfessionType,
      professionOthers: "",
      professionHealthId: "",
      organisationName: "",
      organisationAddress: "",
      bankName: "" as keyof typeof BankType,
      bankAccountName: "",
      bankAccountNo: "",
      imageToken: "",
      createdAt: moment().toDate(),
    },
  };

  componentDidMount() {
    if (this.props.authStore.user) {
      this.handleGetPreviewData();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.authStore.user !== prevProps.authStore.user &&
      this.props.authStore.user
    ) {
      this.handleGetPreviewData();
    }
  }

  handleNavigate = (type: string) => {
    if (type === "bookings") {
      handleNavigation(this.props.history, "/bookings");
    }
  };

  handleGetParams() {
    const id = this.props.match.params.id;
    return id;
  }

  handleLoading = (value: boolean) => {
    this.setState({
      loading: value,
    });
  };

  handleGetPreviewData = async () => {
    this.handleLoading(true);
    const eachBookRes: EachBookRes = await getBooking(this.handleGetParams());
    if (eachBookRes.err || !eachBookRes.data) {
      handleNotiNavigation(this.props.history, 4, "/bookings");
    } else {
      //INFO : Security Configuration
      let conditionPassed = true;
      if (
        this.props.authStore.user?.role === "STATE" ||
        this.props.authStore.user?.role === "CENTER"
      ) {
        if (
          eachBookRes.data.center.centerAddress.state !==
          this.props.authStore.user.state
        ) {
          conditionPassed = false;
        }
      }
      if (this.props.authStore.user?.role === "CENTER") {
        if (
          !this.props.authStore.user.center?.includes(
            eachBookRes.data.center.id
          )
        ) {
          conditionPassed = false;
        }
      }

      if (conditionPassed) {
        this.setState({
          center: eachBookRes.data.center,
          booking: eachBookRes.data.booking,
          user: eachBookRes.data.user,
        });
        this.handleLoading(false);
      } else {
        handleNavigation(this.props.history, "/bookings");
      }
    }
  };

  handleBookingValidation = (
    bookingDate: moment.Moment,
    choice: keyof typeof ValidationResult
  ) => {
    const todayDate = moment(new Date());
    const formatDate = bookingDate.format("dddd, DD MMMM YYYY");

    if (bookingDate.diff(todayDate, "days") >= 7) {
      error({
        title:
          "You can't validate this booking for " +
          formatDate +
          " because the date is invalid",
        icon: <CloseCircleOutlined />,
        onOk: () => {},
        onCancel: () => {},
      });
    } else {
      confirm({
        title:
          "Are you sure want to validate this booking for " + formatDate + "?",
        icon: <ExclamationCircleOutlined />,
        okText: "Confirm",
        onOk: this.handleUpdateBookingValidation.bind(
          this,
          bookingDate,
          choice
        ),
        onCancel: () => {},
      });
    }
  };

  handleNotification = (type: string, message: string, description: string) => {
    if (type === "success") {
      notification.success({ message: message, description: "" });
    } else if (type === "warning") {
      notification.warning({
        message: message,
        description: description,
      });
    }
  };

  handleUpdateBookingValidation = async (
    date: moment.Moment,
    choice: keyof typeof ValidationResult
  ) => {
    this.handleLoading(true);
    const bookingValidation: Validation[] = this.state.booking.validation ?? [];
    const bookingDate = date.toDate();

    if (choice === "IV") {
      choice = "V";
    } else if (choice === "V") {
      choice = "IV";
    }

    const validation: Validation = {
      date: bookingDate,
      createdAt: new Date(),
      result: choice,
    };

    if (bookingValidation.length > 0) {
      let newValidation: boolean = true;
      bookingValidation.forEach((eachValidation) => {
        if (
          new Date(eachValidation.date.seconds * 1000).setHours(0, 0, 0, 0) ===
          bookingDate.setHours(0, 0, 0, 0)
        ) {
          eachValidation.result = choice;
          eachValidation.createdAt = new Date();
          newValidation = false;
        }
      });
      if (newValidation) {
        bookingValidation.push(validation);
      }
    } else {
      bookingValidation.push(validation);
    }

    const bookingValidationUpdate = await updateBookingValidation({
      id: this.state.booking.id,
      validation: bookingValidation,
    });

    if (bookingValidationUpdate) {
      this.handleNotification(
        "warning",
        "Validation Could Not Be Done",
        bookingValidationUpdate
      );
    } else if (choice === "V") {
      this.handleNotification("success", "Booking Successfully Validated", "");
    } else {
      this.handleNotification(
        "success",
        "Booking Successfully Invalidated",
        ""
      );
    }
    this.handleGetPreviewData();
    this.handleLoading(false);
  };

  handleDeleteBookings = async () => {
    this.handleLoading(true);
    const bookingDeletion = await deleteBooking(this.state.booking.id);

    if (bookingDeletion) {
      this.handleNotification("warning", "An error occured", bookingDeletion);
    } else {
      this.handleNotification("success", "Booking Successfully Deleted", "");
      this.handleNavigate("bookings");
    }
    this.handleLoading(false);
  };

  handleVerifyBooking = async () => {
    this.handleLoading(true);
    const selectedBooking = await verifyBooking(this.state.booking.id);
    if (typeof selectedBooking === "object") {
      this.setState({
        booking: selectedBooking,
      });
    }
    this.handleLoading(false);
  };

  handleVerifyBookingConfirmation = () => {
    confirm({
      title: "Are you sure want to verify this booking?",
      icon: <ExclamationCircleOutlined />,
      okText: "Confirm",
      onOk: this.handleVerifyBooking,
      onCancel: () => {},
    });
  };

  handleDeleteBookingsConfirmation = () => {
    confirm({
      title: "Are you sure want to delete this booking?",
      icon: <ExclamationCircleOutlined />,
      okText: "Confirm",
      onOk: this.handleDeleteBookings,
      onCancel: () => {},
    });
  };

  renderMedicalVerificationButton = () => {
    if (
      this.state.booking.roles !== "G" &&
      !this.state.booking.medicalVerified
    ) {
      return (
        <Button
          text="Verify Offer"
          width={150}
          margin={
            this.props.utilsStore.deviceType === DeviceType.Mobile
              ? 10
              : "0px 10px"
          }
          onClick={this.handleVerifyBookingConfirmation}
          small={true}
        />
      );
    } else {
      return null;
    }
  };

  renderCenterPreviewImage = () => {
    let imageUrl = "";

    if (this.state.center.imageToken) {
      const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
      const storageBucketId = isProd
        ? process.env.REACT_APP_PROD_STORAGE_BUCKET
        : process.env.REACT_APP_DEV_STORAGE_BUCKET;
      imageUrl = `https://firebasestorage.googleapis.com/v0/b/${storageBucketId}/o/centers%2F${this.state.center.id}%2Fimage.jpg?alt=media&token=${this.state.center.imageToken}`;
    }

    if (imageUrl) {
      return (
        <img
          src={imageUrl}
          alt="Place Preview"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      );
    } else {
      return null;
    }
  };

  renderVolunteeringRoles = () => {
    let selectedRole = "Role : ";
    if (this.state.booking.id) {
      selectedRole += VolunteerRoles[this.state.booking.roles];
    }
    return selectedRole;
  };

  renderVolunteeringShift = () => {
    let listView: any = [];
    let ableToValidate = false;
    if (
      this.props.authStore.user?.role === "SUPER" ||
      this.props.authStore.user?.role === "CENTER"
    ) {
      ableToValidate = true;
    }

    if (this.state.booking.shift.length > 0) {
      this.state.booking.shift.forEach((shift, index) => {
        const date = moment(this.state.booking.bookDate[index].seconds * 1000);
        const dateString = moment(
          this.state.booking.bookDate[index].seconds * 1000
        ).format("DD/MM/YYYY");

        let choice: keyof typeof ValidationResult = "IV";
        if (this.state.booking.validation) {
          this.state.booking.validation.forEach((eachValidation) => {
            if (
              date.toDate().setHours(0, 0, 0, 0) ===
              new Date(eachValidation.date.seconds * 1000).setHours(0, 0, 0, 0)
            ) {
              choice = eachValidation.result;
            }
          });
        }

        listView.push(
          <li
            style={{
              color: COLOR.BLACK,
            }}
            key={index}
          >
            <RowContainer
              style={{
                width: "100%",
                alignItems: "flex-start",
              }}
            >
              <Body style={{ marginRight: 40 }}>
                {dateString} - {VolunteerShift[shift]}
              </Body>
              <div style={{ flex: 1 }} />
              {ableToValidate && (
                <CustomActionTitle
                  onClick={this.handleBookingValidation.bind(
                    this,
                    date,
                    choice
                  )}
                  style={{ fontSize: 14 }}
                >
                  {String(choice) === "V" ? "Invalidate" : "Validate"} Booking
                </CustomActionTitle>
              )}
            </RowContainer>
          </li>
        );
        return null;
      });
    }
    return listView;
  };

  render() {
    if (this.props.authStore.userLoading) {
      return null;
    }
    return (
      <Spin
        spinning={this.state.loading}
        size="large"
        style={{
          width: "100vw",
          height: "100vh",
          maxHeight: "none",
        }}
      >
        <WindowResizer />
        <Header history={this.props.history} location={this.props.location} />
        <SecurityNavigator location={this.props.location} />
        <VerticalContainer
          style={{
            width: "100%",
          }}
        >
          <VerticalContainer
            style={{
              alignItems: "flex-start",
              width: "90%",
              maxWidth: 1200,
              padding: "40px 0px",
            }}
          >
            <FlexibleRowContainer
              style={{
                width: "100%",
                alignItems: "flex-start",
              }}
            >
              <RowContainer>
                <CustomActionTitle
                  onClick={this.handleNavigate.bind(this, "bookings")}
                  title="Bookings"
                >
                  Bookings
                </CustomActionTitle>
                <Title3 style={{ margin: "0px 10px" }}>/</Title3>
                <Title3 style={{ marginBottom: 0 }}>
                  {this.state.center.centerName}
                </Title3>
              </RowContainer>
              <div style={{ flex: 1, marginTop: 10 }} />
              {(this.props.authStore.user?.role === "SUPER" ||
                this.props.authStore.user?.role === "STATE" ||
                this.props.authStore.user?.role === "HEALTH") && (
                <>
                  {this.renderMedicalVerificationButton()}
                  <Button
                    text="Delete"
                    width={150}
                    margin={"0px 10px"}
                    onClick={this.handleDeleteBookingsConfirmation}
                    small={true}
                  />
                </>
              )}
            </FlexibleRowContainer>
            <Divider style={{ marginTop: 10, marginBottom: 30 }} />
            <Row style={{ width: "100%" }} gutter={8}>
              <Col sm={24} md={12}>
                <VerticalContainer
                  style={{
                    width: "100%",
                    background: COLOR.LIGHTGREY,
                    height: 300,
                    position: "relative",
                  }}
                >
                  {this.renderCenterPreviewImage()}
                </VerticalContainer>
                <VerticalContainer
                  style={{
                    width: "100%",
                    alignItems: "flex-start",
                    marginTop: 20,
                  }}
                >
                  <Title2>{this.state.center.centerName}</Title2>
                  <Body>
                    Address : {this.state.center.centerAddress.street},
                    {this.state.center.centerAddress.district},
                    {this.state.center.centerAddress.poscode},
                    {this.state.center.centerAddress.state}
                  </Body>
                  <Body>
                    Contact Number : {this.state.center.centerContactNo}
                  </Body>
                  <Body>Contact Email : {this.state.center.centerEmail}</Body>
                  <Body>Description : {this.state.center.description}</Body>
                </VerticalContainer>
              </Col>
              <Col
                sm={24}
                md={12}
                style={{
                  width: "100%",
                }}
              >
                <VerticalContainer
                  style={{
                    width: "100%",
                    paddingLeft:
                      this.props.utilsStore.deviceType === DeviceType.Mobile
                        ? 0
                        : 20,
                  }}
                >
                  <BaseProfileContainer>
                    <VerticalContainer
                      style={{
                        margin: 20,
                        marginBottom: 40,
                        width: "90%",
                        maxWidth: 500,
                        alignItems: "flex-start",
                      }}
                    >
                      <Body style={{ marginTop: 10, marginBottom: 5 }}>
                        Booking ID : {this.state.booking?.id ?? ""}
                      </Body>
                      <Body style={{ marginTop: 20, marginBottom: 5 }}>
                        Volunteer's Name : {this.state.user?.name ?? ""}
                      </Body>
                      <Body style={{ marginTop: 20, marginBottom: 5 }}>
                        Volunteer's Email : {this.state.user?.email ?? ""}
                      </Body>
                      <Body style={{ marginTop: 20, marginBottom: 5 }}>
                        Volunteer's Contact No :{" "}
                        {this.state.user?.mobileNo ?? ""}
                      </Body>
                      <Body style={{ marginTop: 20, marginBottom: 5 }}>
                        Volunteering Date :{" "}
                        {moment(
                          this.state.booking.startDate.seconds * 1000
                        ).format("DD/MM/YYYY") +
                          " - " +
                          moment(
                            this.state.booking.endDate.seconds * 1000
                          ).format("DD/MM/YYYY")}
                      </Body>
                      <Body style={{ marginTop: 20, marginBottom: 5 }}>
                        {this.renderVolunteeringRoles()}
                      </Body>
                      {this.state.booking.roles !== "G" && (
                        <Body style={{ marginTop: 20, marginBottom: 5 }}>
                          Verified Offer Letter:{" "}
                          {this.state.booking.medicalVerified ? "Yes" : "No"}
                        </Body>
                      )}
                      <Divider style={{ marginTop: 20, marginBottom: 5 }} />
                      <Body style={{ marginTop: 20, marginBottom: 5 }}>
                        Shift
                      </Body>
                      <VerticalContainer
                        style={{
                          width: "100%",
                        }}
                      >
                        <List style={{ width: "100%" }}>
                          <ul
                            style={{
                              paddingLeft: "1.2em",
                            }}
                          >
                            {this.renderVolunteeringShift()}
                          </ul>
                        </List>
                      </VerticalContainer>
                    </VerticalContainer>
                  </BaseProfileContainer>
                </VerticalContainer>
              </Col>
            </Row>
          </VerticalContainer>
        </VerticalContainer>
      </Spin>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
    authStore: state.authStore,
  };
};

export default connect(mapStateToProps)(BookingEditor);

const BaseProfileContainer = styled(VerticalContainer)`
  border-radius: 10px;
  border: solid 1px #ccc;
  width: 100%;
  border-radius: 10px;
`;
