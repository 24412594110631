export interface utilsStateInterface {
  deviceType: DeviceType;
  deviceSize: number;
}

export enum DeviceType {
  Mobile = 1,
  Tablet = 2,
  Laptop = 3,
}

export enum DeviceSize {
  minLaptopBreakpoints = 992,
  minBigLaptopBreakpoints = 1200,
  minMobileBreakpoints = 576,
  minTabletBreakpoints = 768,
}

const initState: utilsStateInterface = {
  deviceType: DeviceType.Laptop,
  deviceSize: 0,
};

const utilsReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_DEVICE_TYPE":
      return {
        ...state,
        deviceType: action.payload.deviceType,
      };
    case "UPDATE_DEVICE_SIZE":
      return {
        ...state,
        deviceSize: action.payload.deviceSize,
      };
    default:
      return state;
  }
};

export default utilsReducer;
