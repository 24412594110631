import AdminModel, { AdminRole } from "../../models/Admin";
import firebase from "../../utils/firebase";

export interface AdminRes {
  err: string;
  data: AdminModel | null;
}

export const userQueryLimit = 10;

export const getAdminWithPagination = (
  paginationQuery: string,
  paginationType: string,
  paginationSearch: string
) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: "SET_ROLE_LOADING",
      });

      const usersList: AdminModel[] = [];

      let userCollection;
      let userQuery;
      if (!paginationSearch) {
        userCollection = firebase
          .firestore()
          .collection("admin")
          .limit(userQueryLimit);
        if (paginationQuery) {
          if (paginationType === "asc") {
            userQuery = userCollection
              .orderBy("email")
              .startAfter(paginationQuery);
          } else {
            userQuery = userCollection
              .orderBy("email", "desc")
              .startAfter(paginationQuery);
          }
        } else {
          userQuery = userCollection.orderBy("email");
        }
      } else {
        userCollection = firebase.firestore().collection("admin");
        userQuery = userCollection
          .orderBy("email")
          .startAt(paginationSearch)
          .endAt(paginationSearch + "\uf8ff");
      }

      const userSnapshot = await userQuery.get();

      if (userSnapshot) {
        userSnapshot.forEach((eachDoc) => {
          usersList.push(eachDoc.data() as AdminModel);
        });
      }

      if (paginationType === "desc" && usersList.length > 0) {
        usersList.reverse();
      }

      let pagination = "";
      if (usersList.length === userQueryLimit && !paginationSearch) {
        pagination = usersList[userQueryLimit - 1].email;
      }

      let users = [];
      if (usersList.length > 0) {
        users = usersList;
      } else if (!paginationSearch) {
        users = getState().adminStore.users;
      }

      dispatch({
        type: "UPDATE_ROLES",
        payload: {
          users: users,
          pagination: pagination,
        },
      });
    } catch (err) {}
  };
};

export const getAdmin = async (id: string) => {
  let userRes: AdminRes = {
    err: "",
    data: null,
  };

  try {
    const userQuery = await firebase
      .firestore()
      .collection("admin")
      .doc(id)
      .get();
    if (userQuery.exists) {
      const userData: AdminModel = userQuery.data() as AdminModel;
      userRes.data = userData;
    } else {
      userRes.err = "Cannot be found";
    }
  } catch (err) {
    userRes.err = err.message;
  }

  return userRes;
};

export const updateAdmin = async (items: {
  id: string;
  role: keyof typeof AdminRole;
  state: string;
  center: string[];
  ngoName: string;
}) => {
  try {
    const adminRef = items.id;
    if (adminRef) {
      await firebase.firestore().collection("admin").doc(adminRef).update({
        role: items.role,
        state: items.state,
        center: items.center,
        ngoName: items.ngoName
      });
      return "";
    } else return "Admin is not updated. Unknown Error.";
  } catch (err) {
    return err.message;
  }
};
