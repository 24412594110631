import { Form, Spin, Typography } from "antd";
import { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import packageJson from "../../package.json";
import AuthBackground from "../assets/background.webp";
import AdminLogo from "../assets/logo.png";
import Button from "../components/Button";
import TextInput from "../components/TextInput";
import SecurityNavigator from "../navigator/SecurityNavigator";
import WindowResizer from "../navigator/WindowResizer";
import { resetPassword, signIn } from "../store/actions/authActions";
import { sleep } from "../store/actions/utilsActions";
import { authStateInterface } from "../store/reducers/authReducer";
import {
  DeviceType,
  utilsStateInterface,
} from "../store/reducers/utilsReducer";
import { COLOR } from "../styles/Colors";
import { RowContainer, VerticalContainer } from "../styles/Layout";
import { Body, Footer } from "../styles/Typography";

const { Link } = Typography;

enum screenType {
  SIGNIN = "signIn",
  FORGET = "forget",
}

interface Props {
  location: any;
  history: any;
  authStore: authStateInterface;
  utilsStore: utilsStateInterface;
}

interface State {
  email: string;
  password: string;
  emailError: string;
  passwordError: string;
  loading: boolean;
  screen: screenType;
}

class LoginScreen extends Component<Props> {
  state: State = {
    email: "",
    password: "",
    emailError: "",
    passwordError: "",
    loading: false,
    screen: screenType.SIGNIN,
  };

  handleChange = (event: any) => {
    let eventId = event.target.id;

    this.setState({
      [eventId]: event.target.value,
    });
  };

  handleChangeScreen = (type: screenType) => {
    this.setState({
      screen: type,
      emailError: "",
      passwordError: "",
    });
  };

  handleLoading = (loading: boolean) => {
    this.setState({
      loading: loading,
    });
  };

  handleSubmit = (type: screenType) => {
    this.setState(
      {
        emailError: "",
        passwordError: "",
      },
      async () => {
        this.handleLoading(true);
        //eslint-disable-next-line
        const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        if (!filter.test(this.state.email.replace(/\s/g, ""))) {
          this.setState({
            emailError: "Please enter the correct email format",
          });
        }
        if (
          type === screenType.SIGNIN &&
          this.state.password.replace(/\s/g, "").length < 1
        ) {
          this.setState({
            passwordError: "Please enter your password",
          });
        }

        await sleep(250);
        let conditionPassed: boolean = !this.state.emailError;
        if (type === screenType.FORGET) {
          conditionPassed =
            conditionPassed && !this.state.passwordError ? true : false;
        }

        if (conditionPassed) {
          try {
            if (type === screenType.SIGNIN) {
              await signIn({
                email: this.state.email.replace(/\s/g, ""),
                password: this.state.password.replace(/\s/g, ""),
              });
            } else if (type === screenType.FORGET) {
              await resetPassword(this.state.email.replace(/\s/g, ""));
              this.setState({
                emailError: "Password reset link has been sent to your email",
              });
            }
          } catch (err) {
            if (type === screenType.FORGET) {
              this.setState({
                emailError: err.message,
              });
            } else {
              this.setState({
                passwordError: err.message,
              });
            }
          }
        }

        this.handleLoading(false);
      }
    );
  };

  renderScreens = () => {
    if (this.state.screen === screenType.SIGNIN) {
      return (
        <>
          <StyledForm.Item
            validateStatus={this.state.emailError ? "error" : "success"}
            help={this.state.emailError ? this.state.emailError : null}
            style={{
              width: "100%",
              maxWidth: 500,
            }}
          >
            <Body
              style={{
                textAlign: "left",
                marginBottom: 5,
              }}
            >
              Email
            </Body>
            <TextInput
              id="email"
              width={
                this.props.utilsStore.deviceType === DeviceType.Mobile
                  ? "100%"
                  : 500
              }
              height={40}
              borderRadius={10}
              background={COLOR.WHITE}
              placeholder="Email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </StyledForm.Item>
          <StyledForm.Item
            validateStatus={this.state.passwordError ? "error" : "success"}
            help={this.state.passwordError ? this.state.passwordError : null}
            style={{
              width: "100%",
              maxWidth: 500,
            }}
          >
            <RowContainer style={{ marginTop: 20 }}>
              <Body style={{ marginBottom: 5 }}>Password</Body>
              <div style={{ flexGrow: 1 }} />
              <Link
                onClick={this.handleChangeScreen.bind(this, screenType.FORGET)}
              >
                <Body
                  style={{
                    marginBottom: 5,
                    color: COLOR.RED,
                  }}
                >
                  Forgot Password?
                </Body>
              </Link>
            </RowContainer>
            <TextInput
              id="password"
              width={
                this.props.utilsStore.deviceType === DeviceType.Mobile
                  ? "100%"
                  : 500
              }
              height={40}
              borderRadius={10}
              background={COLOR.WHITE}
              placeholder="Password"
              password={true}
              value={this.state.password}
              onChange={this.handleChange}
            />
          </StyledForm.Item>
          <RowContainer
            style={{
              alignSelf: "flex-end",
              marginTop: 10,
            }}
          >
            <Button
              text={"Sign In"}
              width={120}
              small={true}
              onClick={this.handleSubmit.bind(this, screenType.SIGNIN)}
            />
          </RowContainer>
        </>
      );
    } else if (this.state.screen === screenType.FORGET) {
      return (
        <>
          <StyledForm.Item
            validateStatus={this.state.emailError ? "error" : "success"}
            help={this.state.emailError ? this.state.emailError : null}
            style={{
              width: "100%",
              maxWidth: 500,
            }}
          >
            <RowContainer style={{ marginTop: 30 }}>
              <Body style={{ textAlign: "left", marginBottom: 5 }}>Email</Body>
              <div style={{ flexGrow: 1 }} />
              <Link
                onClick={this.handleChangeScreen.bind(this, screenType.SIGNIN)}
              >
                <Body
                  style={{
                    marginBottom: 5,
                    color: COLOR.RED,
                  }}
                >
                  Remembered Password?
                </Body>
              </Link>
            </RowContainer>

            <TextInput
              id="email"
              width={
                this.props.utilsStore.deviceType === DeviceType.Mobile
                  ? "100%"
                  : 500
              }
              height={40}
              borderRadius={10}
              background={COLOR.WHITE}
              placeholder="Email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </StyledForm.Item>
          <RowContainer
            style={{
              alignSelf: "flex-end",
              marginTop: 10,
            }}
          >
            <Button
              text={"Forgot Password"}
              width={"auto"}
              small={true}
              onClick={this.handleSubmit.bind(this, screenType.FORGET)}
            />
          </RowContainer>
        </>
      );
    }
  };

  render() {
    if (this.props.authStore.userLoading) {
      return null;
    }
    return (
      <Spin
        spinning={this.state.loading}
        size="large"
        style={{
          width: "100vw",
          height: "100vh",
          maxHeight: "none",
        }}
      >
        <WindowResizer />
        <VerticalContainer
          style={{
            backgroundImage: `url(${AuthBackground})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            width: "100vw",
            height: "100vh",
          }}
        >
          <SecurityNavigator location={this.props.location} />

          <StyledForm
            style={{
              height: "auto",
              width: "80%",
              maxWidth: 600,
            }}
          >
            <Logo src={AdminLogo} />
            {this.renderScreens()}
            <div style={{ flex: 1 }} />
            <RowContainer style={{ width: "100%", justifyContent: "flex-end" }}>
              <Footer
                style={{
                  color: COLOR.GREY,
                }}
              >
                Version : {packageJson.version}
              </Footer>
            </RowContainer>
          </StyledForm>
        </VerticalContainer>
      </Spin>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  };
};

export default connect(mapStateToProps)(LoginScreen);

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  border-radius: 22px;
  padding: 60px 20px;
  justify-content: flex-start;
  align-items: center;
  background-color: ${COLOR.LIGHTGREY};
`;

const Logo = styled.img`
  margin-bottom: 50px;
  height: 50px;
`;
