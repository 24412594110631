import {
  CheckCircleOutlined,
  FileTextFilled,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Col, Divider, Row, Select, Switch } from "antd";
import moment from "moment";
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { connect } from "react-redux";
import styled from "styled-components";
import Button from "../components/Button";
import Header from "../components/Header";
import SecurityNavigator from "../navigator/SecurityNavigator";
import WindowResizer from "../navigator/WindowResizer";
import {
  getPaymentAnalytics,
  getSpreadsheetAnalytics,
  getStateAnalytics,
  getStateAnalyticsGroup,
} from "../store/actions/analyticsActions";
import { analyticStateInterface } from "../store/reducers/analyticReducer";
import { authStateInterface } from "../store/reducers/authReducer";
import {
  DeviceType,
  utilsStateInterface,
} from "../store/reducers/utilsReducer";
import { COLOR } from "../styles/Colors";
import {
  FlexibleRowContainer,
  RowContainer,
  VerticalContainer,
} from "../styles/Layout";
import { Body, Footer, Title2, Title3 } from "../styles/Typography";

interface Props {
  location: any;
  history: any;
  authStore: authStateInterface;
  utilsStore: utilsStateInterface;
  analyticStore: analyticStateInterface;
  getStateAnalytics: () => void;
  getStateAnalyticsGroup: () => void;
  getSpreadsheetAnalytics: () => void;
  getPaymentAnalytics: () => void;
}

const iconStyle = {
  fontSize: 50,
  color: COLOR.RED,
  marginRight: 20,
};

const { Option } = Select;

class Dashboard extends Component<Props> {
  state = {
    districtShowing: false,
    selectedState: "Selangor",
  };

  componentDidMount() {
    if (this.props.authStore.user) {
      this.handleGetPreviewData();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      JSON.stringify(prevProps.authStore.user) !==
        JSON.stringify(this.props.authStore.user) &&
      this.props.authStore.user
    ) {
      this.handleGetPreviewData();
    }
  }

  handleGetPreviewData = async () => {
    this.props.getStateAnalytics();
    this.props.getStateAnalyticsGroup();
    this.props.getSpreadsheetAnalytics();
    this.props.getPaymentAnalytics();
    this.setState({
      selectedState: this.props.authStore.user?.state ?? "Selangor",
    });
  };

  handleNavigateLink = (link: string) => {
    window.open(link);
  };

  handleChangeSelectedState = (stateName: string) => {
    this.setState({
      selectedState: stateName,
    });
  };

  renderCardInfo = () => {
    const currentDate = this.props.analyticStore.stateAnalytics
      ? new Date(
          this.props.analyticStore.stateAnalytics.analyticalDate.seconds * 1000
        )
      : new Date();

    let registeredUser: string | number = "-";
    let registeredUserAdded: string | number = "-";
    let volunteers: string | number = "-";
    let volunteersAdded: string | number = "-";
    let completedProfile: string | number = "-";
    let completedProfileAdded: string | number = "-";
    const dateString = moment(currentDate).format("DD-MMMM-YYYY ha");

    if (
      this.props.analyticStore.stateAnalytics &&
      this.props.analyticStore.previousStateAnalytics
    ) {
      const currentAnalytics = this.props.analyticStore.stateAnalytics;
      const previousAnalytics = this.props.analyticStore.previousStateAnalytics;
      let adminUser: number = 0;
      let previousAdminUser: number = 0;
      let adminVolunteers: number = 0;
      let previousAdminVolunteers: number = 0;
      let adminProfile: number = 0;
      let previousAdminProfile: number = 0;

      let previousMonthAdminUser: number = 0;
      let previousMonthAdminVolunteers: number = 0;
      let previousMonthAdminProfile: number = 0;

      if (
        this.props.authStore.user?.role === "SUPER" ||
        this.props.authStore.user?.role === "NGO" ||
        this.props.authStore.user?.role === "HEALTH"
      ) {
        currentAnalytics.stateArray.map((eachState) => {
          adminUser += eachState.registeredUser;
          adminVolunteers += eachState.volunteerBooked;
          adminProfile += eachState.completedProfile;
          return null;
        });

        if (this.props.analyticStore.stateAnalyticsGroup.length > 0) {
          this.props.analyticStore.stateAnalyticsGroup.map(
            (eachPreviousStateAnalytics) => {
              eachPreviousStateAnalytics.stateArray.map((eachState) => {
                previousMonthAdminUser += eachState.registeredUser;
                previousMonthAdminVolunteers += eachState.volunteerBooked;
                previousMonthAdminProfile += eachState.completedProfile;
                return null;
              });
              return null;
            }
          );
        }

        previousAnalytics.stateArray.map((eachState) => {
          previousAdminUser += eachState.registeredUser;
          previousAdminVolunteers += eachState.volunteerBooked;
          previousAdminProfile += eachState.completedProfile;
          return null;
        });
      } else if (
        this.props.authStore.user?.role === "STATE" ||
        this.props.authStore.user?.role === "CENTER"
      ) {
        currentAnalytics.stateArray.map((eachState) => {
          const selectedState = this.props.authStore.user?.state ?? "";
          if (eachState.state !== selectedState) {
            return null;
          }
          adminUser += eachState.registeredUser;
          adminVolunteers += eachState.volunteerBooked;
          adminProfile += eachState.completedProfile;
          return null;
        });

        if (this.props.analyticStore.stateAnalyticsGroup.length > 0) {
          this.props.analyticStore.stateAnalyticsGroup.map(
            (eachPreviousStateAnalytics) => {
              eachPreviousStateAnalytics.stateArray.map((eachState) => {
                const selectedState = this.props.authStore.user?.state ?? "";
                if (eachState.state !== selectedState) {
                  return null;
                }
                previousMonthAdminUser += eachState.registeredUser;
                previousMonthAdminVolunteers += eachState.volunteerBooked;
                previousMonthAdminProfile += eachState.completedProfile;
                return null;
              });
              return null;
            }
          );
        }

        previousAnalytics.stateArray.map((eachState) => {
          const selectedState = this.props.authStore.user?.state ?? "";
          if (eachState.state !== selectedState) {
            return null;
          }
          previousAdminUser += eachState.registeredUser;
          previousAdminVolunteers += eachState.volunteerBooked;
          previousAdminProfile += eachState.completedProfile;
          return null;
        });
      }

      registeredUser = adminUser + previousMonthAdminUser;
      registeredUserAdded = adminUser - previousAdminUser;
      volunteers = adminVolunteers + previousMonthAdminVolunteers;
      volunteersAdded = adminVolunteers - previousAdminVolunteers;
      completedProfile = adminProfile + previousMonthAdminProfile;
      completedProfileAdded = adminProfile - previousAdminProfile;
    }

    const cardInfo = [
      {
        icon: <UserOutlined style={iconStyle} />,
        title: registeredUser,
        added:
          registeredUserAdded > 0
            ? "+" + registeredUserAdded
            : registeredUserAdded,
        body: "Registered Users",
        p: "Updated on: " + dateString,
      },
      {
        icon: <TeamOutlined style={iconStyle} />,
        title: volunteers,
        added: volunteersAdded > 0 ? "+" + volunteersAdded : volunteersAdded,
        body: "Volunteers",
        p: "Updated on: " + dateString,
      },
      {
        icon: <CheckCircleOutlined style={iconStyle} />,
        title: completedProfile,
        added:
          completedProfileAdded > 0
            ? "+" + completedProfileAdded
            : completedProfileAdded,
        body: "Completed Profile",
        p: "Updated on: " + dateString,
      },
    ];

    return cardInfo;
  };

  renderChartSize = () => {
    const maxSize = 1200 * 0.9;
    let chartSize: number = this.props.utilsStore.deviceSize * 0.9;
    if (chartSize >= maxSize) {
      chartSize = maxSize;
    }

    return chartSize;
  };

  renderAnalyticsSpreadsheet = () => {
    const listView: any = [];

    let bookingAnalyticsLink = "";
    let centerAnalyticsLink = "";
    let bookingContactsLink = "";
    let paymentExportLink = "";
    let currentDate = "";
    let paymentDate = "";

    const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
    const bucketUrl = isProd
      ? process.env.REACT_APP_PROD_STORAGE_BUCKET
      : process.env.REACT_APP_DEV_STORAGE_BUCKET;
    const prefixFileUrl =
      "https://firebasestorage.googleapis.com/v0/b/" + bucketUrl + "/o/";

    if (this.props.analyticStore.spreadsheetAnalytics) {
      currentDate = moment(
        new Date(
          this.props.analyticStore.spreadsheetAnalytics.analyticalDate.seconds *
            1000
        )
      ).format("DD-MMMM-YYYY ha");
      if (this.props.analyticStore.spreadsheetAnalytics.bookingAnalytics) {
        const bookingAnalyticsData =
          this.props.analyticStore.spreadsheetAnalytics.bookingAnalytics;
        bookingAnalyticsLink =
          prefixFileUrl +
          bookingAnalyticsData.name +
          "?alt=media&token=" +
          bookingAnalyticsData.token;
      }
      if (this.props.analyticStore.spreadsheetAnalytics.centerAnalytics) {
        const centerAnalyticsData =
          this.props.analyticStore.spreadsheetAnalytics.centerAnalytics;
        centerAnalyticsLink =
          prefixFileUrl +
          centerAnalyticsData.name +
          "?alt=media&token=" +
          centerAnalyticsData.token;
      }
      if (this.props.analyticStore.spreadsheetAnalytics.bookingContacts) {
        const bookingContactsData =
          this.props.analyticStore.spreadsheetAnalytics.bookingContacts;
        bookingContactsLink =
          prefixFileUrl +
          bookingContactsData.name +
          "?alt=media&token=" +
          bookingContactsData.token;
      }
    }

    if (this.props.analyticStore.paymentAnalytics) {
      paymentDate = moment(
        new Date(
          this.props.analyticStore.paymentAnalytics.createdAt.seconds * 1000
        )
      ).format("DD-MMMM-YYYY ha");
      const paymentExportData = this.props.analyticStore.paymentAnalytics;
      paymentExportLink =
        prefixFileUrl +
        paymentExportData.name +
        "?alt=media&token=" +
        paymentExportData.token;
    }

    let uploadList = [
      {
        title: "Booking Analytics",
        updatedAt: currentDate,
        link: bookingAnalyticsLink,
      },
      {
        title: "Center Analytics",
        updatedAt: currentDate,
        link: centerAnalyticsLink,
      },
      {
        title: "Booking Contacts",
        updatedAt: currentDate,
        link: bookingContactsLink,
      },
    ];

    if (
      this.props.analyticStore.stateAnalytics &&
      this.props.analyticStore.stateAnalytics.usersExport
    ) {
      const userExportData =
        this.props.analyticStore.stateAnalytics.usersExport;
      const userExportLink =
        prefixFileUrl +
        userExportData.name +
        "?alt=media&token=" +
        userExportData.token;

      const monthAnalyticDate = moment(
        this.props.analyticStore.stateAnalytics.analyticalDate.seconds * 1000
      );
      uploadList.push({
        title: `${monthAnalyticDate.format("MMMM YYYY")} User Exports`,
        updatedAt: monthAnalyticDate.format("DD-MMMM-YYYY-ha"),
        link: userExportLink,
      });
    }

    if (this.props.analyticStore.stateAnalyticsGroup.length > 0) {
      this.props.analyticStore.stateAnalyticsGroup.map((eachAnalytics) => {
        const userExportData = eachAnalytics.usersExport;
        if (userExportData) {
          const userExportLink =
            prefixFileUrl +
            userExportData.name +
            "?alt=media&token=" +
            userExportData.token;
          const eachAnalyticDate = moment(
            eachAnalytics.analyticalDate.seconds * 1000
          );
          uploadList.push({
            title: `${eachAnalyticDate.format("MMMM YYYY")} User Exports`,
            updatedAt: eachAnalyticDate.format("DD-MMMM-YYYY-ha"),
            link: userExportLink,
          });
        }
        return null;
      });
    }

    uploadList.push({
      title: "Payment Reimbursement",
      updatedAt: paymentDate,
      link: paymentExportLink,
    });

    const ngoAdmin = this.props.authStore.user?.role === "NGO" ? true : false;
    const healthAdmin =
      this.props.authStore.user?.role === "HEALTH" ? true : false;
    if (ngoAdmin || healthAdmin) {
      uploadList = uploadList.splice(0, 2);
    }

    uploadList.map((eachUpload, index) => {
      listView.push(
        <VerticalContainer
          style={{
            alignItems: "flex-start",
            width: "100%",
            marginLeft: 15,
          }}
          key={index}
        >
          <RowContainer style={{ width: "100%" }}>
            <FileTextFilled
              style={{ color: COLOR.RED, marginRight: 15, fontSize: 30 }}
            />
            <VerticalContainer style={{ alignItems: "flex-start" }}>
              <Body
                style={{
                  marginBottom: 0,
                }}
              >
                {eachUpload.title}
              </Body>
              <Footer
                style={{
                  marginBottom: 0,
                  color: COLOR.GREY,
                }}
              >
                Updated At : {eachUpload.updatedAt}
              </Footer>
            </VerticalContainer>
            <div style={{ flex: 1 }} />
            {eachUpload.link && (
              <Button
                text="Download"
                small={true}
                width={100}
                onClick={this.handleNavigateLink.bind(this, eachUpload.link)}
              />
            )}
          </RowContainer>
          <Divider style={{ margin: "10px 0px" }} />
        </VerticalContainer>
      );
      return null;
    });

    return listView;
  };

  renderChart = () => {
    if (!this.props.analyticStore.stateAnalytics) {
      return null;
    }

    let showingAllStates = false;
    if (
      !this.state.districtShowing &&
      (this.props.authStore.user?.role === "SUPER" ||
        this.props.authStore.user?.role === "NGO" ||
        this.props.authStore.user?.role === "HEALTH")
    ) {
      showingAllStates = true;
    }

    let volunteersData: number[] = [];
    let registeredData: number[] = [];
    let categories: string[] = [];
    let graphObject: { [key: string]: { [key: string]: number } } = {};

    if (showingAllStates) {
      this.props.analyticStore.stateAnalytics.stateArray.map((eachState) => {
        graphObject[eachState.state] = {
          count: eachState.registeredUser,
          volunteer: eachState.volunteerBooked,
        };
        return null;
      });

      if (this.props.analyticStore.stateAnalyticsGroup.length > 0) {
        this.props.analyticStore.stateAnalyticsGroup.map(
          (eachPreviousStateAnalytics) => {
            eachPreviousStateAnalytics.stateArray.map((eachState) => {
              graphObject[eachState.state] = {
                count:
                  graphObject[eachState.state].count + eachState.registeredUser,
                volunteer:
                  graphObject[eachState.state].volunteer +
                  eachState.volunteerBooked,
              };
              return null;
            });
            return null;
          }
        );
      }
    } else if (this.state.selectedState) {
      this.props.analyticStore.stateAnalytics.stateArray.map((eachState) => {
        if (eachState.state !== this.state.selectedState) {
          return null;
        }
        eachState.totalVolunteerPerDistrict.map((eachVolunteerDistrict) => {
          graphObject[eachVolunteerDistrict.district] = {
            count: eachVolunteerDistrict.count,
            volunteer: eachVolunteerDistrict.volunteer,
          };
          return null;
        });
        return null;
      });

      if (this.props.analyticStore.stateAnalyticsGroup.length > 0) {
        this.props.analyticStore.stateAnalyticsGroup.map(
          (eachPreviousStateAnalytics) => {
            eachPreviousStateAnalytics.stateArray.map((eachState) => {
              if (eachState.state !== this.state.selectedState) {
                return null;
              }
              eachState.totalVolunteerPerDistrict.map(
                (eachVolunteerDistrict) => {
                  graphObject[eachVolunteerDistrict.district] = {
                    count:
                      graphObject[eachVolunteerDistrict.district].count +
                      eachVolunteerDistrict.count,
                    volunteer:
                      graphObject[eachVolunteerDistrict.district].volunteer +
                      eachVolunteerDistrict.volunteer,
                  };
                  return null;
                }
              );
              return null;
            });
            return null;
          }
        );
      }
    }

    Object.keys(graphObject).map((eachKey) => {
      registeredData.push(graphObject[eachKey].count);
      volunteersData.push(graphObject[eachKey].volunteer);
      categories.push(eachKey);
      return null;
    });

    const series = [
      {
        name: "Registered Users",
        data: registeredData,
      },
      {
        name: "Volunteers",
        data: volunteersData,
      },
    ];
    const options = {
      plotOptions: {
        bar: {
          columnWidth: "80%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: categories,
      },
    };

    return (
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={400}
        width={this.renderChartSize()}
      />
    );
  };

  renderStatisticCard = (detail: any) => {
    return (
      <Col
        span={this.props.utilsStore.deviceType === DeviceType.Mobile ? 24 : 8}
      >
        <BaseCardContainer
          style={{
            marginBottom:
              this.props.utilsStore.deviceType === DeviceType.Mobile ? 10 : 0,
          }}
        >
          <VerticalContainer
            style={{
              margin: 25,
            }}
          >
            <RowContainer>
              {detail.icon}
              <VerticalContainer
                style={{
                  alignItems: "flex-start",
                }}
              >
                <RowContainer
                  style={{
                    alignItems: "flex-start",
                  }}
                >
                  <Title3 style={{ marginBottom: 0 }}>{detail.title}</Title3>
                  <Title3
                    style={{ marginBottom: 0, marginLeft: 5, color: "green" }}
                  >
                    ({detail.added})
                  </Title3>
                </RowContainer>

                <Title3 style={{ marginBottom: 0 }}>{detail.body}</Title3>
                <Body style={{ marginBottom: 0 }}>{detail.p}</Body>
              </VerticalContainer>
            </RowContainer>
          </VerticalContainer>
        </BaseCardContainer>
      </Col>
    );
  };

  render() {
    if (this.props.authStore.userLoading) {
      return null;
    }
    const superAdmin =
      this.props.authStore.user?.role === "SUPER" ? true : false;
    const ngoAdmin = this.props.authStore.user?.role === "NGO" ? true : false;
    const healthAdmin =
      this.props.authStore.user?.role === "HEALTH" ? true : false;
    const districtShowing = this.state.districtShowing;

    return (
      <VerticalContainer style={{ width: "100%" }}>
        <WindowResizer />
        <SecurityNavigator location={this.props.location} />
        <Header history={this.props.history} location={this.props.location} />
        <VerticalContainer
          style={{
            width: "100%",
            maxWidth: 1200,
            padding: "40px 0px",
          }}
        >
          <VerticalContainer
            style={{
              alignItems: "flex-start",
              width: "90%",
              marginLeft: 20,
            }}
          >
            <Title2 style={{ marginBottom: 20 }}>
              {superAdmin || ngoAdmin || healthAdmin
                ? "Analytics of MyVAC Malaysia"
                : "Analytics for " +
                  this.props.authStore.user?.state +
                  " State"}
            </Title2>
          </VerticalContainer>
          <Row style={{ width: "90%" }} gutter={16}>
            {this.renderStatisticCard(this.renderCardInfo()[0])}
            {this.renderStatisticCard(this.renderCardInfo()[1])}
            {this.renderStatisticCard(this.renderCardInfo()[2])}
          </Row>
          <VerticalContainer
            style={{
              alignItems: "flex-center",
              width: "90%",
              marginTop: 20,
            }}
          >
            <Title3
              style={{
                margin: "0px 0px 20px 15px",
                alignSelf: "flex-start",
              }}
            >
              Analytics Spreadsheet
            </Title3>
            <VerticalContainer
              style={{
                width: "100%",
                marginBottom: 60,
              }}
            >
              {this.renderAnalyticsSpreadsheet()}
            </VerticalContainer>
            <FlexibleRowContainer
              style={{
                width: "100%",
                marginBottom: 30,
              }}
            >
              {superAdmin || ngoAdmin || healthAdmin ? (
                <Title3 style={{ margin: "0px 0px 10px 15px" }}>
                  Total Registered Users of MyVac Malaysia
                </Title3>
              ) : (
                <Title3 style={{ margin: "0px 0px 10px 15px" }}>
                  Total Registered Users In {this.props.authStore.user?.state}
                </Title3>
              )}

              <div style={{ flexGrow: 1 }} />
              {(superAdmin || ngoAdmin || healthAdmin) && (
                <>
                  <RowContainer>
                    <Body style={{ margin: "0px 12px 0px 0px" }}>
                      {!districtShowing ? "States View" : "District View"}
                    </Body>
                    <Switch
                      defaultChecked
                      onClick={() =>
                        this.setState({ districtShowing: !districtShowing })
                      }
                    />
                  </RowContainer>

                  {districtShowing && (
                    <Select
                      showSearch
                      style={{
                        width: 180,
                        marginLeft: "14px",
                        marginTop:
                          this.props.utilsStore.deviceType <= DeviceType.Tablet
                            ? 10
                            : 0,
                      }}
                      value={this.state.selectedState}
                      optionFilterProp="children"
                      onChange={this.handleChangeSelectedState}
                    >
                      <Option value="Selangor">Selangor</Option>
                      <Option value="Johor">Johor</Option>
                      <Option value="Kedah">Kedah</Option>
                      <Option value="Kelantan">Kelantan</Option>
                      <Option value="WP Kuala Lumpur">Kuala Lumpur</Option>
                      <Option value="Melaka">Melaka</Option>
                      <Option value="Negeri Sembilan">Negeri Sembilan</Option>
                      <Option value="Pahang">Pahang</Option>
                      <Option value="Pulau Pinang">Pulau Pinang</Option>
                      <Option value="Perak">Perak</Option>
                      <Option value="Perlis">Perlis</Option>
                      <Option value="WP Putrajaya">Putrajaya</Option>
                      <Option value="Sabah">Sabah</Option>
                      <Option value="WP Labuan">WP Labuan</Option>
                      <Option value="Sarawak">Sarawak</Option>
                      <Option value="Terengganu">Terengganu</Option>
                    </Select>
                  )}
                </>
              )}
            </FlexibleRowContainer>

            {this.renderChart()}
          </VerticalContainer>
        </VerticalContainer>
      </VerticalContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
    analyticStore: state.analyticStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getStateAnalytics: () => dispatch(getStateAnalytics()),
    getStateAnalyticsGroup: () => dispatch(getStateAnalyticsGroup()),
    getSpreadsheetAnalytics: () => dispatch(getSpreadsheetAnalytics()),
    getPaymentAnalytics: () => dispatch(getPaymentAnalytics()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

const BaseCardContainer = styled(VerticalContainer)`
  background: ${COLOR.LIGHTGREY};
  width: 100%;
  border-radius: 5px;
`;
