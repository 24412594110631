import { Input } from "antd";
import React, { Component } from "react";
import { COLOR } from "../styles/Colors";

interface Props {
  width: number | string;
  placeholder: string;
  maxWidth?: number | string;
  password?: boolean;
  disabled?: boolean;
  id?: string;
  value?: string | number;
  onChange?: (e: any) => void;
  height?: number | string;
  borderRadius?: number;
  background?: string;
  maxLength?: number;
  type?: string;
}

export default class TextInput extends Component<Props> {
  render() {
    if (this.props.password) {
      return (
        <Input.Password
          id={this.props.id ?? ""}
          value={this.props.value ?? ""}
          style={{
            width: this.props.width,
            maxWidth: this.props.maxWidth ?? "none",
            background: this.props.background ?? COLOR.WHITE,
            height: this.props.height,
            borderRadius: this.props.borderRadius,
          }}
          placeholder={this.props.placeholder}
          onChange={this.props.onChange}
          disabled={this.props.disabled}
        />
      );
    } else {
      return (
        <Input
          id={this.props.id ?? ""}
          value={this.props.value ?? ""}
          type={this.props.type ?? ""}
          style={{
            width: this.props.width,
            maxWidth: this.props.maxWidth ?? "none",
            background: this.props.background ?? COLOR.WHITE,
            height: this.props.height,
            borderRadius: this.props.borderRadius,
          }}
          placeholder={this.props.placeholder}
          onChange={this.props.onChange}
          disabled={this.props.disabled}
          maxLength={this.props.maxLength ?? undefined}
        />
      );
    }
  }
}
