import { combineReducers } from "redux";
import analyticReducer from "./analyticReducer";
import authReducer from "./authReducer";
import bookingReducer from "./bookingReducer";
import centerReducer from "./centerReducer";
import healthVerificationLinkReducer from "./healthVerificationLinkReducer";
import ngoReducer from "./ngoReducer";
import resReducer from "./resReducer";
import roleReducer from "./roleReducer";
import userReducer from "./userReducer";
import utilsReducer from "./utilsReducer";

const rootReducer = combineReducers({
  authStore: authReducer,
  utilsStore: utilsReducer,
  centerStore: centerReducer,
  resStore: resReducer,
  analyticStore: analyticReducer,
  userStore: userReducer,
  adminStore: roleReducer,
  bookingStore: bookingReducer,
  healthVerificationLinkStore: healthVerificationLinkReducer,
  ngoStore: ngoReducer,
});

export default rootReducer;
