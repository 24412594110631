import { Col, Divider, notification, Row, Select, Spin } from "antd";
import "antd/dist/antd.css";
import axios from "axios";
import { Component } from "react";
import { connect } from "react-redux";
import Button from "../components/Button";
import Header from "../components/Header";
import TextInput from "../components/TextInput";
import { AdminRole } from "../models/Admin";
import NgoModel from "../models/Ngo";
import SecurityNavigator from "../navigator/SecurityNavigator";
import WindowResizer from "../navigator/WindowResizer";
import { getAllCenters } from "../store/actions/centerActions";
import { AllNgoRes, getAllNgo } from "../store/actions/ngoActions";
import { AdminRes, getAdmin, updateAdmin } from "../store/actions/roleActions";
import { sleep } from "../store/actions/utilsActions";
import { authStateInterface } from "../store/reducers/authReducer";
import { centerStateInterface } from "../store/reducers/centerReducer";
import { COLOR } from "../styles/Colors";
import { RowContainer, VerticalContainer } from "../styles/Layout";
import {
  Body,
  CustomActionTitle,
  Footer as Warning,
  Title3,
} from "../styles/Typography";
import { getStateList } from "../utils/getState";
import { handleNavigation, handleNotiNavigation } from "../utils/navigator";

interface Props {
  location: any;
  match: any;
  history: any;
  authStore: authStateInterface;
  centerStore: centerStateInterface;
  getAllCenters: (state: string) => void;
}

interface UserAttributeState {
  id: string;
  name: string;
  email: string;
  password?: string;
  role: keyof typeof AdminRole | string;
  state: string;
  ngoName: string;
  center: [];
}

interface UserAttributeStateError {
  nameError: string;
  emailError: string;
  passwordError?: string;
  roleError: string;
  stateError: string;
  ngoNameError: string;
  centerInchargedError: string;
}

interface State {
  loading: boolean;
  userAttribute: UserAttributeState;
  userAttributeError: UserAttributeStateError;
  ngoList: NgoModel[];
}

const { Option } = Select;

class RoleEditor extends Component<Props> {
  state: State = {
    loading: false,
    userAttribute: {
      id: "",
      name: "",
      email: "",
      password: "",
      role: "",
      state: "",
      ngoName: "",
      center: [],
    },
    userAttributeError: {
      nameError: "",
      emailError: "",
      passwordError: "",
      roleError: "",
      stateError: "",
      ngoNameError: "",
      centerInchargedError: "",
    },
    ngoList: [],
  };

  componentDidMount = async () => {
    if (this.handleGetParams()) {
      if (
        this.props.authStore.user &&
        this.props.authStore.user.role === "SUPER"
      ) {
        await this.handleGetPreviewData();
      }
    } else {
      this.handleNavigate("roles");
    }
  };

  componentDidUpdate = async (prevProps: Props) => {
    if (
      JSON.stringify(prevProps.authStore.user) !==
        JSON.stringify(this.props.authStore.user) &&
      this.props.authStore.user &&
      this.props.authStore.user.role === "SUPER" &&
      !this.state.loading
    ) {
      await this.handleGetPreviewData();
    }
  };

  handleGetParams() {
    const id = this.props.match.params.id;
    return id;
  }

  handleGetPreviewData = async () => {
    if (this.handleGetParams() !== "add") {
      this.handleLoading(true);
      const roleQuery: AdminRes = await getAdmin(this.handleGetParams());
      const ngoQuery: AllNgoRes = await getAllNgo();
      this.props.getAllCenters("");
      if (roleQuery.err) {
        handleNotiNavigation(this.props.history, 4, "/roles");
      } else {
        this.setState({
          userAttribute: roleQuery.data,
          ngoList: ngoQuery.data,
        });
        this.handleLoading(false);
      }
    } else {
      const ngoQuery: AllNgoRes = await getAllNgo();
      this.setState({
        ngoList: ngoQuery.data,
      });
    }
  };

  handleChangeStateSelection = (type: keyof UserAttributeState, value: any) => {
    const clonedUserAttribute = this.state.userAttribute;

    clonedUserAttribute[type] = value;

    if (type === "state") {
      clonedUserAttribute["center"] = [];
    }

    this.setState({
      userAttribute: clonedUserAttribute,
    });
  };

  handleChange = (e: any) => {
    let clonedUserAttribute = this.state.userAttribute;

    clonedUserAttribute[e.target.id as keyof UserAttributeState] =
      e.target.value;
    this.setState({
      userAttribute: clonedUserAttribute,
    });
  };

  handleNavigate = (type: string) => {
    if (type === "roles") {
      handleNavigation(this.props.history, "/roles");
    }
  };

  handleLoading = (value: boolean) => {
    this.setState({
      loading: value,
    });
  };

  handleNotification = (type: string, message: string, description: string) => {
    if (type === "success") {
      notification.success({ message: message, description: "" });
    } else if (type === "warning") {
      notification.warning({
        message: message,
        description: description,
      });
    } else if (type === "error") {
      notification.error({
        message: message,
        description: description,
      });
    }
  };

  handleCheckCondition = (typeList: string[]) => {
    const userAttributeChecker = JSON.parse(
      JSON.stringify(this.state.userAttributeError)
    );
    typeList.map((eachType) => {
      switch (eachType) {
        case "name":
          if (this.state.userAttribute.name.replace(/\s/g, "").length <= 0) {
            userAttributeChecker["nameError"] = "Name cannot be empty";
          } else {
            userAttributeChecker["nameError"] = "";
          }
          break;
        case "email":
          const filter =
            /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/; // eslint-disable-line
          if (!filter.test(this.state.userAttribute.email.replace(/\s/g, ""))) {
            userAttributeChecker["emailError"] = "Email cannot be empty";
          } else {
            userAttributeChecker["emailError"] = "";
          }
          break;
        case "password":
          if (
            this.state.userAttribute.password &&
            this.state.userAttribute.password.replace(/\s/g, "").length <= 0
          ) {
            userAttributeChecker["passwordError"] = "Password cannot be empty";
          } else {
            userAttributeChecker["passwordError"] = "";
          }
          break;
        case "role":
          if (this.state.userAttribute.role.replace(/\s/g, "").length <= 0) {
            userAttributeChecker["roleError"] = "Please Select A Role";
          } else {
            userAttributeChecker["roleError"] = "";
          }
          break;
        case "state":
          if (this.state.userAttribute.state.replace(/\s/g, "").length <= 0) {
            userAttributeChecker["stateError"] = "Please Select A State";
          } else {
            userAttributeChecker["stateError"] = "";
          }
          break;
        case "ngoName":
          if (!this.state.userAttribute.ngoName) {
            userAttributeChecker["ngoNameError"] =
              "Please Select An Organisation";
          } else {
            userAttributeChecker["ngoNameError"] = "";
          }
          break;
        case "center":
          if (
            this.state.userAttribute.center &&
            this.state.userAttribute.center.length <= 0
          ) {
            userAttributeChecker["centerInchargedError"] =
              "Please Select A Center to Be In-Charged";
          } else {
            userAttributeChecker["centerInchargedError"] = "";
          }
          break;
        default:
          break;
      }
      return null;
    });
    this.setState({
      userAttributeError: userAttributeChecker,
    });
  };

  handleUpdateRole = async () => {
    this.handleLoading(true);
    this.handleCheckCondition(["role"]);
    if (this.state.userAttribute.role === "CENTER") {
      this.handleCheckCondition(["center", "state"]);
    } else if (this.state.userAttribute.role === "NGO") {
      this.handleCheckCondition(["ngoName"]);
    } else if (this.state.userAttribute.role === "STATE") {
      this.handleCheckCondition(["state"]);
    }

    await sleep(250);
    let conditionPassed: boolean = false;
    conditionPassed = !this.state.userAttributeError.roleError;
    if (this.state.userAttribute.role === "CENTER") {
      conditionPassed =
        conditionPassed &&
        !this.state.userAttributeError.centerInchargedError &&
        !this.state.userAttributeError.stateError;
    } else if (this.state.userAttribute.role === "NGO") {
      conditionPassed =
        conditionPassed && !this.state.userAttributeError.ngoNameError;
    } else if (this.state.userAttribute.role === "STATE") {
      conditionPassed =
        conditionPassed && !this.state.userAttributeError.stateError;
    }

    if (conditionPassed) {
      try {
        await updateAdmin({
          id: this.state.userAttribute.id,
          role: this.state.userAttribute.role as keyof typeof AdminRole,
          state: this.state.userAttribute.state ?? "",
          center: this.state.userAttribute.center ?? [],
          ngoName: this.state.userAttribute.ngoName ?? "",
        });
        this.handleNotification("success", "Role has Updated", "");
        this.handleNavigate("roles");
      } catch (err) {
        this.handleNotification("warning", err.message, "");
      }
    } else {
      this.handleNotification("warning", "Role Could Not Be Updated", "");
    }
    this.handleLoading(false);
  };

  handleAddRoles = async () => {
    this.handleLoading(true);
    this.handleCheckCondition(["name", "email", "password", "role"]);
    if (this.state.userAttribute.role === "CENTER") {
      this.handleCheckCondition(["center", "state"]);
    } else if (this.state.userAttribute.role === "NGO") {
      this.handleCheckCondition(["ngoName"]);
    } else if (this.state.userAttribute.role === "STATE") {
      this.handleCheckCondition(["state"]);
    }

    await sleep(250);
    let conditionPassed: boolean = false;
    conditionPassed =
      !this.state.userAttributeError.nameError &&
      !this.state.userAttributeError.emailError &&
      !this.state.userAttributeError.passwordError &&
      !this.state.userAttributeError.roleError;

    if (this.state.userAttribute.role === "CENTER") {
      conditionPassed =
        conditionPassed &&
        !this.state.userAttributeError.centerInchargedError &&
        !this.state.userAttributeError.stateError;
    } else if (this.state.userAttribute.role === "NGO") {
      conditionPassed =
        conditionPassed && !this.state.userAttributeError.ngoNameError;
    } else if (this.state.userAttribute.role === "STATE") {
      conditionPassed =
        conditionPassed && !this.state.userAttributeError.stateError;
    }

    if (conditionPassed) {
      const isProd = process.env.REACT_APP_FIREBASE_ENV === "production";
      let apiUrl: string;
      if (isProd) {
        apiUrl =
          "https://asia-southeast2-mysukarela.cloudfunctions.net/signUpAdmin";
      } else {
        apiUrl =
          "https://asia-southeast2-mysukarela-dev.cloudfunctions.net/signUpAdmin";
      }
      const headers = {
        "Content-Type": "application/json",
        "x-auth-api-key": process.env.REACT_APP_AUTH_TOKEN,
      };

      try {
        const creationResponse = await axios.post(
          apiUrl,
          {
            name: this.state.userAttribute.name,
            email: this.state.userAttribute.email,
            password: this.state.userAttribute.password,
            role: this.state.userAttribute.role,
            state: this.state.userAttribute.state,
            center: this.state.userAttribute.center,
            ngoName: this.state.userAttribute.ngoName,
          },
          { headers: headers }
        );
        if (creationResponse.data.response) {
          this.handleNotification("success", "Role has successfully Added", "");
          this.handleNavigate("roles");
        } else {
          this.handleNotification("error", "Unable to add Role to API", "");
        }
      } catch (err) {
        this.handleNotification("error", err.message, "");
      }
      this.handleLoading(false);
    } else {
      this.handleNotification("warning", "Role Could Not Be Added", "");
      this.handleLoading(false);
    }
  };

  renderButtons = () => {
    if (this.handleGetParams() !== "add" && this.handleGetParams()) {
      return (
        <Button
          text={"Update"}
          width={150}
          small={true}
          onClick={this.handleUpdateRole}
        />
      );
    } else {
      return (
        <Button
          text={"Add"}
          width={150}
          small={true}
          onClick={this.handleAddRoles}
        />
      );
    }
  };

  render() {
    if (this.props.authStore.userLoading) {
      return null;
    }

    return (
      <Spin
        spinning={this.state.loading}
        size="large"
        style={{
          width: "100vw",
          height: "100vh",
          maxHeight: "none",
        }}
      >
        <Header history={this.props.history} location={this.props.location} />
        <WindowResizer />
        <SecurityNavigator location={this.props.location} role="SUPER" />
        <VerticalContainer
          style={{
            width: "100%",
          }}
        >
          <VerticalContainer
            style={{
              alignItems: "flex-start",
              width: "90%",
              height: "100%",
              maxWidth: 1200,
              padding: "40px 0px",
            }}
          >
            <RowContainer
              style={{
                width: "100%",
                justifyContent: "flex-start",
              }}
            >
              <CustomActionTitle
                onClick={this.handleNavigate.bind(this, "roles")}
                title="Roles"
              >
                Roles
              </CustomActionTitle>
              <Title3 style={{ margin: "0px 10px" }}>/</Title3>
              <Title3 style={{ marginBottom: 0 }}>
                {this.props.match.params.id === "add"
                  ? "Add New Role"
                  : this.state.userAttribute.email}
              </Title3>
            </RowContainer>
            <Divider style={{ marginTop: 10, marginBottom: 30 }} />
            <Row style={{ width: "100%" }} gutter={8}>
              <Col span={12}>
                <VerticalContainer
                  style={{
                    width: "95%",
                    alignItems: "flex-start",
                  }}
                >
                  <Body style={{ marginBottom: 5 }}>Name</Body>
                  <TextInput
                    id="name"
                    width="100%"
                    value={this.state.userAttribute.name}
                    disabled={
                      this.props.match.params.id === "add" ? false : true
                    }
                    placeholder={"Name"}
                    background={
                      this.props.match.params.id === "add"
                        ? undefined
                        : COLOR.LIGHTGREY
                    }
                    onChange={this.handleChange}
                  />
                  {this.state.userAttributeError.nameError && (
                    <Warning style={{ marginBottom: 0 }}>
                      {this.state.userAttributeError.nameError}
                    </Warning>
                  )}

                  <Body style={{ marginBottom: 5, marginTop: 20 }}>Role</Body>
                  <Select
                    style={{ width: "100%" }}
                    value={
                      this.state.userAttribute.role !== ""
                        ? this.state.userAttribute.role
                        : undefined
                    }
                    placeholder={"Role"}
                    onChange={this.handleChangeStateSelection.bind(
                      this,
                      "role"
                    )}
                  >
                    <Option value={"CENTER"}>{AdminRole.CENTER}</Option>
                    <Option value={"STATE"}>{AdminRole.STATE}</Option>
                    <Option value={"NGO"}>{AdminRole.NGO}</Option>
                    <Option value={"HEALTH"}>{AdminRole.HEALTH}</Option>
                  </Select>
                  {this.state.userAttributeError.roleError && (
                    <Warning style={{ marginBottom: 0 }}>
                      {this.state.userAttributeError.roleError}
                    </Warning>
                  )}
                  <Body style={{ marginBottom: 5, marginTop: 20 }}>
                    Center In-Charged
                  </Body>
                  <Select
                    value={
                      this.state.userAttribute.center
                        ? this.state.userAttribute.center
                        : undefined
                    }
                    style={{ width: "100%" }}
                    mode="multiple"
                    disabled={this.state.userAttribute.role !== "CENTER"}
                    allowClear
                    placeholder={"Center In-Charged"}
                    optionLabelProp="label"
                    onChange={this.handleChangeStateSelection.bind(
                      this,
                      "center"
                    )}
                  >
                    {this.props.centerStore.centers.map(
                      (eachCenter, index: number) => {
                        if (
                          eachCenter.centerAddress.state ===
                          this.state.userAttribute.state
                        ) {
                          return (
                            <Option
                              key={index}
                              label={eachCenter.centerName}
                              value={eachCenter.id}
                            >
                              {eachCenter.centerName}
                            </Option>
                          );
                        }
                        return null;
                      }
                    )}
                  </Select>
                  {this.state.userAttributeError.centerInchargedError && (
                    <Warning style={{ marginBottom: 0 }}>
                      {this.state.userAttributeError.centerInchargedError}
                    </Warning>
                  )}
                </VerticalContainer>
              </Col>
              <Col span={12}>
                <Body style={{ marginBottom: 5 }}>Email</Body>
                <TextInput
                  id="email"
                  value={this.state.userAttribute.email}
                  disabled={this.props.match.params.id === "add" ? false : true}
                  width="100%"
                  placeholder={"Email"}
                  background={
                    this.props.match.params.id === "add"
                      ? undefined
                      : COLOR.LIGHTGREY
                  }
                  onChange={this.handleChange}
                />
                {this.state.userAttributeError.emailError && (
                  <Warning style={{ marginBottom: 0 }}>
                    {this.state.userAttributeError.emailError}
                  </Warning>
                )}
                {this.state.userAttribute.role !== "NGO" ? (
                  <>
                    <Body style={{ marginBottom: 5, marginTop: 20 }}>
                      State
                    </Body>
                    <Select
                      style={{ width: "100%" }}
                      value={
                        this.state.userAttribute.state !== ""
                          ? this.state.userAttribute.state
                          : undefined
                      }
                      placeholder={"State"}
                      onChange={this.handleChangeStateSelection.bind(
                        this,
                        "state"
                      )}
                    >
                      {getStateList().map(
                        (eachState: string, index: number) => (
                          <Option key={index} value={eachState}>
                            {eachState}
                          </Option>
                        )
                      )}
                    </Select>
                    {this.state.userAttributeError.stateError && (
                      <Warning style={{ marginBottom: 0 }}>
                        {this.state.userAttributeError.stateError}
                      </Warning>
                    )}
                  </>
                ) : (
                  <>
                    <Body style={{ marginBottom: 5, marginTop: 20 }}>
                      Organisation Name
                    </Body>
                    <Select
                      value={
                        this.state.userAttribute.ngoName
                          ? this.state.userAttribute.ngoName
                          : undefined
                      }
                      style={{ width: "100%" }}
                      allowClear
                      placeholder={"Organisation Name"}
                      optionLabelProp="label"
                      onChange={this.handleChangeStateSelection.bind(
                        this,
                        "ngoName"
                      )}
                    >
                      {this.state.ngoList.map((eachNgo, index: number) => {
                        return (
                          <Option
                            key={index}
                            label={eachNgo.name}
                            value={eachNgo.shorthand}
                          >
                            {eachNgo.name}
                          </Option>
                        );
                      })}
                    </Select>
                    {this.state.userAttributeError.ngoNameError && (
                      <Warning style={{ marginBottom: 0 }}>
                        {this.state.userAttributeError.ngoNameError}
                      </Warning>
                    )}
                  </>
                )}

                {this.props.match.params.id === "add" && (
                  <>
                    <Body style={{ marginBottom: 5, marginTop: 20 }}>
                      Password
                    </Body>
                    <TextInput
                      id="password"
                      value={this.state.userAttribute.password}
                      width="100%"
                      password={true}
                      placeholder={"Enter a secure password..."}
                      onChange={this.handleChange}
                    />
                    {this.state.userAttributeError.passwordError && (
                      <Warning style={{ marginBottom: 0 }}>
                        {this.state.userAttributeError.passwordError}
                      </Warning>
                    )}
                  </>
                )}
              </Col>
              <RowContainer
                style={{
                  width: "100%",
                  marginTop: 30,
                  justifyContent: "flex-end",
                }}
              >
                {this.renderButtons()}
              </RowContainer>
            </Row>
          </VerticalContainer>
        </VerticalContainer>
      </Spin>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    centerStore: state.centerStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAllCenters: (state: string) => dispatch(getAllCenters(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleEditor);
